import React, { useState } from "react";
import { InstructorHeader } from "../../../instructor/header";
import Footer from "../../../footer";
import TextEditor from "../../../pages/course/addCourse/editor";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Input, Form } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import * as Yup from "yup";
import moment from "moment";

import { useFormik } from "formik";
import AdminInstructorSidebar from "../../../instructor/adminSidebar";
import axios from "../../../../api/axios";
import { useEffect } from "react";
import AdminHeader from "../../../instructor/adminHeader";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
const CLASSMODULE = "https://zoomservice.chahalacademy.com/api/meeting/create";
const ADDCLASS = "https://zoomservice.chahalacademy.com/api/meeting/create";
// const OPTION = "/api/live-classes/zoomAppEmail";
const ZOOMVIEW = "https://zoomservice.chahalacademy.com/api/zoom/get_all";
const SHOWMODULE = "https://zoomservice.chahalacademy.com/api/sub-module/get_all";


import "../../../instructor/adminTestSeries/index.css"
import { notification, Space } from "antd";

export default function AddLiveClasses() {
  const initalValue = {
    title: "",
    // name: "",
    // email: "",
    date: "",
    duration: "",
    mettingpassword: "",
    // submodule: [],
  };

  const liveSchema = Yup.object({
    title: Yup.string().required("Please enter your title"),
    // name: Yup.string().min(2).max(100).required("Please enter your name"),
    // email: Yup.string().required("Please enter your email"),
    date: Yup.string().required("Please enter your date"),
    duration: Yup.string().required("Please enter your duration"),
    mettingpassword: Yup.string().required("Please enter your meeting password"),
    // submodule: Yup.array()
    //   .min(1, "Pick at least one items")
    //   .of(
    //     Yup.object().shape({
    //       label: Yup.string().required(),
    //       value: Yup.string().required(),
    //     })
    //   ),
    // batch: Yup.array()
    //   .min(1, "Pick at least one items")
    //   .of(
    //     Yup.object().shape({
    //       label: Yup.string().required(),
    //       value: Yup.string().required(),
    //     })
    //   ),
  });
  const [checked, setChecked] = useState(false);
  const [option, setOption] = useState(null);
  const [Options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submodule, setsubmodule] = useState(null)
  const [submit, setSubmit] = useState(false)
  const handleChangew = (nextChecked) => {
    setChecked(nextChecked);
  };
  const[zoomaccountid,setZoomaccountId]=useState([])
  const[zoommoduleid,setZoommoduleId]=useState([])
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedModule, setSelectedModule] = useState([]);
  console.log(selectedModule,"module")

  const token = localStorage.getItem("token");
  const config = {
    headers: { api_key:"lmsv2" }
  };
  const fetchModule = async () => {
    try {
      const response = await axios.get(CLASSMODULE, config);
      setOption(response?.data?.batch);
      setsubmodule(response?.data?.submodule)
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
    }

  };
  useEffect(() => {
    // fetchModule();
  }, []);
  const fetchZoom = async () => {
    try {
      setLoading(true);
      const response = await axios.get(ZOOMVIEW, config);
      console.log(response,"res")
      setZoomaccountId(response.data?.message);
      // setListModule(response.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };
  const fetchModule1 = async () => {
    setLoading(true);

    try {
      const response = await axios.get(SHOWMODULE, config);
      // console.log(response)

      setZoommoduleId(response.data.message);
      // setLast_page(response.data.subModule.last_page);
      // setData(response.data?.message);
      // setFeatureTypeId(response?.data?.featuretypeid);
      if(response?.status==200){
        console.log(response)
        // console.log(response)
      }
      // setCurrentPage(page);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
    setLoading(false);

  };
  useEffect(()=>{
    fetchZoom()
    fetchModule1()
  },[])
  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption);
  };

  const handleModuleChange = (selectedOption) => {
    setSelectedModule(selectedOption);
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false)
  const openNotificationWithIcon = (type,text,col) => {
    api[type]
      (

        {
          message:
            (
              <div style={{ color: col }}>
                <strong>{text}</strong>
              </div>
            ),
        });

  };
  const openNotificationWithIcon2 = (type) => {
   
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
    const openNotificationWithIconSesion = (type) => {
      notification[type]({
        message: (
          <div style={{ color: "red" }}>
            <strong>Session Expired Please Login </strong>
          </div>
        ),
        duration: 1,
      });
    };
    
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value, { setFieldError }) => {
      // console.log(value);
      setSubmit(true)
      const arrid = selectedModule?.map(module => module?.value);

      try {
        const response = await axios.post(
          ADDCLASS,
          {
            title: value?.title,
            // name: value.name,
            fk_zoom_account_id:selectedAccount?.value,
            fk_sub_module_id:arrid,
            // email: value.email,
            duration: +value.duration,
            date: value.date,
            password: value?.mettingpassword,
            // batch: value.batch.map((e) => e.value),
            // submodule: value.submodule.map((e) => e.value),
          },
          config
        );
         console.log(response.data)
        if (response?.status===200 ) {
          openNotificationWithIcon("success",'Live Class Created',"#52c41a")
          setTimeout(() => {
            navigate(-1);
          }, 800);
        }
        else if (response?.data?.[1]) {
          openNotificationWithIcon('error',response?.data?.[1],'red')
        }
        setSubmit(false)
      } catch (error) {
        setSubmit(false)
        console.log(error);
        // setSubmit(false)
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error?.response?.data?.message, "red")
        }
        else {
          openNotificationWithIcon("error", 'Internal Server Error', "red")
        }
       
        // openNotificationWithIcon('error',error.response.data.errors.email.toString(),'red');

       
      }
    },
    validationSchema: liveSchema,
  });

  const navigate = useNavigate();
  // const fetchOption = async () => {
  //   try {
  //     setLoading(true);
  //     const responce = await axios.get(OPTION, config);
  //     const result = responce?.data?.zoomAppDetail?.map(({ email }) => ({
  //       label: email,
  //       value: email,
  //     }));
  //     setOptions(result);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // useEffect(() => {
  //   fetchOption();
  // }, []);

  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      // height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
  };
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-8 main-page">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">

                    {loading === true ? (
                      <div
                        className="d-flex justify-content-center"
                        style={{ position: "absolute" }}
                      >
                        <div
                          className="spinner-border text-warning"
                          style={{
                            left: "50%",
                            marginLeft: "24em",
                            marginTop: "10em",
                          }}
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div className="settings-widget">
                        <div className="settings-inner-blk p-0">
                          <div className="sell-course-head comman-space">
                            <h3 style={{ textTransform: 'capitalize' }}>Add Live Class</h3>
                            <nav
                              aria-label="breadcrumb"
                              style={{
                                marginBottom: "-1em",
                                marginTop: "0.5em",
                              }}
                            >
                              <ol className="breadcrumb">
                                <li
                                  className="breadcrumb-item"
                                  onClick={() =>
                                    navigate("/admin/live-classes")
                                  }
                                  role="button"
                                >
                                  <a>Live Classes</a>
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  role="button"
                                  aria-current="page"
                                >
                                  Add Live Class
                                </li>
                              </ol>
                            </nav>
                          </div>
                          <div className="tak-head d-flex align-items-center">
                            <div>
                              <div className="checkout-form personal-address add-course-info ">
                                <form onSubmit={handleSubmit}>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Title
                                        </label>
                                        <input
                                          type="text"
                                          name="title"
                                          value={values?.title}
                                          onChange={handleChange}
                                          className="form-control"
                                          placeholder="Enter Title"
                                        />
                                        {errors.title && touched.title ? (
                                          <span style={{ color: "red" }}>
                                            {errors.title}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Name
                                        </label>
                                        <input
                                          name="name"
                                          type="text"
                                          className="form-control"
                                          onChange={handleChange}
                                          placeholder="Enter Name"
                                        />
                                        {errors.name && touched.name ? (
                                          <span style={{ color: "red" }}>
                                            {errors.name}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}

                                  
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Date Time
                                        </label>
                                        <input
                                          type="datetime-local"
                                          formNoValidate

                                          value={moment(values?.date).format("YYYY-MM-DD[T]HH:mm:ss")}

                                          onChange={handleChange}
                                          className="form-control"
                                          name="date"
                                          // validateonblur={true}
                                        />
                                        {errors.date && touched.date ? (
                                          <span style={{ color: "red" }}>
                                            {errors.date}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Duration
                                        </label>
                                        <input
                                          type="number"
                                          name="duration"
                                          value={values?.duration}

                                          className="form-control"
                                          onChange={handleChange}
                                          placeholder="Enter Duration"
                                        />
                                        {errors?.duration && touched?.duration ? (
                                          <span style={{ color: "red" }}>
                                            {errors?.duration}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Meeting Password
                                        </label>
                                        {/* <input
                                          type="password"
                                          value={values?.password?values?.password:""}

                                          onChange={handleChange}
                                          className="form-control"
                                          placeholder="Enter Meeting Password (only numbers)"
                                          name="mettingpassword"
                                        />
                                        {errors.mettingpassword && touched.mettingpassword ? (
                                          <span style={{ color: "red" }}>
                                            {errors.mettingpassword}
                                          </span>
                                        ) : null} */}
                                         <Form.Item
        // label="Meeting Password"
        validateStatus={errors?.mettingpassword && touched?.mettingpassword ? "error" : ""}
        help={errors?.mettingpassword && touched?.mettingpassword ? errors.mettingpassword : null}
      >
        <Input.Password
          value={values?.mettingpassword}
          style={{height:"40px"}}
          onChange={handleChange}
          placeholder="Enter Meeting Password (only numbers)"
          name="mettingpassword"
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>

                                      </div>
                                    </div>
                                    {/* <div className="col-lg-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Select Batch
                                        </label>

                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          name="batch"
                                          isMulti
                                          options={option?.map((ele) => {
                                            return {
                                              label: ele.name,
                                              value: ele.id,
                                            };
                                          })}
                                          // defaultValue={options[0]}
                                          placeholder="Select Batch"
                                          onChange={(assignedUser) =>
                                            setFieldValue(
                                              "batch",
                                              assignedUser
                                            )
                                          }
                                        ></Select>
                                        {errors.batch &&
                                          touched.batch ? (
                                          <span style={{ color: "red" }}>
                                            {errors.batch}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}
                                                                        <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Zoom Account
                                        </label>
                                        <Select
        options={zoomaccountid?.map(account => ({ value: account.id, label: account?.email }))}
        value={selectedAccount}
        onChange={handleAccountChange}
        placeholder="Select Zoom Account"
      />
                                        {/* {errors.password && touched.password ? (
                                          <span style={{ color: "red" }}>
                                            {errors.password}
                                          </span>
                                        ) : null} */}
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Zoom Submodule
                                        </label>
                                        <Select
        options={zoommoduleid?.map(module => ({ value: module.id, label: module.name }))}
        value={selectedModule}
        onChange={handleModuleChange}
        placeholder="Select Zoom Module"
      />
                                        {errors.password && touched.password ? (
                                          <span style={{ color: "red" }}>
                                            {errors.password}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}
                                        <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Zoom Module
                                        </label>

                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          name="batch"
                                          isMulti
                                          // options={zoommoduleid?.map((ele) => {
                                          //   return {
                                          //     label: ele.name,
                                          //     value: ele.id,
                                          //   };
                                          // })}
                                          options={zoommoduleid?.map(module => ({ value: module?.id, label: module?.name }))}
                                          value={selectedModule}

                                          // defaultValue={options[0]}
                                          placeholder="Select Batch"
                                          // onChange={(assignedUser) =>
                                          //   setFieldValue(
                                          //     "batch",
                                          //     assignedUser
                                          //   )
                                          // }
                                          onChange={handleModuleChange}

                                        ></Select>
                                        {errors.batch &&
                                          touched.batch ? (
                                          <span style={{ color: "red" }}>
                                            {errors.batch}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Select SubModule
                                        </label>

                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          name="submodule"
                                          isMulti
                                          options={submodule?.map((ele) => {
                                            return {
                                              label: ele.name,
                                              value: ele.id,
                                            };
                                          })}
                                          // defaultValue={options[0]}
                                          placeholder="Select SubModule"
                                          onChange={(assignedUser) =>
                                            setFieldValue(
                                              "submodule",
                                              assignedUser
                                            )
                                          }
                                        ></Select>
                                        {errors.submodule &&
                                          touched.submodule ? (
                                          <span style={{ color: "red" }}>
                                            {errors.submodule}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}
                                    {/* <div style={{ marginTop: "1em" }}>
                                      
                                      <Space>

                                        <button className="btn btn-primary"
                                          type="submit"
                                          disabled={submit}
                                        >
                                          Submit
                                        </button>

                                      </Space>

                                      &nbsp;&nbsp;
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => navigate(-1)}
                                      >
                                        Cancel
                                      </button>
                                    </div> */}
                                    {contextHolder}
                                    <div className="update-profile"
                                     id="submit-button"
                                    >
                                      <div
                                        className="btn-group mr-2"
                                        role="group"
                                        aria-label="First group"
                                        id="custom-submit"
                                      >
                                        <button type="submit" className="btn btn-primary"
                                          disabled={submit}
                                        >
                                          Submit
                                        </button>
                                      </div>{" "}
                                      <div
                                        className="btn-group mr-2"
                                        role="group"
                                        aria-label="First group"
                                        id="custom-submit"
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            navigate("/admin/live-classes");
                                            // windowTop(0,0)
                                          }}
                                          className="btn btn-primary cancel-btn "
                                        >
                                          Cancel
                                        </button>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <ToastContainer /> */}
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
