import React, { useState } from "react";
import { InstructorHeader } from "../../../instructor/header";
import Footer from "../../../footer";
import TextEditor from "../../../pages/course/addCourse/editor";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Input, Form } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import CryptoJS from "crypto-js";

import * as Yup from "yup";
import { useFormik } from "formik";
import AdminInstructorSidebar from "../../../instructor/adminSidebar";
import axios from "../../../../api/axios";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { notification, Space } from "antd";

import AdminHeader from "../../../instructor/adminHeader";
import { ControlPointDuplicateRounded } from "@mui/icons-material";
const CLASSMODULE = "/api/live-classes/add";
const ADDCLASS = "/api/live-classes/create";
const EDITCLASS = "https://zoomservice.chahalacademy.com/api/meeting/show";
const UDATECLASS = "https://zoomservice.chahalacademy.com/api/meeting/edit";
const ZOOMVIEW = "https://zoomservice.chahalacademy.com/api/zoom/get_all";
const SHOWMODULE = "https://zoomservice.chahalacademy.com/api/sub-module/get_all";


export default function EditLiveClasses() {
  const token = localStorage.getItem("token");
  const config = {
    headers: { api_key:"lmsv2" }
  };
  const [data, setData] = useState(null);
  const[zoomaccountid,setZoomaccountId]=useState([])
  const[zoommoduleid,setZoommoduleId]=useState([])
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);

  const [live, setlive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(null);
  const [subModules, setsubModules] = useState(null)
  const [resultsub, setresultsub] = useState(null)
  const { id ,meeting_number} = useParams();
  const [submit, setSubmit] = useState(false)
  const [matches,setMatches]=useState([])

  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // const fetchModule = async () => {
  //   const response = await axios.get(CLASSMODULE, config);
  //   // setOption(response?.data);
  // };
  useEffect(() => {
    // fetchModule();
  }, []);

  const fetchZoom = async () => {
    try {
      setLoading(true);
      const response = await axios.get(ZOOMVIEW, config);
      // console.log(response,"res")
      setZoomaccountId(response.data?.message);
      // setListModule(response.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };
  const fetchModule = async () => {
    setLoading(true);

    try {
      const response = await axios.get(SHOWMODULE, config);
      // console.log(response)

      setZoommoduleId(response.data.message);
      // setLast_page(response.data.subModule.last_page);
      // setData(response.data?.message);
      // setFeatureTypeId(response?.data?.featuretypeid);
      if(response?.status==200){
        setLoading(false)
        return
        // console.log(response)
      }
      // setCurrentPage(page);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
    setLoading(false);

  };
  useEffect(()=>{
    fetchZoom()
    fetchModule()
  },[])
  const fetchClass = async () => {
    try {
      setLoading(true);
      if (decryptedData) {
        const response = await axios.get(`${EDITCLASS}/${decryptedData}`, config);
        // console.log(response,"liveclass")
        setOption(response?.data?.message)
        setData(response?.data?.message);
        setlive(response?.data?.message);
        setsubModules(response?.data?.subModules)
        // setSelectedAccount(response?.data?.fk_zoom_account)
        // setSelectedModule(response?.data?.fk_sub_module)
        const account = response?.data?.message?.fk_zoom_account;
        const module = response?.data?.message?.fk_sub_module;
         console.log(module,"module")

        if (account) {
          setSelectedAccount(account?.id);
        }
          if (module && zoommoduleid) {
            setMatches(module)
            
          }
          
      }
      setLoading(false);
    }
    catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error","Internal Server Error","red")
      }
    }

  };
  useEffect(() => {
    fetchClass();
  }, []);
  
//  console.log(data,"data");
  const result = [];
  // option?.map((it) => {
  //   live?.map((its) => {
  //     if (it?.id == its?.batch_id) {
  //       return result.push(Object.assign({}, it, its));
  //     }
  //   });
  // });
  // console.log(result);

  const initalValue = {
    title: data?.title?data?.title:"",
    // name: data?.name?data?.name:"",
    // email: data?.email?data?.email:"",
    date: data?.date?data?.date:"",
    duration: data?.duration?data?.duration:"",
    mettingpassword: data?.password?data?.password:"",
    // status: data?.status,
    // batch: result?.map((ele) => {
    //   return {
    //     label: ele.name,
    //     value: Number(ele.batch_id),
    //   };
    // }),
    // submodule: resultsub?.map((ele) => {
    //   return {
    //     label: ele.name,
    //     value: ele.id,
    //   };
    // }),
  };
  const liveSchema = Yup.object({
    title: Yup.string().required("Please enter your title"),
    // name: Yup.string().min(2).max(100).required("Please enter your name"),
    // email: Yup.string().required("Please enter your email"),
    date: Yup.string().required("Please enter your date"),
    duration: Yup.string().required("Please enter your duration"),
    mettingpassword: Yup.string().required("Please enter your meeting password"),
    // submodule: Yup.array()
    //   .min(1, "Pick at least one items")
    //   .of(
    //     Yup.object().shape({
    //       label: Yup.string().required(),
    //       value: Yup.string().required(),
    //     })
    //   ),
  });
  const [checked, setChecked] = useState(false);
  const handleChangew = (nextChecked) => {
    setChecked(nextChecked);
  };
  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption);
  };

  const handleModuleChange = (selectedOption) => {
    setSelectedModule(selectedOption);
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false)
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    api[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>Live Class Updated</strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Created

          // </div>)

        });






  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    enableReinitialize: true,
    onSubmit: async (value, { setFieldError }) => {
      // console.log(value);
      setSubmit(true)
      try {
        console.log("krishna")

        const response = await axios.put(
          `${UDATECLASS}/${meeting_number}`,
          {
            title: value.title,
            // name: value.name,
            // email: value.email,
            fk_zoom_account_id:selectedAccount,
            fk_sub_module_id:selectedModule,
            duration: value.duration,
            date: value.date,
            password: value?.mettingpassword,
            // "live-class-id": decryptedData,
            // batch: value.batch.map((e) => e.value),
            // submodule: value.submodule.map((e) => e.value),
            // meeting_id: data?.meeting_id,
            // status: value.status,
          },
          config
        );
        // console.log(response);
        if (response)
          openNotificationWithIcon("success")
        setTimeout(() => {
          navigate(-1);
        }, 1000);
        // toast.info("Live Class Updated");
      } catch (error) {
        console.log(error);
        setSubmit(false)
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon2("error", error.response.data.message, "red");
        } 
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon2("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else {
          openNotificationWithIcon2("error", "Internal Server Error", "red");
        }
        console.log(error);
      
      }
    },
    validationSchema: liveSchema,
  });

  const navigate = useNavigate();
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      // height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
  };
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  const openNotificationWithIcon2 = (type,text,col) => {
    console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:col}}>
         <strong> {text} </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
    // const windowTop = (arg1, arg2) => {
    //   window.scrollTo(arg1, arg2);
    // };
    
    //   useEffect(() => {
    //     windowTop(0, 0);
    //   }, []);
    // console.log(selectedAccount,selectedModule)
    // console.log(matches,"module")

    useEffect(() => {
      if (matches.length > 0 && zoommoduleid) {
        const matchedModules = matches
          .filter(mod => zoommoduleid.some(zoomMod => zoomMod.id === mod?.sub_module_id))
          .map(mod => ({
            value: mod?.sub_module_id,
            label: zoommoduleid.find(zoomMod => zoomMod.id === mod?.sub_module_id)?.name,
          }));
        console.log(matchedModules, "matches");
        setSelectedModule(matchedModules);
      }
    }, [matches, zoommoduleid]);
    const formattedDate = values?.date ? values.date.replace('Z', '') : '';
    console.log(formattedDate,"date",values?.date)
    return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-8 main-page">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3>Edit Live Class</h3>
                          <nav aria-label="breadcrumb" style={{ marginBottom: '-1em', marginTop: '0.5em' }}>
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item" onClick={() => navigate('/admin/live-classes')} role="button">
                                <a>Live Classes</a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                role="button"
                                aria-current="page"
                              >
                                Edit Live Class
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="tak-head d-flex align-items-center">
                          <div>
                            <div className="checkout-form personal-address add-course-info ">
                              <form noValidate onSubmit={handleSubmit}>
                                {loading === true ? (
                                  <div
                                    className="d-flex justify-content-center"
                                    style={{ position: "absolute" }}
                                  >
                                    <div
                                      className="spinner-border text-warning"
                                      style={{
                                        left: "50%",
                                        marginLeft: "24em",
                                        marginTop: "10em",
                                      }}
                                      role="status"
                                    >
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Title
                                        </label>
                                        <input
                                          type="text"
                                          name="title"
                                          onChange={handleChange}
                                          className="form-control"
                                          value={values.title}
                                          placeholder="Enter Title"
                                        />
                                        {errors.title && touched.title ? (
                                          <span style={{ color: "red" }}>
                                            {errors.title}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Name
                                        </label>
                                        <input
                                          name="name"
                                          type="text"
                                          value={values.name}
                                          className="form-control"
                                          onChange={handleChange}
                                          placeholder="Enter Name"
                                        />
                                        {errors.name && touched.name ? (
                                          <span style={{ color: "red" }}>
                                            {errors.name}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}

                                    {/* <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Email
                                        </label>
                                        <input
                                          type="text"
                                          onChange={handleChange}
                                          name="email"
                                          value={values.email}
                                          className="form-control"
                                          placeholder="Enter Email"
                                          disabled
                                        />
                                        {errors.email && touched.email ? (
                                          <span style={{ color: "red" }}>
                                            {errors.email}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Date Time
                                        </label>
                                        <input
          type="datetime-local"
          formNoValidate
          value={formattedDate}
          onChange={handleChange}
          className="form-control"
          name="date"
        />
                                        {errors.date && touched.date ? (
                                          <span style={{ color: "red" }}>
                                            {errors.date}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Duration
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          onChange={handleChange}
                                          placeholder="Enter Duration"
                                          name="duration"
                                          value={values?.duration}
                                        />
                                        {errors.duration && touched.duration ? (
                                          <span style={{ color: "red" }}>
                                            {errors.duration}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Meeting Password
                                        </label>
                                        {/* <input
                                          type="password"
                                          onChange={handleChange}
                                          className="form-control"
                                          placeholder="Enter Meeting Password (In Numbers )"
                                          name="password"
                                          value={values.password}
                                        />
                                        {errors.password && touched.password ? (
                                          <span style={{ color: "red" }}>
                                            {errors.password}
                                          </span>
                                        ) : null} */}
                                                   <Form.Item
        // label="Meeting Password"
        validateStatus={errors.mettingpassword && touched.mettingpassword ? "error" : ""}
        help={errors.mettingpassword && touched.mettingpassword ? errors.mettingpassword : null}
      >
        <Input.Password
          value={values?.mettingpassword}
          style={{height:"40px"}}
          onChange={handleChange}
          placeholder="Enter Meeting Password (only numbers)"
          name="password"
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
          <div className="form-group">
            <label className="form-control-label">Zoom Account</label>
            <select
              value={selectedAccount}
              onChange={handleAccountChange}
              className="form-control"
            >
              <option value="">Select Zoom Account</option>
              {zoomaccountid?.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.email}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-lg-6">
      <div className="form-group">
        <label className="form-control-label">Zoom Submodule</label>
        {/* <select
          value={selectedModule}
          onChange={handleModuleChange}
          className="form-control"
          multiple
        >
          <option value="" disabled>Select Zoom Module</option>
          {zoommoduleid?.map((module) => (
            <option key={module.id} value={module.id}>
              {module.name}
            </option>
          ))}
        </select> */}
        
        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          name="batch"
                                          isMulti
                                         
                                          options={zoommoduleid?.map(module => ({ value: module?.id, label: module?.name }))}
                                          value={selectedModule}

                                          // defaultValue={options[0]}
                                          placeholder="Select Batch"
                                         
                                          onChange={handleModuleChange}

                                        ></Select>
      </div>
    </div>
                                    {/* <div className="col-lg-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Select Batch
                                        </label>

                                        <Select
                                          className=" select country-select"
                                          name="batch"
                                          isMulti
                                          options={option?.map((ele) => {
                                            return {
                                              label: ele.name,
                                              value: ele.id,
                                            };
                                          })}
                                          // defaultValue={}
                                          value={values.batch}
                                          // defaultValue={options[0]}
                                          placeholder="Select Batch"
                                          onChange={(assignedUser) =>
                                            setFieldValue(
                                              "batch",
                                              assignedUser
                                            )
                                          }
                                          // onChange={setCountry}
                                          styles={style}
                                        ></Select>
                                        {errors.batch &&
                                          touched.batch ? (
                                          <span style={{ color: "red" }}>
                                            {errors.batch}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}
                                    {/* <div className="col-lg-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Select SubModule
                                        </label>

                                        <Select
                                          className=" select country-select"
                                          name="submodule"
                                          isMulti
                                          options={subModules?.map((ele) => {
                                            return {
                                              label: ele.name,
                                              value: ele.id,
                                            };
                                          })}
                                          // defaultValue={}
                                          value={values.submodule}
                                          // defaultValue={options[0]}
                                          placeholder="Select SubModule"
                                          onChange={(assignedUser) =>
                                            setFieldValue(
                                              "submodule",
                                              assignedUser
                                            )
                                          }
                                          // onChange={setCountry}
                                          styles={style}
                                        ></Select>
                                        {errors.submodule &&
                                          touched.submodule ? (
                                          <span style={{ color: "red" }}>
                                            {errors.submodule}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}
                                     
     
                                    {/* <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Status
                                        </label>
                                        <br />
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id="inlineRadio1"
                                            onChange={
                                              getFieldProps("status").onChange
                                            }
                                            value={"0" || values?.status}
                                            checked={values?.status === "0"}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="inlineRadio1"
                                          >
                                            Inactive
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id="inlineRadio2"
                                            checked={values?.status === "1"}
                                            onChange={
                                              getFieldProps("status").onChange
                                            }
                                            value={"1" || values?.status}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="inlineRadio2"
                                          >
                                            Active
                                          </label>
                                        </div>
                                      </div>
                                    </div> */}
                                    <div style={{ marginTop: "1em" }}>
                                      {contextHolder}
                                      <div className="update-profile"
                                      id="submit-button"
                                      >
                                        <div
                                          className="btn-group mr-2"
                                          role="group"
                                          aria-label="First group"
                                          id="custom-submit"

                                        >
                                          <button type="submit" className="btn btn-primary"
                                            disabled={submit}
                                          >
                                            Update
                                          </button>
                                        </div>{" "}
                                        <div
                                          className="btn-group mr-2"
                                          role="group"
                                          aria-label="First group"
                                          id="custom-submit"

                                        >
                                          <button
                                            type="button"
                                            onClick={() => {
                                              navigate("/admin/live-classes");
                                              // windowTop(0,0)
                                            }}
                                            className="btn btn-primary cancel-btn "
                                          >
                                            Cancel
                                          </button>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <ToastContainer autoClose={3000} hideProgressBar={true} /> */}
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
