import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon19, Icon20, Icon21, logo ,ChahalLogo} from "../imagepath";
import "./index.css"

const Footer = () => {
  const navigate = useNavigate();
  const isLoggedIn = true;
  const isRegistered = true;

  const handleLogin = () => {
    if (isLoggedIn) {
      navigate("/");
    } else {
      navigate("/login");
    }
  };

  const handleRegister = () => {
    if (isRegistered) {
      navigate("/");
    } else {
      navigate("/register");
    }
  };
  return (
    <footer className="footer">
    <div className="top_footer pt-3 pb-1">

<div className="container">

  <div className="row">

      <div className="col-lg-3 col-sm-8 mb-4 mb-lg-0">

          <div className="footer_logo">

              <a href="{{url('/')}}">
                <img alt="logo" src={ChahalLogo}  width="200" height="50"/></a>

          </div>

          <h5 className="text-black"
          style={{marginTop:"10px"}}
          >New Delhi Corporate Office</h5>

          <ul className="contact_info contact_info_light list_none "
                    style={{marginTop:"10px" , listStyle: "none"
                  }}

          >

              <li>
                  <address>Office No. 22-B, Ground Floor, Near Metro Pillar 112, Old Rajinder Nagar, Pusa Road, Karol Bagh, New Delhi-110060.</address>
              </li>

              <li>

                  <i className="fa fa-envelope"></i>

                  <a  className="ml-2"href="mailto:chahalacademy@gmail.com">chahalacademy@gmail.com</a>

              </li>

              <li>

                  <i className="fa fa-mobile-alt"></i>

                  <a className="ml-2" href="tel:+919625993995">96259-93995</a>

              </li>

              

          </ul>

      </div>

      <div className="col-lg-6 col-sm-4 mb-4 mb-lg-0" id=""
      style={{display:"inline"}}
      >

          <h5>Our Coaching Centres</h5>

          <div className="row">

              <div className="col-lg-4 col-4">

                  <ul className="list_none widget_links links_style1 "           
                  style={{listStyle: "none"}}
>

                      <li ><a href="https://chahalacademy.com/best-ias-coaching-in-delhi">Delhi Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/ias-coaching/ahmedabad">Ahmedabad Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/gpsc-upsc-coaching/anand">Anand Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/ias-coaching/vadodara">Vadodara Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-bhubaneswar">Bhubaneswar Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-chandigarh">Chandigarh Centre</a></li>
                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-dimapur">Dimapur Centre</a></li>

                  </ul>

              </div>

              <div className="col-lg-4 col-4">

                  <ul className="list_none widget_links links_style1" 
                                    style={{listStyle: "none"}}

                  >

                      <li><a href="https://chahalacademy.com/best-ias-coaching-in-dehradun">Dehradun Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-gandhinagar">Gandhinagar Centre</a></li>
                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-guwahati">Guwahati Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-hyderabad">Hyderabad Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/ias-coaching/kanpur">Kanpur Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-kolkata">Kolkata Centre</a></li>

                      {/* <!--<li><a href="{{url('/best-ias-coaching-in-mehsana')}}">Mehsana Centre</a></li>    --> */}

                      <li className="mt-2" ><a href="https://chahalacademy.com/best-ias-coaching-in-mumbai">Mumbai Centre</a></li>

                  </ul>

              </div>

              <div className="col-lg-4 col-4">

                  <ul className="list_none widget_links links_style1"
                    style={{listStyle: "none"}}

                  >

                      <li><a href="https://chahalacademy.com/best-ias-coaching-in-jaipur">Jaipur Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/ias-coaching/patna">Patna Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-raipur">Raipur Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-rajkot">Rajkot Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/ias-coaching/ranchi">Ranchi Centre</a></li>

                      <li className="mt-2"><a href="https://chahalacademy.com/ias-coaching/surat">Surat Centre</a></li>
                      <li className="mt-2"><a href="https://chahalacademy.com/best-ias-coaching-in-thane">Thane Centre</a></li>

                  </ul>

              </div>

          </div>

      </div>

      

      <div className="col-lg-3 col-md-6">

          <a href="{{url('/selections')}}"><h5 className="text-black">Our Proven Results</h5></a>

          <div className="ml-3">

              <ul className="widget_title" style={{fontSize:"14px",listStyle: "none"}}          
>

                  <li className="text-black">312 Selections in UPSC-CSE 2022-23</li><br/>

                  <li className="text-black">216 Selections in UPSC-CSE 2021-22</li><br/>

                  <li className="text-black">118 Selections in UPSC-CSE 2020-21</li>

              </ul>

          </div>

          <p className="widget_title"><strong>Follow Us</strong></p>

          <ul className="list_none social_icons radius_social social_black social_style1 d-flex gap-4"
          style={{listStyle: "none"}}
          >


              {/* <!--<li><a href="https://twitter.com/chahalacademy" target="_blank"><span className="ion-social-twitter"></span></a></li>--> */}


              {/* <!--<li><a href="https://play.google.com/store/apps/details?id=com.chahalacademy" target="_blank"><span className="ion-social-android"></span></a></li>--> */}


              {/* <li><a className="ion-social" target="_blank" href="https://t.me/chahalacademyupsc"><img src="{{asset('assets/telegram.png')}}" alt="telegram" width="20" height="20"></a></li> */}
              <li><a href="https://www.facebook.com/chahalacademyofficial/" target="_blank"><i className="fab fa-facebook-square" style={{fontSize:"34px"}}></i></a></li>
    <li><a href="https://www.youtube.com/@chahalacademy/featured" target="_blank"><i className="fab fa-youtube" style={{fontSize:"34px"}}></i></a></li>
    <li><a href="https://www.instagram.com/chahalacademy/" target="_blank"><i className="fab fa-instagram" style={{fontSize:"34px"}}></i></a></li>

          </ul>

      </div>

  </div>

</div>

</div>



  </footer>
  );
};

export default Footer;
