import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik } from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../../instructor/adminHeader";
import { async } from "q";
import { notification, Space } from "antd";

const CREATECONTACTS = "/api/webcontacts/store";
const FEDDMASTER="/api/feed-master/show-active"
const COURSETIME="/api/course-type/show-active"
const UPLOADIMAGE="/api/uploads";

const initalValue = {
  name: "",
  status: "",
  image: "",
};
const courseSchema = Yup.object({
  name: Yup.string().min(2).required("Please enter your name"),
  status: Yup.string().required("Please select your status"),

});

export default function AddAdminCreateContact() {
  const [country, setCountry] = useState(null);
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [radio, setRadio] = useState(null);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [api, contextHolder] = notification.useNotification();
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCoursetime, setSelectedCoursetime] = useState([]);
  const [selectedCoursevalue, setSelectedCoursevalue] = useState("");
  const [selectedCoursetimevalue, setSelectedCoursetimevalue] = useState("");
const [imageerror,setImageError]=useState("")
  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon2 = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconerror = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>course is already exists</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    
    onSubmit: async (values, { setFieldError }) => {
        // console.log("krishna before try");

      setSubmit(true);
      try {

        // console.log("krishna");
        const formData = new FormData();
        if (file === null) {
          formData.append("image", "");
        } else if (file !== null) {
          formData.append("image", file);
        } else {
          formData.append("image", file);
        }
      
        formData.append("name", values.name);
        formData.append("status", values.status);
        formData.append("file",file)

        const response = await axios.post(CREATECONTACTS, formData, config);
        // console.log(response);
        if (response) {
          openNotificationWithIcon("success", "Contact Created", "#52c41a");
          setTimeout(() => {
            navigate("/admin/contacts");
          }, 800);
          // toast.success("Course Created");
        }
      } catch (error) {
        setSubmit(false);
        console.log(error);
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red");
        } else {
          openNotificationWithIcon("error", "Internal Server Error", "red");
        }
        // if (error.response.data.errors.image) {
        //   setFieldError("image", error.response.data.errors.image.toString());
        // }
       
      }
    },
    validationSchema: courseSchema,
  });
  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // console.log(selectedCourse,"selected course")
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"contact"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Contacts</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate("/admin/contacts")}>
                            Contacts
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Contacts
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Name"
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                       
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                               Image
                            </label>
                            <input
                              type="file"
                              name="image"
                              id="image"
                              onChange={(e) => {
                                setFile(e.target.files[0]),
                                  setFilePre(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                              }}
                              className="form-control"
                              placeholder="Cover Image"
                            />
                            {errors.image && touched.image ? (
                              <span style={{ color: "red" }}>
                                {errors.image}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div
                              className="add-image-box"
                              style={{ minHeight: "0", height: "200px" }}
                            >
                              {!filePre && (
                                <Link to="#" style={{ fontSize: "200px" }}>
                                  <i className="far fa-image" />
                                </Link>
                              )}
                              {filePre && (
                                <img
                                  src={filePre}
                                  style={{
                                    height: "200px",
                                    width: "100%",
                                    borderRadius: "10px",
                                  }}
                                ></img>
                              )}
                            </div>
                          </div>
                        </div>
                       
                      

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Status :</label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios1"
                                onChange={getFieldProps("status").onChange}
                                value="0"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadios1"
                              >
                                InActive
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios2"
                                onChange={getFieldProps("status").onChange}
                                value="1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadios2"
                              >
                                Active
                              </label>
                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        
                       
                       
                        <div className="update-profile" id="submit-button"
                        style={{marginTop:"20px"}}
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            //   value={console.log("krishna in submit")}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
