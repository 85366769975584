import React, { useContext, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import Footer from "../../footer";
import AdminInstructorSidebar from "../adminSidebar";
// import Modal from "react-bootstrap/Modal";
import AuthContext from "../../../context/AuthProvider";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Switch from "react-switch";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import AdminHeader from "../adminHeader";
import "./index.css";
import Form from "react-bootstrap/Form";
import axios from "../../../api/axios";
import { Tooltip, Input } from "antd";
import PaginationQuestion from "../../pages/course/addCourse/PaginationQuestion";

const QUESTIONAPI = "/api/test-series/testseries-question-search";
const SHOWQUESTION = "/api/test-series/show-testseries-question";
const DELETEQUESTION = "/api/test-series/delete-question";
const QUESSTIONSTATUS = "/api/test-series/status-question";
const TESTEXCEL = "/api/test-series/download/question/excel/sample";
const IMPORT = "/api/test-series/word/import-questions";
const SHOWSUDMODULE = "/api/test-series/submodules";
const DELETEMULTIPLEQUESTIONS = "/api/test-series/delete-question/delete/multiple";

const DownloadWord = "/api/export/wordfile/download";
import { Modal } from "antd";
import { notification, Space } from "antd";


const { confirm } = Modal;

export const AdminTestListUsers = () => {
  const { auth } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [id, setId] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [moduleName1, setModuleName1] = useState("");
  const [moduleid, setModuleId] = useState("");
  const [checked, setChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [prevPage, setPrevPage] = useState(1);
  const [records1, setRecords] = useState(null);


  const [showTest, setShowTest] = useState(false);
  const [error, seterror] = useState("");
  const [submoduleid, setUubmoduleid] = useState(null);
  const[fkid,setFkid]=useState("")
  const[fkidname,setFkidName]=useState("")

  const [list, setList] = useState([]);
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, subuser } = useParams();
  const [message, setmessage] = useState("No Data");
  const [subModule, setSubModule] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [word, setWord] = useState(false);
  const inputFile = useRef(null);
  const location = useLocation();
  const[allchecked,setAllchecked]=useState(false)
  let currentPath = window.location.origin;
  if(currentPath==="https://lms.stellarflux.in"){
    currentPath="https://lmscode.stellarflux.in"
  }
  else if(currentPath==="https://lms.stellarflux.com"){
    currentPath="https://lmscode.stellarflux.com"
  }
  else if(currentPath==="http://127.0.0.1:3000"){
    currentPath="https://lmscode.stellarflux.com"
  }
  // console.log(currentPath,"currentpath")


  


  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const config1 = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "blob" },
    responseType: "arraybuffer",
  };
  const showQuestion = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${SHOWQUESTION}/${user}/${subuser}?page=${page}`,
        config
      );
      setFkid(response?.data?.submodule?.fk_sub_module_id)

      setModuleName(response?.data?.module?.name);
      setModuleName1(response?.data?.submodule?.name)
      setLast_page(response?.data?.allQuestion?.last_page);
      setData(response?.data?.allQuestion?.data);
      setList(response?.data?.allQuestion?.data);
      setUubmoduleid(response?.data?.submoduleid);
      setModuleId(response?.data?.submodule?.module_id)
      ShowSudModuleBredcum(response?.data?.submodule?.fk_sub_module_id)

      // setCurrentPage(page);
      const subModuleData = response?.data?.allQuestion?.data;
      const singleValue = subModuleData?.find((item) => item.id === item.id);
      setSubModule(singleValue?.sub_module_id);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  useEffect(() => {
    showQuestion();
  }, []);
  // console.log(moduleid,"moduleid")
  const navigate = useNavigate();
  const handleEdit = (id) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(
      `/admin/testseries/user/edit-question/${ciphertexts}/${user}/${subuser}`
    );
  };

  const handleClose = () => setShow(false);
  const handleCloseImoprt = () => setShowImport(false);
  const handleCloseTest = () => setShowTest(false);
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      QUESSTIONSTATUS,
      { id: id, status: result },
      config
    );
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    // toast.success("Status Change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };

  const [file, setFile] = useState(null);
  const openNotificationWithIconImport = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Question Imported</strong>
        </div>
      ),
      duration: 3,
    });
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const handleDelteImoprt = async () => {
    const formData = new FormData();
    formData.append("question_import", file);
    formData.append("sub-module", submoduleid);
    formData.append("type", 2);
    seterror("");

    const customConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.post(IMPORT, formData, customConfig);
      openNotificationWithIconImport("success");
      showQuestion();
      if (response) {
        setShowImport(false);
        setIsModalVisible(false);
        if (inputFile.current) {
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        }
      }
    } catch (error) {
      console.log(error);
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }

      seterror(error.response.data.message);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if(error?.response?.status===403){
        openNotificationWithIcon3("error", error?.response?.data?.message);
      }
    
      else{
      openNotificationWithIcon3("error",error?.response?.data?.message);
      }
    }
  };
// console.log(error,"error")
const handelDeleteQuestions = (uri, check) => {
  if (check == true) {
  }
  const arr = [...checked];
  if (!arr.includes(uri)) {
    setChecked([...arr, uri]);
  } else {
    const res = arr.filter((e) => e !== uri);
    setChecked(res);
  }
};
const handelpagechange=()=>{
  if (isCheckAll && currentPage !== prevPage) {
    setIsCheckAll(false);
  } 
}
useEffect(()=>{
  handelpagechange()
},[currentPage])
const handleSelectAll = () => {
  setIsCheckAll(!isCheckAll);

  setChecked((prevChecked) => {
    // console.log("Previous Checked Array:", prevChecked);

    if (isCheckAll) {
      // Unselect all items
      return prevChecked.filter((item) => !list?.map((li) => li?.id).includes(item));
    } else {
      // Select all items on the current page and preserve previously selected items
      const currentPageIds = list?.map((li) => li?.id) || [];
      return [...prevChecked, ...currentPageIds];
    }
  });

  setPrevPage(currentPage);
};
// console.log(checked,"checked",list)
const handleDeleteQuestionsList = async (event) => {
  event.preventDefault()
  const checkedValues = checked.join(','); 
    setLoading(true);
    try {
      const response = await axios.post(`${DELETEMULTIPLEQUESTIONS}`,{id:checked}, config);
      setLoading(false);
      showQuestion();
      setShow(false);
      openNotificationWithIcon("success")
      setChecked([])
      setIsCheckAll(false)
      
     
    } 
    catch (error) {
      console.error("Error deleting student:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon1("error")

      }
    }
  
};
  const downloadFileWord = async () => {
    //  window.open(`${currentPath}/sample/sample.docx`,_blank)

    try {
      const response = await axios.get(DownloadWord, config1);
      const blob = new Blob([response?.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "sample.docx";
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      openNotificationWithIcon2("error");
    }
    setWord(false);
  };
  const exportCsv = (excel) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const data = new Blob([excel], { type: "application/vnd.ms-excel" });
    FileSaver.saveAs(data, "Question" + fileExtension);
  };
  const handleShow = async (id) => {
    setShow(true);
    setId(id);
  };
  const handleShowImport = () => {
    setShowImport(true);
    setIsModalVisible(true);
  };
  const handleShowTest = async (id) => {
    setShowTest(true);
    // setId(id);
  };
  const DeleteTestSeries = async () => {
    if (id) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETEQUESTION}/${id}`, config);
        // console.log("resonse", resonse);
        toast.warning("Question Deleted");
        setLoading(false);
        showQuestion();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Question Deleted</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this question?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETEQUESTION}/${id}`, config);
          openNotificationWithIcon("success");
          setLoading(false);
          showQuestion();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          openNotificationWithIcon2("error");
        }
      },
      onCancel() {},
    });
  }
  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      const res = await axios.post(
        QUESTIONAPI,
        { name: searchValue, submodule: submoduleid },
        config
      );
      if (res.data.question.length > 0) {
        setList(res.data.question);
      }
      if (res.data.question.length == 0) {
        setList(res.data.question);
      }
      if (searchValue.length === 0) {
        const res = await axios.get(
          `${SHOWQUESTION}/${user}/${subuser}?page=${currentPage}`,
          config
        );
        setList(res.data.subModule.data);
      }
    } catch (error) {
      // console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  const itemsPerPage = 10;
  // console.log(moduleName1)

  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  const slug=localStorage.getItem("slug")
  const namevalue=localStorage.getItem("moduleName")
  const bredcrum=JSON.parse(localStorage.getItem('items'));
  // console.log( bredcrum ,"items ")
  const handleBreadcrumbClick = (id) => {
    const breadcrumb = JSON.parse(localStorage.getItem('items')) || [];
  
    // Find the index of the clicked ID in the breadcrumb array
    const index = breadcrumb.findIndex(item => item.id === id);
  
    // Check if the index is valid
    if (index !== -1) {
      // Remove all items from the breadcrumb array starting from the found index
      const updatedBreadcrumb = breadcrumb.slice(0, index);
      localStorage.setItem('items', JSON.stringify(updatedBreadcrumb));
    }
  
    // Continue with navigation logic
    // ...
  };
  // console.log(fkid,"fkid")
        const ShowSudModuleBredcum = async (id) => {
        // console.log(id)
        try {
          setLoading(true);
          const response = await axios.get(`${SHOWSUDMODULE}/${id}`, config);
            // console.log(response,"response")
            setFkidName(response?.data?.message?.data[0]?.test_sub_module)
          // setList(response?.data?.message?.data);
          // setData(response?.data?.message?.data);
          // setLast_page(response?.data?.message?.last_page);
          //  setModuleName(response?.data?.message?.data[0]?.test_sub_module);
          //  setCurrentPage(page);
        //   const subModuleData = response.data.subModule.data;
        //   const singleValue = subModuleData.find((item) => item.id === item.id);
        //   setSubModule(singleValue?.module_id);
    
          setLoading(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");

          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      };
    //  console.log(fkidname,"name")
const getSlug=localStorage.getItem("slug")
function stripHtmlTags(html) {
  // Remove specific HTML tags
  return html.replace(/<br\s*\/?>/gi, "").replace(/<img[^>]*>/gi, "");
}
  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"Test"} />
      <div className="page-content instructor-page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3 style={{ textTransform: "capitalize" }}>
                           {moduleName?moduleName:moduleName1}
                        </h3>
                        <nav
                          aria-label="breadcrumb"
                          style={{ marginTop: "0.5em", marginBottom: "-1.6em" }}
                        >
                          <ol className="breadcrumb">
                            {/* <li
                              className="breadcrumb-item "
                              onClick={() => navigate("/admin/test-series")}
                              aria-current="page"
                              style={{
                                cursor: "pointer",
                                marginRight: "0.5em",
                              }}
                            >
                              Test Series{" "}
                            </li> */}
                            <li
                              className="breadcrumb-item p-0 "
                              style={{
                                fontWeight: "normal",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // let ciphertext = CryptoJS.AES.encrypt(
                                //   JSON.stringify(submoduleid),
                                //   "my-secret-key@admin8623009959"
                                // ).toString();
                                // let ciphertexts =
                                //   encodeURIComponent(ciphertext);
                                navigate(
                                  `/admin/test-series/${getSlug}/${moduleid}`
                                );
                              }}
                              aria-current="page"
                            >
                              {namevalue}
                            </li>
                            {/* {bredcrum?.length>0?bredcrum?.map((item)=>{
                                    // console.log(item)
                                    return(
                                      <li
                                      className="breadcrumb-item "
                                      aria-current="page"
                                      style={{
                                        cursor: "pointer",
                                        // color: "rgb(108, 117, 125)",
                                        // marginLeft: "-.6em",
                                        paddingRight:"0px"
                                      }}
                                      onClick={() => {
                                         handleBreadcrumbClick(item?.id),
                                        navigate(`/admin/test-series-module/${item?.id}/${user}/${moduleid}`)}}

                                    >
                                      {item?.name}
                                    </li>
                                    )
                                  }):<></>} */}
                                  {/* {fkidname? <li
                              className="breadcrumb-item "
                              aria-current="page"
                              style={{ cursor: "pointer",paddingRight:"0px" }}
                              onClick={()=>{
                                navigate(`/admin/test-series-module/${fkidname?.id}/${user}/${moduleid}`)
                              }}
                            >
                              <a>{fkidname?.name}</a>
                            </li>:""} */}
                                  
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              <a>{moduleName1}</a>
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div className="comman-space pb-0">
                        <div
                          // className="settings-tickets-blk course-instruct-blk table-responsive"
                          // id="table-value"
                        >
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              <form action="#">
                                <div
                                  className="row  gx-2 align-items-center"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div className="col-md-3 col-item">
                                    <div
                                      className=" search-group justify-content-start"
                                      id="search-btn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "rgb(246, 106, 98)", // Change the color to yellow
                                        }}
                                      >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                          x1="21"
                                          y1="21"
                                          x2="16.65"
                                          y2="16.65"
                                        ></line>
                                      </svg>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Input Search Text"
                                        // allowClear
                                        onChange={Filter}
                                        onKeyDown={handleKeyPress}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-8 col-lg-8 col-item ">
                                    <div
                                      className="button-container"
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      {/* <Button onClick={() => exportCsv(excel)} className="custom-button"
                                        style={{ border: " 1px solid rgb(0, 128, 0)", color: "rgb(0, 128, 0)", backgroundColor: "white" }}
                                      >
                                        Export Sample
                                      </Button>
                                      <Button onClick={() => handleShowImport()} className="custom-button"
                                        style={{ border: " 1px solid rgb(242, 9, 9)", color: "rgb(242, 9, 9)", backgroundColor: "white" }}

                                      >
                                        Import Excel
                                      </Button> */}
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // if (!word) {
                                            // downloadFileWord();
                                            // setWord(true);
                                          // }
                                          //  window.open(`${currentPath}/sample/sample.docx`,"_blank")
                                             window.open(`https://lmscodenew.chahalacademy.com//sample/sample.docx`,'_blank')
                                        }}
                                        className="custom-button-total"
                                      >
                                        Export Word Sample
                                      </button>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleShowImport();
                                        }}
                                        className="custom-button-free"
                                      >
                                        Import Word Sample
                                      </button>
                                      {checked?.length>0?
                                      <Button
                                        onClick={() =>
                                          // navigate(
                                          //   `/admmin/testseries/add-question/${user}/${subuser}`
                                          // )
                                          handleDeleteQuestionsList(event)
                                        }
                                        className=" btn btn-danger"
                                        style={{}}
                                      >
                                        Delete Questions
                                      </Button>
                                      :""}
                                      <Button
                                        onClick={() =>
                                          navigate(
                                            `/admmin/testseries/add-question/${user}/${subuser}`
                                          )
                                        }
                                        className="custom-button"
                                        style={{}}
                                      >
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>

                          {/* Referred Users */}
                          <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          
                          <Modal
                            title="Import Question"
                            okButtonProps={{
                              style: { backgroundColor: "#FF875A" },
                              children: "Import",
                            }}
                            open={isModalVisible}
                            okText="Import"
                            onOk={() => {
                              handleDelteImoprt();
                            }}
                            onCancel={() => {
                              setIsModalVisible(false);
                              seterror("");
                            }}
                          >
                            <div className=" col-lg-12">
                                <>
                                <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Format of the question
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Q.1) In which phase, the concept of untouchability was put clearly?  <br></br>
      Option a) Rigvedic phase <br/>
      Option b) Later Vedic phase  <br/>
      Option c) Later Gupta phase <br/>
      Option d) Dharmashastra phase <br/>
      Answer) d  <br></br>
      Subject) Ancient History  <br></br>
      desc: The concept of untouchability assumed deﬁnite shape in the early Christian era which was the period known as the era of Dharmashastra in ancient Indian history. During this period, the Brahmanical religion was on ascendences. Various scriptures were being written and the social customs of diﬀerent types were practiced strictly and untouchability was one of them. <br></br>
      </div>
    </div>
  </div>
 
 
</div>
                                </>
                            </div>
                            <div className="mb-3 mt-3">
                              <label htmlFor="formFile" className="form-label">
                                only support word file{" "}
                              </label>
                              <input
                                className="form-control"
                                autoFocus
                                onChange={(e) => setFile(e.target.files[0])}
                                type="file"
                                id="formFile"
                                ref={inputFile}
                              />
                              {Array.isArray(error) ? (
  <div>
    {error.map((errorItem, index) => (
      <div key={index}>
        {Object.values(errorItem).map((errorMessage, i) => (
          <span key={i} style={{ color: "red" }}>
            {errorMessage}
            {i !== Object.values(errorItem).length - 1 && ", "}
          </span>
        ))}
      </div>
    ))}
  </div>
) : (
  <span style={{ color: "red" }}>{error}</span>
)}

                            </div>
                          </Modal>
                        
                          <table className="table table-nowrap ">
                            <thead style={{ textTransform: "capitalize" }}>
                              <tr className="text-center">
                                <th>SR. NO.</th>
                                <th>
                                      <input
                                        className="form-check-input"
                                         style={{ marginRight: "-5px" }}
                                       checked={isCheckAll}
                                        // onClick={(e) => handleSelectAll(e)}
                                        onChange={(e) => handleSelectAll(e)}

                                        type="checkbox"
                                        placeholder="Selecte All"
                                      />
                                    </th>  
                                    {/* <th>Select</th>                              */}
                                     <th>QUESTION</th>
                                <th>TYPE</th>
                                <th>CHAPTER</th>
                                <th>STATUS</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {loading === true ? (
                             <tbody   className="d-flex justify-content-center align-items-center"
                             style={{
                               position: "fixed",
                               top: 0,
                               right: 0,
                               bottom: 0,
                               left: 0,
                               backgroundColor: "rgba(255, 255, 255, 0.6)",
                             }}>
                               <tr>
                                 <td>
                                   <div
                                     className="spinner-border text-warning"
                                     role="status"
                                   >
                                     <span className="sr-only">Loading...</span>
                                   </div>
                                 </td>
                               </tr>
                             </tbody>
                            ) : (
                              list?.map((item, i) => {
                                console.log(item)
                                const serialNumber =
                                  (currentPage - 1) * itemsPerPage + i + 1;
                                  const res = checked?.filter(
                                    (e) => e == item.id
                                  );

                                return (
                                  <tbody
                                    style={{ textTransform: "capitalize" }}
                                    key={serialNumber}
                                  >
                                    <tr className="text-center">
                                      <td>{serialNumber}</td>
                                      <td className="text-center"
                                          >
                                                <div className="form-check form-check-inline">
                                                  {res.map((e) => {
                                                    if (e) {
                                                      return (
                                                        <input
                                                         checked
                                                          className="form-check-input"
                                                          style={{
                                                            marginLeft: "0px",
                                                          }}
                                                          type="checkbox"
                                                          onChange={(e) => {
                                                            handelDeleteQuestions(
                                                              item.id,
                                                              e.target.checked
                                                            );
                                                          }}
                                                          key={item?.id}
                                                          id="inlineCheckbox1"
                                                        />
                                                      );
                                                    } else {
                                                      return (
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          style={{
                                                            marginLeft: "0px",
                                                          }}
                                                          onChange={(e) => {
                                                            handelDeleteQuestions(
                                                              item.id,
                                                              e.target.checked
                                                            );
                                                          }}
                                                          key={item?.id}
                                                          id="inlineCheckbox1"
                                                        />
                                                      );
                                                    }
                                                  })}
                                                  {res.length == 0 ? (
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      style={{
                                                        marginLeft: "0px",
                                                      }}
                                                      onChange={(e) => {
                                                        handelDeleteQuestions(
                                                          item.id,
                                                          e.target.checked
                                                        );
                                                        handelselctedvalue()
                                                      }}
                                                      key={item?.id}
                                                      id="inlineCheckbox1"
                                                    />
                                                  ) : null}
                                                </div>
                                              </td>
                                      <td
                                        style={{
                                          whiteSpace: " normal !important",
                                          wordWrap: "break-word",
                                          maxWidth: "450px",
                                        }}
                                      >
                                        <Tooltip title={stripHtmlTags(item?.name)}>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: item?.name.substr(0, 40),
                                            }}
                                                      key={item?.id}
                                                      id="inlineCheckbox1"
                                          />
                                        </Tooltip>
                                      </td>
                                      <td>
                                        {item?.question_type_id == "1"
                                          ? "MCQ"
                                          : "Subjective"}
                                      </td>
                                      <td>
                                        {item?.chapter
                                          
                                          ? item?.chapter: "None"}
                                      </td>
                                      <td>
                                        {" "}
                                        <Switch
                                          onChange={() => {
                                            handleChange(
                                              item?.id,
                                              item?.status
                                            );
                                          }}
                                          checked={
                                            item?.status == 1 ? true : false
                                          }
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={15}
                                          width={38}
                                          className="react-switch"
                                        />
                                      </td>
                                      <td>
                                        <Tooltip title="Edit">
                                          <button
                                            onClick={() => handleEdit(item.id)}
                                            type="button"
                                            className="btn btn-secondary"
                                          >
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </button>{" "}
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <Button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => showConfirm(item.id)}
                                          >
                                            <i className="fa-regular fa-trash-can"></i>
                                          </Button>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            )}
                          </table>
                          </div>
                       
                          {list?.length <= 0 ? (
                            <h5 className="text-center">{message}</h5>
                          ) : (
                            ""
                          )}
                          {/* Referred Users */}
                        </div>
                        <div className="fixtable">
                          {list?.length <= 0 ? (
                            ""
                          ) : (
                            <PaginationQuestion
                              nPages={last_page}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              link={SHOWQUESTION}
                              setData={setList}
                              user={user}
                              subuser={subuser}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
            <ToastContainer />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
