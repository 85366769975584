import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik } from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../../instructor/adminHeader";
import { notification, Space } from "antd";

const CREATEVIMEO = "/api/admin/vimeo_Account";
const initalValue = {
    name: "",
    VIMEO_ACCESS:"",
    VIMEO_CLIENT: "",
    VIMEO_SECRET: "",
  };
  const VimeoSchema = Yup.object({
    name: Yup.string().required("Please enter your User Name"),
    VIMEO_ACCESS:Yup.string().required("Please enter your Vimeo Access"),
    VIMEO_CLIENT:Yup.string().required("Please enter your Vimeo ClinetId"),
    VIMEO_SECRET:Yup.string().required("Please enter your Vimeo SecretKey"),

  });



export default function CreateVimeoUser() {
  const navigate = useNavigate();

  const [submit, setSubmit] = useState(false)

  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false)
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: col }}>
                <strong>{text}</strong>
              </div>
            ),
          duration: 1
        });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (values, { setFieldError }) => {
      setSubmit(true)
      try {
        // console.log(value);
        const formData = new FormData();

        formData.append("name", values?.name);
        formData.append("VIMEO_ACCESS", values?.VIMEO_ACCESS);
        formData.append("VIMEO_CLIENT", values?.VIMEO_CLIENT);
        formData.append("VIMEO_SECRET", values?.VIMEO_SECRET);


        const response = await axios.post(CREATEVIMEO, formData, config);
        // console.log(response);
        if (response) {
          setToast(true)
          openNotificationWithIcon("success", 'Vimeo Account Created', "#52c41a")
          setTimeout(() => {
            navigate("/admin/vimeo-video/users");
          }, 1000);
          // toast.success("Batch Created");
        }
      } catch (error) {
        console.log(error);
        setSubmit(false)
        setToast(false)
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red")
        }
        else {
          openNotificationWithIcon("error", 'Internal Server Error', "red")
        }
       

      }
    },
    validationSchema: VimeoSchema,
  });


  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute")
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute")
    }
    else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
          style={{ marginTop: "-15px", minWidth: "100%" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-9 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Vimeo Account</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate('/admin/vimeo-video/users')}>Vimeo Account</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Vimeo Account
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Vimeo Account Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={values?.name}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter your Account Name"
                              />
                              {errors?.name && touched?.name ? (
                                <span style={{ color: "red" }}>
                                  {errors?.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Vimeo Token
                              </label>
                              <input
                                type="text"
                                name="VIMEO_ACCESS"
                                id="VIMEO_ACCESS"
                                value={values?.VIMEO_ACCESS}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter your  Vimeo Secret"
                              />
                              {errors?.VIMEO_ACCESS && touched?.VIMEO_ACCESS ? (
                                <span style={{ color: "red" }}>
                                  {errors?.VIMEO_ACCESS}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Vimeo Identifier
                              </label>
                              <input
                                type="text"
                                name="VIMEO_CLIENT"
                                id="VIMEO_CLIENT"
                                value={values?.VIMEO_CLIENT}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter your Identifier"
                              />
                              {errors?.VIMEO_CLIENT && touched?.VIMEO_CLIENT ? (
                                <span style={{ color: "red" }}>
                                  {errors?.VIMEO_CLIENT}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Vimeo Secret
                              </label>
                              <input
                                type="text"
                                name="VIMEO_SECRET"
                                id="VIMEO_SECRET"
                                value={values?.VIMEO_SECRET}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter your Vimeo Token"
                              />
                              {errors?.VIMEO_SECRET && touched?.VIMEO_SECRET ? (
                                <span style={{ color: "red" }}>
                                  {errors?.VIMEO_SECRET}
                                </span>
                              ) : null}
                            </div>
                          </div>


                       

                        <div className="update-profile"
                          id="submit-button"

                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >


                            <button type="submit" className="btn btn-primary"
                              disabled={submit}

                            >
                              Submit
                            </button>



                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
}
