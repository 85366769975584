import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";

import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import { Input, Tooltip } from "antd";
import Button from "react-bootstrap/Button";

import axios from "../../../api/axios";
import CryptoJS from "crypto-js";

const STUDENTLIST = "api/payment-list";
const DELETESTUDENT = "/api/student/delete";
const PAYEDINSTALMENTS = "/api/course-installment-paid";

import "react-toastify/dist/ReactToastify.css";
import { notification, Modal } from "antd";
import Form from "react-bootstrap/Form";
import "../addAdminRecordedVideos/insex.css";
import moment from "moment";
const { confirm } = Modal;

export default function AddAdminTransactionList() {
  const { Search } = Input;
  const [install, setinstall] = useState(null);
  const [paid, setpaid] = useState("");
  const [list, setList] = useState([]);
  // const [itemsPerPage, setitemsPerPage] = useState('')
  const [userid, setuserid] = useState("");
  const [batchid, setbatchid] = useState("");
  const [courseid, setcourseid] = useState("");
  const [paydata, setpaydata] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [message, setmessage] = useState("No Data");
  const [total_amount, settotal_amount] = useState("");
  const [remaing, setRemaing] = useState("");
  const [sum, setsum] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(new Date());

  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fecthSudentList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(STUDENTLIST, config);
      // console.log(resonse?.data,"res")
   
        // console.log(batch_detail_relation,"batch_detail_relation")

      // setitemsPerPage(batch_detail_relation.length)
       setList(resonse?.data);
       setData(resonse?.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon1("error","Internal Server Error","red")
      }
    }
  };

  useEffect(() => {
    fecthSudentList();
  }, []);

  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon1 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  // console.log(list,"list")

  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblerecordsData = list?list?.slice(indexOfFirstCourse, indexOfLastCourse):[];
  const recordsDataLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math.ceil(recordsDataLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);
  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1);
  };
 

  



  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      const res = list.filter((e) =>
        e.student.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (res.length > 0) {
        setList(res);
      }
      if (res.length == 0) {
        setList(res);
        setmessage("No Data Found");
      }
      if (searchValue.length === 0) {
        setList(data);
      }
    } catch (error) {
      console.error("Error deleting student:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  let itemsPerPage = 10;
  const renderPagination = () => {
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(recordsDataLength / itemsPerPage);

    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()].map((pageNum) => (
        <li
          key={pageNum}
          className={
            currentPage === pageNum + 1 ? "page-item active" : "page-item"
          }
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis2" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"transaction"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget ttt">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3 style={{ textTransform: "capitalize" }}>
                            Transactions List
                          </h3>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            // className="settings-referral-blk table-responsive"
                            // id="table-value"
                          >
                            {/* <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          // allowClear
                                          onChange={Filter}
                                          onKeyPress={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div> */}
                            <div className="settings-referral-blk table-responsive">
                           
                            <table className="table table-nowrap mt-2">
                              <thead>
                                <tr className="text-center">
                                  <th>Sr.No</th>
                                  <th>Student Name</th>
                                  <th>Course Name</th>
                                  <th>Amount</th>
                                  <th>Payment Status</th>
                                  <th>Transaction Id</th>
                                   <th>Payment Date</th> 
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                
                                  <tbody  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td className="spinner-border text-warning sr-only"
                                          role="status">
                                       <>
                                       Loading...
                                      
                                       </>
                                        
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : visiblerecordsData?.length > 0 ? (
                                visiblerecordsData?.map((item, i) => {
                                  // console.log(visiblerecordsData,"visiblerecordsdata")
                                  const serialNumber =
                                    (currentPage - 1) * itemsPerPage + i + 1;
                                  return (
                                    <tbody
                                      key={i}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <tr className="text-center">
                                        <td>{serialNumber}</td>
                                        <td>
                                          <Link
                                            // to="student-profile"
                                            className="refer-avatar-blk align-items-center"
                                          >
                                            <p>{item?.paymentuser[0]?.name}</p>
                                          </Link>
                                        </td>
                                        <td>{item?.paymentcourse[0]?.name}</td>
                                        <td>{item?.amount}</td>
                                        <td>
                                         {item?.payment_status}
                                        </td>
                                        <td>
                                          {item?.transactionId}
                                        </td>
                                        <td>
                                          {" "}
                                          {moment(item?.created_at).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                       
                                      </tr>
                                    </tbody>
                                  );
                                })
                              ) : (
                                ""
                              )}
                            </table>
                            </div>
                            {visiblerecordsData?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {visiblerecordsData?.length <= 0 ? (
                              ""
                            ) : (
                              <ul
                                className="pagination lms-page d-flex align-items-center justify-content-center"
                                style={{ marginLeft: "1.5em" }}
                              >
                                <li
                                  className="page-item prev"
                                  onClick={() => prevPageHandler()}
                                >
                                  <Link
                                    className="page-link"
                                    to="#"
                                    tabIndex={-1}
                                  >
                                    <i className="fas fa-angle-left" />
                                  </Link>
                                </li>
                                {renderPagination()}

                                <li
                                  className="page-item next"
                                  onClick={() => nextPageHandler()}
                                >
                                  <Link className="page-link" to="#">
                                    <i className="fas fa-angle-right" />
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
