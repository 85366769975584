import React from 'react'
import { useState } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import axiosInstance from '../../../api/axios1';
import { useEffect } from 'react';
import StudentHeader from '../studentHeader';
import Footer from '../../footer';
import { Icon1 } from '../../imagepath';
const EXPIRED="api/student/get/expired_courses"

const ExpiredCourse = () => {
  
  const [loading, setLoading] = useState(false);
  const [expireddata,setExpiredData]=useState([])
  const tokenStudent = localStorage.getItem("tokenStudent");

  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const ExpiredCourse = async () => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(EXPIRED, config);
      // console.log(resonse?.data,"response")
      setExpiredData(resonse?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification")

        navigate("/login");
      }
      else{
        openNotificationWithIcon3("error",error?.response?.data?.message)
      }
      // openNotificationWithIcon2("error");
    }
  };

  useEffect(() => {
    ExpiredCourse();
    
  }, []);
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  // console.log(expireddata,"expired")
  return (
    <div className="main-wrapper">
    <StudentHeader />
    {/* Student Dashboard */}
    <div className="page-content">
      <div className="container">
        <div className="row">
          <div className='col-6'
          style={{margin:"auto"}}
          >
          {expireddata?.length > 0 ? (
                          <Scrollbar style={{ height: 250 }}>
                            <table className="table table-nowrap">
                              <thead>
                                <tr >
                                  <th  >Title</th>
                                  <th >Expired Date</th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                {expireddata?.length > 0 &&
                                  expireddata?.map((item, i) => {
                                    const dateParts = item?.expiry_date.split('-');
                                    const formattedDate = dateParts.reverse().join('-');
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <p>
                                            <img
                                              src={Icon1}
                                              alt=""
                                              className="me-2"
                                            />
                                            {item?.course[0]?.name}
                                          </p>
                                        </td>
                                        <td> {formattedDate}</td>
                                       
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </Scrollbar>
                        ) : (
                          <h4 style={{ padding: "20px",textAlign:"center" }}>
                            No Courses
                          </h4>
                        )}
          </div>
        
        </div>
      </div>
    </div>
    <Footer />
  </div>
   
  )
}

export default ExpiredCourse