import React from 'react'
import { useState } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import axiosInstance from '../../../api/axios1';
import { useEffect } from 'react';
import StudentHeader from '../studentHeader';
import Footer from '../../footer';
import { Icon1 } from '../../imagepath';
const INVOICE="api/get-student-InvoiceList"
import { LogOut, Moon, Star, User, Delete ,Download} from "react-feather";
import { Link, useNavigate } from "react-router-dom";


const Updates = () => {
  
  const [loading, setLoading] = useState(false);
  const [invoicedata,setInvoiceData]=useState([])
  const tokenStudent = localStorage.getItem("tokenStudent");

  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const InvoiceDownload = async () => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(INVOICE, config);
      //  console.log(resonse?.data,"response")
      setInvoiceData(resonse?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {

        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification")

        navigate("/login");
      }
      else{
        openNotificationWithIcon3("error",error?.response?.data?.message)
      }
      // openNotificationWithIcon2("error");
    }
  };

  useEffect(() => {
    InvoiceDownload();
    
  }, []);
  // console.log(invoicedata,"expired")
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  return (
    <div className="main-wrapper">
    <StudentHeader />
    {/* Student Dashboard */}
    
    {/* <Footer /> */}
  </div>
   
  )
}

export default Updates