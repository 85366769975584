import React, { useRef, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CryptoJS from "crypto-js";
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import "./index.css";
import AdminInstructorSidebar from "../adminSidebar";
import axios from "../../../api/axios";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import AdminHeader from "../adminHeader";
// import Modal from "react-bootstrap/Modal";
import { Tooltip, Input } from "antd";
import Select from "react-select";

import { notification, Modal } from "antd";
const { confirm } = Modal;
import Form from "react-bootstrap/Form";
import { Download, FileText, Upload } from "react-feather";

const AMOUNT = "/api/course-amount";

const BATCHSTUDENTS = "/api/student-batch-view";
const DELETE = "/api/student-delete-batchList";
const EXPORT = "/api/import/userpayemtterm";
const Import = "/api/export/userpayemtterm";
const DOWNLOADEXCEL = "/api/admin/batch/user/download";
const COURSESLIST = "/api/course-list-batch";
const ADDCOURSE = "/api/add-subject-batch";
const ADDSUBSCRPTION = "/api/multiple-user-subscription";

export default function AddBatchStudents() {
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const config2 = {
    headers: { Authorization: `Bearer ${token}`, Accept: "application/json" },
  };
  const { slug, id } = useParams();

  const navigate = useNavigate();
const [new1,setNew1]= useState("")
useEffect(()=>{
  setNew1(1)

},[])
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [lists, setLists] = useState(null);
  const [batchDetails, setBatchDetails] = useState([]);
  const [error, seterror] = useState("");
  const [showImport, setShowImport] = useState(false);
  const [excel, setExcel] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [message, setmessage] = useState("No Data");
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, setLast_page] = useState(null);
  const [batchid, setBatchId] = useState("");
  const inputFile = useRef(null);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [courseslist, setcourseslist] = useState([]);
  const [courseid, setCourseid] = useState([]);
  // console.log(courseid)
  const [userdata, setUserData] = useState([]);
  const handleClose = () => setShow(false);
  const [coursedata, setCourseData] = useState("");
  const [instalmentdata, setinstalmentdata] = useState("");
  const [coursename, setCoureName] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [coursevalue, setCoursevalue] = useState("");
  const [checked, setChecked] = useState([]);
  const[coursedataList,setCourseDataList]=useState([])
  const [formState, setFormState] = useState({
    expiry_date: "",
    total_amount: "",
    installAmount: "",
    pending_fees: "",
    batch_id: "",
    users: [],
    courseId: "",
  });
  const[error1,setError1]=useState()
  const [batchsubjectlist, setBatchSubjectList] = useState([]);
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "auto",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "white" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const [batchname, setBatchName] = useState("");
  // console.log(formState, "fomstate");
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Student Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const handleDeleteStudent = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(`${DELETE}/${id}`, config);
      openNotificationWithIcon("success");
      setLoading(false);
      fetchBatchData();
      setShow(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this student?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETE}/${id}`, config);
          openNotificationWithIcon("success");
          setLoading(false);
          fetchBatchData();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            openNotificationWithIconSesion("error")
            navigate("/admin/login");
          }
else{
          openNotificationWithIcon2("error");
}
        }
      },
      onCancel() {},
    });
  }
  // Fetch batch data from the API

  const fetchBatchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BATCHSTUDENTS}/${slug}`, config);
      // console.log(response?.data?.course,"coures")
      setBatchData(response?.data?.batcheDetails);
      setBatchName(response?.data?.batche?.name);
      setLists(response?.data?.batcheDetails);
      setUserData(response?.data?.userid);
      setBatchId(response?.data?.batche?.id);
      setLast_page(response?.data?.user?.last_page);
      setCourseData(response?.data?.course);
      setinstalmentdata(response?.data?.installment);
      setCoureName(response?.data?.courename);
      setBatchSubjectList(response?.data?.batchsubjectlist);
      setCourseDataList(response?.data?.courselist)
      setLoading(false);
    } catch (error) {
      console.log("Error fetching batch data:", error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
  };

  useEffect(() => {}, [batchDetails, selectedStudents]);

  // Fetch batch data on component mount
  // console.log(coursedata,"course")
  useEffect(() => {
    fetchBatchData();
  }, []);
  // console.log(batchsubjectlist, "batchsubjectlist");

  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblebatchData = batchData?.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );
  const batchDataLength = batchData?.length ? batchData?.length : 0;
  const numOFTotalPage = Math.ceil(batchDataLength / 10);
  // const page = [...Array(numOFTotalPage + 1).keys()].slice(1)
  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1);
  };
  const itemsPerPage = 10;
  const renderPagination = () => {
    let pages = []
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(batchDataLength / itemsPerPage);

    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()].map((pageNum) => (
        <li
          key={pageNum}
          className={
            currentPage === pageNum + 1 ? "page-item active" : "page-item"
          }
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis2" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  const Filter = async (e) => {
    const searchValue = e.target.value;
    // setBatchData(res.data.allVideos);
    if (searchValue.length > 0) {
      const res = batchData.filter((e) =>
        e.userrelation[0].name.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (res.length == 0) {
        setBatchData(res);
        setmessage("No Data Found");
      } else {
        setBatchData(res);
        setmessage("No Data");
      }
    }
    if (searchValue.length === 0) {
      setBatchData(lists);
    }
  };
  const config1 = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "blob" },
    responseType: "arraybuffer",
  };

  const exportCsv = async (excel) => {
    try {
      const response = await axios.get("/api/export/userpayemtterm", config1);
      const fileExtension = ".xlsx";
      const data = new Blob([response?.data], {
        type: "application/vnd.ms-excel",
      });
      FileSaver.saveAs(data, "Student" + fileExtension);
    } catch (error) {
      console.log(error);
      openNotificationWithIcon1("error", error?.response?.data?.message, "red");

    }
  };
  const handleShowImport = () => {
    setShowImport(true);
    setIsModalVisible(true);
  };
  const handleCloseImoprt = () => setShowImport(false);

  const handleDelteImoprt = async () => {
    const formData = new FormData();

    formData.append("file", file);
    seterror("");
    try {
      const response = await axios.post(EXPORT, formData, config);
      openNotificationWithIconImport("success", "Student Created", "#52c41a");
      fetchBatchData();
      if (response) {
        setShowImport(false);
        setIsModalVisible(false);
        if (inputFile.current) {
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        }
      }
    } catch (error) {
      console.log(error);
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      if (error?.response?.status === 422) {
        openNotificationWithIconImport(
          "error",
          error.response.data.message,
          "red"
        );
      } else {
        openNotificationWithIconImport("error", error?.response?.data?.message, "red");
      }
      seterror(error.response.data.message);
     
    }
  };
  const openNotificationWithIconImport = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const amountchange=()=>{
    // dumy function 
  }
  const handleinstallments = (id, batch_id) => {
    navigate(`/admin/course-instalments/${id}/${batch_id}`);
  };
  const handeladdsubscrptions = (id, batch_id, coursedata) => {
    navigate(`/admin/addsubscrption/${id}/${batch_id}/${coursedata}`);
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  const exportCsv1 = async (excel) => {
    try {
      const response = await axios.get(`${DOWNLOADEXCEL}/${batchid}`, config1);
      if(response?.status==210){
        openNotificationWithIcon1("error", "Please Selecte Course", "red");

      }
      else{
      setExcel(response.data);
      const fileExtension = ".xlsx";
      const data = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      FileSaver.saveAs(data, "Student" + fileExtension);
    }
    } catch (error) {
      console.log(error);
      // setError1(error?.response)
      // setWord(false);
      openNotificationWithIcon1("error", error?.response?.data?.message, "red");

    }
  };
  // console.log(error1,"error")
  const handelCourse = (course_id) => {
    // setcourseid(course_id);
    handleShowForm();
  };
  const handelSubscrptions = (course_id) => {
    // setcourseid(course_id);
    if (checked?.length > 0) {
      handleShowForm1();
    } else {
      openNotificationWithIcon1("error", "Please Selecte Student", "red");
    }
  };
  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const handleShowForm1 = () => {
    setIsModalVisibleForm1(true);
  };
  const coursesdatavalue = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(`${COURSESLIST}/${batchid}`, config);
      // console.log(resonse,"resonse")
      setcourseslist(resonse?.data);
      setLoading(false);
      fetchBatchData();
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        // localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error");
    }
  };
  useEffect(() => {
    // coursesdatavalue();
  }, []);
  //  console.log(batchid,"batchid")
  const handleAddNotification = async () => {
    try {
      const response = await axios.post(
        ADDCOURSE,
        {
          course_id: courseid,
          batch_id: batchid,
        },
        config
      );
      // console.log(response);
      if (response) {
        setIsModalVisibleForm(false);
        openNotificationWithIcon1(
          "success",
          response?.data?.message,
          "#52c41a"
        );
        fetchBatchData();

        // Notifications();
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      if (error.response.status == 403) {
        openNotificationWithIcon1("error", "403", "red");
      } else {
        openNotificationWithIcon1("error", error.response.data.message, "red");
      }
    }
  };
  const openNotificationWithIcon1 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleChange = (id, check) => {
    if (check == true) {
      // handleShowForm();
    }
    const arr = [...checked];
    if (!arr.includes(id)) {
      setChecked([...arr, id]);
    } else {
      const res = arr.filter((e) => e !== id);
      setChecked(res);
    }
  };
  //  console.log(checked,"checked")
  
  const handelsubmitSubscrption = async () => {
    const updatedFormState = {
      ...formState,
      batch_id: batchid, // Add batch_id to formStateadda
      users: checked, // Add users to formState
      courseId: courseid,
    };
    // console.log(updatedFormState,"upadte formstate")
    try {
      const response = await axios.post(
        ADDSUBSCRPTION,
        updatedFormState,
        config
      );
      if (response) {
        setIsModalVisibleForm1(false);
        openNotificationWithIconImport(
          "success",
          response.data.success,
          "#52c41a"
        );
        // fetchBatchData()
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        openNotificationWithIconImport(
          "error",
          error.response.data.error,
          "red"
        );
      } else {
        openNotificationWithIconImport(
          "error",
          error.response.data.message,
          "red"
        );
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  // console.log(checked,"checked")

  const handleInputChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [field]: value,
    }));
  };
  const courseamount = async (value) => {
     console.log(value,"id")
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      // setLoading(true);
      const resonse = await axios.get(`${AMOUNT}/${value?value:batchsubjectlist[0]?.courseId}`, config);
      // console.log(resonse,"amount");
      setFormState((prevFormState) => ({
        ...prevFormState,
        total_amount: resonse?.data[0]?.amount,
      }));

      // console.log(resonse,"amount")
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      openNotificationWithIcon2("error");
    }
  };
  useEffect(()=>{

  },[])
  const getSelectOptions = () => {
    return courseslist.map((course) => {
      const isDisabled = batchsubjectlist.some(
        (subject) => subject?.courseId === course?.id
      );
      return {
        label: course.name,
        value: course.id,
        isDisabled: isDisabled,
      };
    });
  };
  useEffect(() => {
    const initialSelectedOptions = batchsubjectlist?.map((subject) => ({
      label: subject.courseName,
      value: subject.courseId,
      isDisabled: true,
    }));
    setSelectedOptions(initialSelectedOptions);
  }, [batchsubjectlist]);
  useEffect(() => {
    // Use a separate effect to handle courseid after selectedOptions is updated
    const initialCourseIds = selectedOptions?.map((option) => option.value);
    setCourseid(initialCourseIds);
  }, [selectedOptions]);

  // console.log(selectedOptions, "courseid", courseid);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Batches"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h3 style={{ textTransform: "capitalize" }}>
                              Students
                            </h3>
                            <div>
                              {/* <h3 style={{ textTransform: "capitalize" }}>
                        {coursename?.name} {"Course"}
                          </h3> */}
                            </div>
                          </div>

                          <nav
                            aria-label="breadcrumb"
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "-1.6em",
                            }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item"
                                style={{ cursor: "pointer" }}
                              >
                                <a onClick={() => navigate("/admin/batchList")}>
                                  {batchname}
                                </a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                style={{ cursor: "pointer" }}
                              >
                                <a onClick={() => navigate("")}>Students</a>
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="comman-space pb-0">
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              {/* <h4 className="text text-secondary">{name.slice(0,60)}</h4> */}
                              <form action="#">
                                {/* <p>Zoom App Details</p> */}
                                <div
                                  className="row gx-2 align-items-center"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div className="col-md-5 col-lg-5 col-item ">
                                    <div
                                      className="button-container justify-content-start"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className=" search-group ">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          // allowclear
                                          onChange={Filter}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                      <button
                                        className="custom-button-total"
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        Total Students{": "}
                                        {batchData?.length}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-md-7 col-lg-7 col-item">
                                    <div
                                      className="button-container"
                                      style={{
                                        justifyContent: "flex-end",
                                        gap: "5px",
                                      }}
                                    >
                                      <Tooltip title="Download Excel">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            exportCsv1(excel);
                                          }}
                                          // style={{ marginRight: ".5em" }}
                                          className="custom-button-excel"
                                        >
                                          <Download size={20} />
                                        </button>
                                      </Tooltip>
                                      <Tooltip title="Export Excel">
                                        <button
                                          onClick={(e) => {
                                            exportCsv(excel),
                                              e.preventDefault();
                                          }}
                                          className="custom-button-total"
                                        >
                                          <FileText size={20} />
                                        </button>
                                      </Tooltip>
                                      <Tooltip title="Import Excel">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleShowImport();
                                          }}
                                          className="custom-button-free"
                                        >
                                          <Upload size={20} />
                                        </button>
                                      </Tooltip>
                                      <Button
                                        onClick={(e) => {
                                          handelSubscrptions();
                                          coursesdatavalue()
                                        }}
                                        className="btn custom-button btn-dark"
                                        // style={{ paddingLeft: "5px", paddingRight: "5px", width: '18%', marginLeft: '.1em' }}
                                      >
                                        {/* <i className="fa-solid fa-plus"></i>{" "} */}
                                        Submit{" "}
                                      </Button>
                                      <Button
                                        onClick={(e) => {
                                          // navigate(
                                          //   `/admin/batcheslink/add-user/${slug}/${encodeURIComponent(
                                          //     id
                                          //   )}`
                                          // );
                                          handelCourse();
                                        }}
                                        className="btn custom-button"
                                        // style={{ paddingLeft: "5px", paddingRight: "5px", width: '18%', marginLeft: '.1em' }}
                                      >
                                        {/* <i className="fa-solid fa-plus"></i>{" "} */}
                                        Select Course{" "}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="settings-referral-blk table-responsive">
                            {/* Instructor Users */}
                            {/* <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Studentkkk</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are You Sure Want Delete This Student?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleDeleteStudent}
                                >
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal> */}

                            {/* excell file */}
                            {/* import student */}
                            <Modal
                              title="Import Student"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisible}
                              okText="Import"
                              onOk={() => {
                                handleDelteImoprt();
                              }}
                              onCancel={() => {
                                setIsModalVisible(false);
                                seterror("");
                              }}
                            >
                              <div className="mb-3">
                                <label htmlFor="formFile" className="form-label">
                                  only support excel file
                                </label>
                                <input
                                  className="form-control"
                                  autoFocus
                                  onChange={(e) => setFile(e.target.files[0])}
                                  ref={inputFile}
                                  type="file"
                                  id="formFile"
                                />
                                {error && (
                                  <span style={{ color: "red" }}>{error}</span>
                                )}
                              </div>
                            </Modal>

                            {/* slecte course */}
                            <Modal
                              title="Add Course"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisibleForm}
                              okText="Create"
                              onOk={() => {
                                handleAddNotification();
                              }}
                              onCancel={() => {
                                setIsModalVisibleForm(false);
                              }}
                            >
                              <div
                                className="col-lg-12"
                                style={{ marginTop: "15px" }}
                              >
                                <div className="form-group">
                                  <label className="form-label">
                                    Select Course
                                  </label>

                                  <Select
                                    className="select country-select"
                                    menuPlacement="bottom"
                                    name="course"
                                    isMulti
                                    // options={getSelectOptions()}
                                    options={coursedataList?.map((ele) => {
                                      return {
                                        label: ele.name,
                                        value: ele.id,
                                      };
                                    })}
                                    placeholder="Select Course"
                                    // onChange={(selectedOptions) => {
                                    //   // Filter out the pre-selected values from the new selection
                                    //   const newSelectedOptions = selectedOptions.filter(
                                    //     (option) => !batchsubjectlist.some((subject) => subject.courseId === option.value)
                                    //   );
                                    //   setSelectedOptions(newSelectedOptions);
                                    // }}
                                    onChange={(selectedOptions) => {
                                      setSelectedOptions(selectedOptions);
                                    }}
                                    value={selectedOptions}
                                    styles={style}
                                  />

                                  {/* {errors.batch && touched.batch ? (
      <span style={{ color: "red" }}>{errors.batch}</span>
    ) : null} */}
                                </div>
                              </div>
                            </Modal>
                            {/* checkbox modal */}
                            <Modal
                              title="Add Subscription"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisibleForm1}
                              okText="Create"
                              onOk={() => {
                                handelsubmitSubscrption();
                              }}
                              onCancel={() => {
                                setIsModalVisibleForm1(false);
                              }}
                            >
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Select Course</Form.Label>
                                  <Form.Select
                                    value={courseid} // Set the selected value here
                                    onChange={(e) => {
                                      setCourseid(e.target.value),
                                        courseamount(e?.target.value);
                                    }} // Handle the change event
                                    placeholder="Select a course"
                                    multiple={false}
                                  >
                                    <option value="">Select...</option>
                                    {courseslist?.map((option) => (
                                      <option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput2"
                                >
                                  <Form.Label>Expiry Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    value={formState?.expiry_date}
                                    min={new Date().toISOString().split("T")[0]}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "expiry_date",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput3"
                                >
                                  <Form.Label>Total Amount</Form.Label>
                                  <Form.Control
                                    type="number"
                                    value={formState?.total_amount?formState?.total_amount:""}
                                    onChange={amountchange}
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput4"
                                >
                                  <Form.Label>Install Amount</Form.Label>
                                  <Form.Control
                                    type="number"
                                    value={formState?.installAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "installAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput5"
                                >
                                  <Form.Label>Pending Fees</Form.Label>
                                  <Form.Control
                                    type="number"
                                    value={formState?.pending_fees}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "pending_fees",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Form>
                            </Modal>

                            <table className="table table-nowrap mt-2">
                              <thead>
                                <tr className="text-center">
                                  <th>SR. NO.</th>
                                  {coursedata == 1 ? <th>Select</th> : null}
                                  <th>Name</th>
                                  <th>EMAIL</th>
                                  <th>PHONE</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                               <tbody   className="d-flex justify-content-center align-items-center"
                               style={{
                                 position: "fixed",
                                 top: 0,
                                 right: 0,
                                 bottom: 0,
                                 left: 0,
                                 backgroundColor: "rgba(255, 255, 255, 0.6)",
                               }}>
                                 <tr>
                                   <td>
                                     <>
                                     <div
                                       className="spinner-border text-warning"
                                       role="status"
                                     >
                                       <span className="sr-only">
                                         Loading...
                                       </span>
                                     </div>
                                     </>
                                   </td>
                                 </tr>
                               </tbody>
                              ) : (
                                visiblebatchData
                                  ?.sort((a, b) => b.id - a.id)

                                  ?.map((item, i) => {
                                    const sNo =
                                      (currentPage - 1) * itemsPerPage + i + 1;
                                    // console.log(item?.userrelation[0]?.id,"item")
                                    const isUserInData = userdata?.some(
                                      (user) =>
                                        user?.user_id ==
                                        item?.userrelation[0]?.id
                                    );
                                    // console.log(isUserInData,"user_id")
                                    const instamentdatavalue =
                                      instalmentdata?.some(
                                        (user) =>
                                          +user?.user_id ==
                                          item?.userrelation[0]?.id
                                      );
                                    // console.log(instamentdatavalue,"instalment")
                                    const res = checked?.filter(
                                      (e) => e == item?.id
                                    );
                                    return (
                                      <tbody key={item?.id}>
                                        <tr className="text-center">
                                          <td>{sNo}</td>
                                          {coursedata == 1 ? (
                                            <td className="text-center">
                                              <div className="form-check form-check-inline">
                                                {res.map((e) => {
                                                  if (e) {
                                                    return (
                                                      <input
                                                        checked
                                                        className="form-check-input"
                                                        style={{
                                                          marginLeft: "0px",
                                                        }}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                          handleChange(
                                                            item?.fk_student_id,
                                                            e.target.checked
                                                          );
                                                        }}
                                                        // disabled={isUserInData}
                                                        id="inlineCheckbox1"
                                                      />
                                                    );
                                                  } else {
                                                    return (
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        style={{
                                                          marginLeft: "0px",
                                                        }}
                                                        onChange={(e) => {
                                                          handleChange(
                                                            item?.fk_student_id,
                                                            e.target.checked
                                                          );
                                                        }}
                                                        // disabled={isUserInData}
                                                        id="inlineCheckbox1"
                                                      />
                                                    );
                                                  }
                                                })}
                                                {res.length == 0 ? (
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    style={{
                                                      marginLeft: "0px",
                                                    }}
                                                    onChange={(e) => {
                                                      handleChange(
                                                        item?.fk_student_id,
                                                        e.target.checked
                                                      );
                                                    }}
                                                    // disabled={isUserInData}
                                                    id="inlineCheckbox1"
                                                  />
                                                ) : null}
                                              </div>
                                            </td>
                                          ) : (
                                            ""
                                          )}

                                          <td>{item?.userrelation[0]?.name}</td>
                                          <td>
                                            {item?.userrelation[0]?.email}
                                          </td>
                                          <td>
                                            {item?.userrelation[0]?.phone}
                                          </td>
                                          <td className="text-center">
                                            <Tooltip title=" Courses Installments">
                                              <button
                                                onClick={() => {
                                                  handleinstallments(
                                                    item?.userrelation[0]?.id,
                                                    batchid
                                                  );
                                                }}
                                                type="button"
                                                className="btn btn-info text-white"
                                                disabled={
                                                  instamentdatavalue == false
                                                }
                                              >
                                                ₹
                                              </button>{" "}
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                              {" "}
                                              <Button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  showConfirm(item?.id)
                                                }
                                              >
                                                <i className="fa-regular fa-trash-can"></i>
                                              </Button>
                                            </Tooltip>
                                            {coursedata == 1 ? (
                                              <Tooltip title="Add Subscrption">
                                                {" "}
                                                <Button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={() =>
                                                    handeladdsubscrptions(
                                                      item?.userrelation[0]?.id,
                                                      batchid,
                                                      coursename?.id
                                                    )
                                                  }
                                                >
                                                  <i className="fa-solid fa-plus"></i>{" "}
                                                  {/* Add Subscrptions */}
                                                </Button>
                                              </Tooltip>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                              )}
                            </table>
                            {visiblebatchData?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {visiblebatchData?.length <= 0 ? (
                              ""
                            ) : (
                              <ul
                                className="pagination lms-page d-flex align-items-center justify-content-center"
                                style={{ marginLeft: "1.5em" }}
                              >
                                <li
                                  className="page-item prev"
                                  onClick={() => prevPageHandler()}
                                >
                                  <Link
                                    className="page-link"
                                    to="#"
                                    tabIndex={-1}
                                  >
                                    <i className="fas fa-angle-left" />
                                  </Link>
                                </li>
                                {renderPagination()}

                                <li
                                  className="page-item next"
                                  onClick={() => nextPageHandler()}
                                >
                                  <Link className="page-link" to="#">
                                    <i className="fas fa-angle-right" />
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </div>
  );
}
