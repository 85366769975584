import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import {   notification, Space  } from "antd";

import "react-toastify/dist/ReactToastify.css";
const ADDTESTSERIES = "/api/test-series/create-testseries-module";

const initalValue = {
  name: "",
  total_question: "",
  total_marks: "",
  negative_question: "",
  duration: "",
  attempts:""
};
const studentSchema = Yup.object({
  name: Yup.string().min(2).max(100).required("Please enter your name"),
  total_question: Yup.string()
    .min(1)
    .max(25)
    .required("Please enter your total question"),
  total_marks: Yup.string()
    .min(1)
    .max(25)
    .required("Please enter your total marks"),
  negative_question: Yup.string()
    .min(1)
    .max(25)
    .required("Please enter your negative marks"),
  duration: Yup.string().min(1).max(25).required("Please enter your duration"),
  attempts: Yup.string().min(1).max(25).required("Please enter your attempts"),

});
export default function AdminAddTestSeries() {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const[submit,setSubmit]=useState(false)
  const [api, contextHolder] = notification.useNotification();
  
    const openNotificationWithIcon1 = (type,text,col) => {
      console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:col}}>
           <strong>{text}</strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };
  const { errors, values, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value) => {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      setSubmit(true)
      try {
        const response = await axios.post(
          ADDTESTSERIES,
          {
            "testseries-name": value.name,
            total_question: value.total_question,
            total_marks: value.total_marks,
            negative_question: value.negative_question,
            duration: value.duration,
            attempts:value?.attempts
          },
          config
        );
        // console.log(response);
        if (response) {
          openNotificationWithIcon1("success",'Test Series Created',"#52c41a")
          setTimeout(() => {
            // navigate("/admin/test-series");
            navigate(-1);
          }, 1000);
          // toast.success("Test Series Created");
        }
      } catch (error) {
        console.log(error);
        if (resonse?.status == 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login")
  
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon1("error", error.response.data.message, "red")
        }
        else {
          openNotificationWithIcon1("error", 'Internal Server Error', "red")
        }
        setSubmit(false)

      }
    },
    validationSchema: studentSchema,
  });
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  const openNotificationWithIcon2 = (type) => {
    console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"Profile"} />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
                style={{minWidth:"100%",marginTop:"-15px"}}

        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Test Series</h3>
                    {/* <p>Add Test Series information.</p> */}
                    <nav aria-label="breadcrumb" style={{cursor:"Pointer"}} >
                      <ol className="breadcrumb" style={{marginBottom:0.5}}>
                        <li className="breadcrumb-item" onClick={()=>navigate('/admin/test-series')} aria-current="page">
                          Test Series
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                        Add Test Series
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head">
                      {/* <h4>Add Test Series</h4>
                      <p>Add Test Series information.</p> */}
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Total Question
                            </label>
                            <input
                              type="number"
                              name="total_question"
                              id="total_question"
                              value={values.total_question}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Total Question"
                            />
                            {errors.total_question && touched.total_question ? (
                              <span style={{ color: "red" }}>
                                {errors.total_question}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Total Marks
                            </label>
                            <input
                              type="number"
                              name="total_marks"
                              id="total_marks"
                              value={values.total_marks}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Total Marks"
                            />
                            {errors.total_marks && touched.total_marks ? (
                              <span style={{ color: "red" }}>
                                {errors.total_marks}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Negative Marks(Every Question)
                            </label>
                            <input
                              type="number"
                              name="negative_question"
                              id="negative_question"
                              step="0.01"
                              value={values.negative_question}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Negative Marks"
                            />
                            {errors.negative_question &&
                            touched.negative_question ? (
                              <span style={{ color: "red" }}>
                                {errors.negative_question}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Duration (In Minutes)
                            </label>
                            <input
                              type="number"
                              name="duration"
                              id="duration"
                              value={values.duration}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Duration"
                            />
                            {errors.duration && touched.duration ? (
                              <span style={{ color: "red" }}>
                                {errors.duration}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Attempts
                            </label>
                            <input
                              type="number"
                              name="attempts"
                              id="attempts"
                              value={values.attempts}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Duration"
                            />
                            {errors.attempts && touched.attempts ? (
                              <span style={{ color: "red" }}>
                                {errors.attempts}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="update-profile"
                              id="submit-button"

                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button type="submit" className="btn btn-primary"
                            disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
