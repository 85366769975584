import React from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../../api/axios";
 
import { useState } from "react";
// import {ZoomMtg} from '@zoomus/websdk'
const host =window.location.origin
const local ='http://127.0.0.1:3000'
const zoomService = 'https://zoomservice.chahalacademy.com'
const JoinMeeting = () => {
  const navigate= useNavigate()
  useEffect(() => {    
   window.addEventListener("popstate", () => {
  window.location.reload()
});
 
 }, []);
 const [sdkDetail, setSDKDetails] = useState();
  const details = JSON.parse(localStorage.getItem("details"));
    const fetchData = async()=>{
      let dt = await  axios.get(`${zoomService}/api/zoom/get/sdk/${details?.meeting_id}`);
      console.log("dsfdsfsdf")
       console.log(dt.data.message)
       setSDKDetails(dt.data.message)
     
    }
    useEffect(async()=>{
      fetchData();
 
    },[])
  const name = localStorage.getItem("nameuser");
 
  const fetchZoom = async () => {
    let payload = {
      meetingNumber: details?.meeting_id,
      role: 0,
      sdkKey: sdkDetail?.fk_zoom_account?.zoom_meeting_sdks[0]?.client_id,
      sdkSecret: sdkDetail?.fk_zoom_account?.zoom_meeting_sdks[0]?.client_secret,
      passWord: details?.password,
      userName: name,
      userEmail: "",
      leaveUrl: `${host}/student/std-live-class`,
    };
    console.log('meee',payload)
    const { ZoomMtg } = await import("@zoomus/websdk");
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.13.0/lib", "/av");
 
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load("en-US");
    ZoomMtg.i18n.reload("en-US");
    ZoomMtg.generateSDKSignature({
      meetingNumber: payload.meetingNumber,
      role: payload.role,
      sdkKey: payload.sdkKey,
      sdkSecret: payload.sdkSecret,
      success: function (signature) {
        ZoomMtg.init({
          debug: true,
          leaveUrl: payload.leaveUrl,
          isSupportAV: true,
          disableInvite: true,
          // isSupportAV: true, meetingInfo: [ 'topic', 'host', ] ,
          success: function (data) {
            ZoomMtg.join({
              meetingNumber: payload.meetingNumber,
              signature: signature.result,
              sdkKey: payload.sdkKey,
              userName: payload.userName,
              userEmail: payload.userEmail,
              passWord: payload.passWord,
              tk: "",
              success: function () {
                // console.log("-----------joined--------");
              },
              error: function (error) {
                console.log(error);
              },
            });
          },
          error: function (error) {
            console.log(error);
          },
        });
      },
      error: function (error) {
        console.log(error);
      },
    });
  };
  useEffect(()=>{
    // if(sdkDetail?.fk_zoom_account?.zoom_meeting_sdks[0]?.client_id){
      fetchZoom();
    // }
  },[setSDKDetails,sdkDetail])
  return <></>;
};
 
export default JoinMeeting;