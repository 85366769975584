import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../footer";
import "./index.css"
import {
  PencilIcon, logo, Video, Video1, VideoBg, Video2, Play, PlayVideo
} from "../../imagepath";
import moment from "moment";
import { notification, Space } from "antd";

import StudentHeader from "../studentHeader";
import axios from "../../../api/axios";
import FooterStudent from "../../fotterstudent";
import axiosInstance from "../../../api/axios1";
import { Tooltip } from "antd";
const VIDEO = "/api/student/videos";
export default function StudentsVideoCourse() {
    const {id}=useParams()
    //  console.log(id,"id")
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState(null);
  const navigate = useNavigate();
  const tokenStudent = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const getVideo = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(`${VIDEO}/${id}`, config);
    //   console.log(responce,"responce")
      
      const course = responce?.data
    // console.log(course,"course")
    
    
    // const transformedResponse = responce?.data?.flatMap(item => {
    //   console.log(item,"item")

    //   const course_relation_liveclass = item?.sub || [];
    
    //   if (course_relation_liveclass.length === 0) {
    //     return null; // Skip the item if course_relation_liveclass is empty
    //   }
    
    //   const courserelationsubmoduleObjects = course_relation_liveclass.flatMap(classItem => {
    //     const courserelationsub_module_id = classItem?.sub_module_id || null; // Get sub_module_id from classItem
    
    //     return classItem.courserelationsubmodule.map(submodule => {
    //       return {
    //         id: submodule.id,
    //         slug: submodule.slug,
    //         name: submodule.name,
    //         module_id: submodule.module_id,
    //         module1: submodule.module1,
    //         courserelationsub_module_id, // Assign the sub_module_id to courserelationsub_module_id
    //       };
    //     });
    //   });
    
    //   if (courserelationsubmoduleObjects.length === 0) {
    //     return null; // Skip the item if courserelationsubmoduleObjects is empty
    //   }
    
    //   return {
    //     name: item.name,
    //     slug: item.slug,
    //     courseid: item?.course_subscription[0]?.course_id,
    //     course_relation_liveclass: courserelationsubmoduleObjects,
    //     courserelationsub_module_id: courserelationsubmoduleObjects[0]?.courserelationsub_module_id || null,
    //     courserelationmodule_id: courserelationsubmoduleObjects[0]?.module_id || null,
    //   };
    // }).filter(Boolean); // Filter out null items
    
    // console.log(transformedResponse);
    
          
    
      // const courseSub=course?.filter(e=>e?.courserelationsubmodule?.length>0)

      // const videomodule = course.filter(e => e?.courserelationsubmodule?.length > 0)
      // const unique = [];
      // courseSub?.map(x =>
      //   unique.filter(a => a?.module_id === x?.module_id && a?.course_id === x?.course_id).length > 0 ? null : unique.push(x)
      // );
      // videomodule?.map(x => unique.filter(a => a?.module_id == x?.module_id && a?.course_id == x?.course_id).length > 0 ? null : unique?.push(x));
      
      // console.log(videomodule,"videomodule")
      // const arrayHashmap = unique.reduce((obj, item) => {
      //   obj[item?.course_id] ? obj[item?.course_id]?.videomodule?.push(...item.videomodule) : (obj[item.course_id] = { ...item });
      //   return obj;
      // }, {});
      // console.log(arrayHashmap,"data")

      // const mergedArray = Object.values(arrayHashmap);
      const filteredCourses = responce?.data?.map(item => {
        const uniqueSub = item?.sub.filter((subItem, index, self) =>
            index === self?.findIndex(t => (
                t?.subModuleSlug === subItem?.subModuleSlug
            ))
        );
        return { ...item, sub: uniqueSub };
    });
    // console.log(filteredCourses,"courses")
    
      setLoading(false);
      setList(filteredCourses);
    }
    catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('tokenStudent')
        navigate('/login')
      }
      else{
        openNotificationWithIcon3("error",error?.response?.data?.message)

      }
      setLoading(false)
    }

  };
  // console.log(list,"list value")
  useEffect(() => {
    getVideo();
  }, []);
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "red" }}>
                <strong>Internal Server Error </strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted

          // </div>)
          duration: 1
        });
  };
  const handleNotification = (courseSlug, slug,) => {
    // let date = new Date();
    // let current = moment(date).format("YYYY-MM-DD HH:mm:ss").valueOf()
    // let expiry_dates = moment(expiry_date).format("YYYY-MM-DD HH:mm:ss").valueOf()
    // // console.log(`${current}     ${expiry_dates}`);
    // if (current > expiry_dates) {
    //   toast.info("Course is Expired ");
    // } else {
      
    // }
    navigate(
      `/student/videos/${courseSlug}/${slug}`
    )

  }
  useEffect(() => {
    const lastVisiteStudentRoute = localStorage.getItem("lastVisiteStudentRoute")
    if (lastVisiteStudentRoute) {
      localStorage.removeItem("lastVisiteStudentRoute")
    }
    else {
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

    }
  }, []);
  useEffect(() => {
    const lastVisiteStudentTest = localStorage.getItem("lastVisiteStudentTest")
    if (lastVisiteStudentTest !== window.location.pathname) {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");

    }
  }, []);
// console.log(list,"list")
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"videos"} />
      {/* BreadCrumb */}
      <div className="breadcrumb-bar">
        <div className="container"
          style={{ minWidth: "98%", margin: "auto" }}

        >
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/student/videos">Videos</Link>
                    </li>
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="">{list?list[0]?.courseName:""}</Link>
                    </li>
                    {/* <li className="breadcrumb-item">Students Videos</li> */}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BreadCrumb */}
      {/* Page Wrapper */}
      <div className="page-content" >
        <div className="container"
          style={{ minWidth: "98%", margin: "auto" }}

        >
          <div className="row">
            <h3 className="mb-3 mb-md-4 mb-lg-5">Videos</h3>
            {/* animate__animated animate__fadeIn live-classes-heading */}
            {loading === true ? (
              <div
                style={{ display: 'block' }}
                id="cover-spin"
              >
              </div>
            ) : (
              list?.length > 0 ? list?.map((item) => {
              //  console.log(item,"index")
                return (
                  <div className="col-lg-12" key={item?.courseSlug}>
                    <h4 >{item?.courseName}</h4>
                    <div className="featured-instructor-two">
                      <div className="row" >
                        {item?.sub?.map((it) => {
                          // console.log(item?.courseSlug
                          //   , it?.subModuleSlug,"it")
                          // console.log("it")
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 p-4 d-flex custom-div" style={{ cursor: "pointer" }} key={it?.subModuleSlug}>
                                <div className="course-full-width" >
                                  <div
                                    className="blur-border course-radius aos"

                                  >
                                    <div
                                      //  style={{ background: "#FFE5B4" }}
                                      onClick={() =>
                                        handleNotification(item?.courseSlug
                                          , it?.subModuleSlug,)
                                      }
                                      className="online-course d-flex align-items-center"
                                      style={{justifyContent:"space-evenly"}}
                                    >
                                      <div className="course-img">
                                        <img style={{ height: '80px', width: '80px' }} src={PlayVideo} alt="" />
                                      </div>
                                      <div className="course-inner-content ">
                                        <Tooltip title={it?.subModuleName}>
                                        <h6 style={{fontWeight:700,display:"flex"}}>
                                          <span className="d-flex">
                                          {`${it?.subModuleName?.slice(
                                                          0,
                                                          20
                                                        )} ${
                                                          it?.subModuleName
                                                            ?.length > 20
                                                            ? "..."
                                                            : ""
                                                        }`}
                                            {/* {it?.subModuleName} */}
                                            </span>
                                        </h6>
                                        </Tooltip>
                                        <Tooltip title={it?.module?.name}>
                                        <p>
                                        {`${it?.module?.name?.slice(
                                                          0,
                                                          20
                                                        )} ${
                                                          it?.module?.name
                                                            ?.length > 20
                                                            ? "..."
                                                            : ""
                                                        }`}
                                          {/* {it?.module?.name} */}
                                          </p>

                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </>

                          );
                        })}

                        {/* <div className="col-lg-3 col-md-6 d-flex p-4   custom-div" style={{ cursor: "pointer" }}>
                          <div className="course-full-width">
                            <div
                              className="blur-border course-radius aos"
                            // data-aos="fade-up"
                            >
                              <div
                                // style={{ background: "#FFE5B4" }}
                                onClick={() => {
                                  let date = new Date();
                                  let current = moment(date).format("YYYY-MM-DD HH:mm:ss").valueOf()
                                  let expiry_dates = moment(item?.course?.expiry_date).format("YYYY-MM-DD HH:mm:ss").valueOf()
                                  if (current > expiry_dates) {
                                    toast.info("Course Is Expired ");
                                  } else {
                                    navigate(
                                      `/student/videos/${item?.slug}/others`
                                    )
                                  }

                                }
                                }
                                className="online-course d-flex align-items-center"
                                style={{justifyContent:"space-evenly"}}
                              >
                                <div className="course-img">
                                  <img style={{ height: '80px', width: '80px' }} src={PlayVideo} alt="" />
                                </div>
                                <div className="course-inner-content">
                                  <h5  style={{fontWeight:700}}>
                                    <span className="d-flex">Others</span>
                                  </h5>
                                  <p>Student</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                );
              })
                : <h2 className="text-center">No Videos</h2>)}
          </div>
        </div>
      </div>

      {/* Page Wrapper */}
      <ToastContainer />
      <FooterStudent />
    </div>
  );
}
