import React, { useContext, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { LoginImg, logo, NetIcon1, NetIcon2, RegisterImg,ChahalLogo } from "../../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../api/axios";
import * as Yup from "yup";
import { notification, Space } from "antd";
import { useFormik } from "formik";
const LOGIN_URL = "/api/admin-login";
const initalValue = {
  email: "",
  password: "",
};
const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string()
    .required("Please enter a password")
    .min(6, "Password must have at least  characters"),
});
const AdminLogin = () => {
  const { setAuth } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [loading,setLoading] =useState(false)
  const [toast, setToast] = useState(false)
  const token = localStorage.getItem("token")
  const[value,setvalue]=useState(false)
  const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")

  useEffect(() => {
   
    if (token) {
      navigate("/admin/dashboard");
    }
    else if (!token) {
      navigate("/admin/login")
    }
  }, [])
  const openNotificationWithIcon1 = (type, text) => {
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>{text}</strong>
              </div>
            ),
          duration: 1
        });
  };
  const openNotificationWithIcon2 = (type,text) => {
    console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>{text}</strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
    const openNotificationWithIcon3 = (type) => {
      console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>{"Internal Server Error"}</strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
  const { values, errors, touched, setFieldError, handleChange, handleSubmit } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (val) => {
        // console.log(val);
        try {
          setLoading(true)
          setvalue(true)
          const response = await axios.post(LOGIN_URL, values, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          });
          // console.log(response?.data);
          localStorage.removeItem('tokenStudent')
          const nameuser = response?.data?.user?.name
          localStorage.setItem("nameuser", nameuser);
          const token = response?.data?.token;
          localStorage.setItem("token", token);
          setAuth({ email, password, token });
          openNotificationWithIcon1("success", 'Login Successful')

          if(lastVisitedRoute==="/admin/login" ){
            // console.log(lastVisitedRoute,)
            navigate("/admin/dashboard")
          }
          else if(lastVisitedRoute){
            navigate(lastVisitedRoute)
          }
          else if (!lastVisitedRoute) {
            setTimeout(() => {
              navigate("/admin/dashboard");
            }, 1000);


          }
          setLoading(false)
          setvalue(false)

        } catch (err) {
          console.log("err", err);
          setvalue(false)
          // if (err.response?.data?.message) {
          //   setFieldError("email", err.response?.data?.message);
          // }
          if(err?.response?.status==401){
          openNotificationWithIcon2("error",err.response?.data?.message)
          }
          else{
            openNotificationWithIcon3("error")

          }
        }
      },
      validationSchema: loginSchema,
    });

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme"
            >
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={RegisterImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Chahal Academy.
                  </h2>
                  {/* <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p> */}
                </div>
              </div>
              {/* <div className="welcome-login">
                <div className="login-banner">
                  <img src={RegisterImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Chahal Academy.
                  </h2>
                  <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={RegisterImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Chahal Academy.
                  </h2>
                  <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo">
                    <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                    <div className="back-home">
                      <Link to="/">Back to Home</Link>
                    </div>
                  </div>
                  <h1>Sign into Admin Account</h1>
                  <form noValidate onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group">
                      <label className="form-control-label">Email</label>
                      <input
                        type="email novalidate"
                        name="email"
                        id="email"
                        autoComplete="off"
                        value={values.email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your email address"
                        required
                      />
                      {errors.email && touched.email ? (
                        <span style={{ color: "red" }}>{errors.email}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Password</label>
                      <div className="pass-group">
                        <div className="password-field-wrapper">
                          <input
                            type={passwordType}
                            onChange={handleChange}
                            value={values.password}
                            required
                            autoComplete="off"
                            name="password"
                            id="password"
                            className="form-control password-field"
                            placeholder="Password"
                          />
                          <span
                            className="toggle-password feather-eye"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <FeatherIcon icon="eye" />
                            ) : (
                              <FeatherIcon icon="eye-off" />
                            )}
                          </span>
                        </div>
                        {errors.password && touched.password ? (
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        ) : null}

                      </div>
                    </div>
                    {error ? <div style={{ color: "red" }}>{error}</div> : null}
                    <div className="forgot">
                      <span>
                        <Link className="forgot-link" to="/forgot-password">
                          Forgot Password ?
                        </Link>
                      </span>
                    </div>
                    <div className="remember-me">
                      <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                        {" "}
                        Remember me
                        <input type="checkbox" name="radio" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="d-grid">
                      <button className="btn btn-start" type="submit"
                      disabled={value}
                      >
                       Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="google-bg text-center">
                <span>
                  <Link to="#">Or sign in with</Link>
                </span>
                <div className="sign-google">
                  <ul>
                    <li>
                      <Link to="#">
                        <img src={NetIcon1} className="img-fluid" alt="Logo" />{" "}
                        Sign In using Google
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={NetIcon2} className="img-fluid" alt="Logo" />
                        Sign In using Facebook
                      </Link>
                    </li>
                  </ul>
                </div>
                <p className="mb-0">
                  New User ? <Link to="/register">Create an Account</Link>
                </p>
              </div> */}
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default AdminLogin;
