/**
 * Video playback not working on codesandbox.io, CORS security issue?
 * But will work in your development
 */

import { useEffect, useRef, useState } from "react";
// import videojs from "video.js";
// import "videojs-youtube";
// import vimeo from "videojs-vimeo";

// import "video.js/dist/video-js.css";
import axios from "../../../../api/axios";
import ReactPlayer from "react-player";
import WaterMark from "./WaterMark";
import WaterMarkFull from "./WaterMarkFull";


export default function VideoJs({ type, src ,viewvid,moduleid}) {
  // console.log(moduleid,"moduleid")
  
  const token = localStorage.getItem("tokenStudent");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenChange = () => {
    const isFullScreenNow =
      !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      );
  
    setIsFullScreen(isFullScreenNow);
  
    if (isFullScreenNow) {
      // Apply styles for full screen
      document.getElementById("react-player").style.width = "100%"; // Example adjustment
      document.getElementById("watermark-full").style.display = "block"; // Show watermark in full screen
    } else {
      // Apply styles for non-full screen
      document.getElementById("react-player").style.width = "100%"; // Example adjustment
      document.getElementById("watermark-full").style.display = "none"; // Hide watermark in regular mode
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener(
      "webkitfullscreenchange",
      handleFullScreenChange
    );
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener("MSFullscreenChange", handleFullScreenChange);
    };
  }, []);

  // console.log(isFullScreen,"screen")

  const endvideo = async () => {
    // console.log("video ende")
    try {
      const response = await axios.post("/api/student/create/video_watched", {
        "fk_sub_module_id": moduleid,
        "fk_video_id": viewvid
      }, config);
      // setPrimaryTimer(+(response?.data?.watchTime));
      // console.log(response,"response")
    } catch (error) {
      console.log(error)
        if (error?.response?.status == 403) {
            openNotificationWithIcon4("error", error?.response?.data?.message);
            navigate("/student/videos");
          }
          if (error.response.status == 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem('tokenStudent');
            navigate('/login');
          }
          else{
            openNotificationWithIcon4("error",error?.response?.data?.message)
          }
    }
  }
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon4 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  return (
    
    <div style={{position:"relative"}} >

    <ReactPlayer
     id="react-player"
      url={src}
      // url={"https:\/\/www.youtube.com\/watch?v=zshznThlRck&list=PLQ266RXG7V9zDP8TNHclZsvUxhstU62-i&index=2"}

      width="100%"
      height="600px"
      className='react-player'
      controls={true}
      onPlay={endvideo}
    />
    </div>
  );
}
{/* <div class="video">
  <div class="watermark">
    <img draggable="false" src="https://www.jing.fm/clipimg/full/82-824068_aperture-science-logo-png.png">
  </div>
  <video id="video" controlsList="nodownload nocaptions" controls="" preload="metadata">
        <source src="https://thepaciellogroup.github.io/AT-browser-tests/video/ElephantsDream.mp4" type="video/mp4">
    </video>
</div> */}