import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { Share, User11 } from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentSideBar from "../sidebar";
import CryptoJS from "crypto-js";
import Select from "react-select";
import * as Yup from "yup";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import TextEditor from "../../pages/course/addCourse/textEditor";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { Toast, Col, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
const COURSEEDIT = "/api/course/edit";
const COURSEUPDATE = "/api/course/update";
const COURSECATEGORY="/api/course-category/show-active"
const COURSETIME="/api/course-type/show-active"

import { notification, Space } from "antd";

export default function EditAdminCourse() {
  const [showToast, setShowToast] = useState(false);
  const [option, setOption] = useState(null);
  const [relation, setRelation] = useState(null);
  const [errorI, setErrorI] = useState("");
  const [file, setFile] = useState(null);
  const [radio, setRadio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { couid ,id } = useParams();
  // console.log(couid,id)
  const [submit, setSubmit] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCoursetime, setSelectedCoursetime] = useState([]);
  const [selectedCoursevalue, setSelectedCoursevalue] = useState([]);
  const [selectedCoursetimevalue, setSelectedCoursetimevalue] = useState([]);
  const [selectedCourseloginType, setSelectedCourseLoginType] = useState("");



  var bytes = CryptoJS.AES.decrypt(couid, "my-secret-key@admin8623009959");
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // console.log(decryptedData,"decrypted")
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchCourseCategory = async () => {
    try {
        const res = await axios.get(`${COURSECATEGORY}/${id}`, config)
// console.log(res,"category")
        setSelectedCourse(res?.data?.message)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }

    }
}
const fetchCourseTime = async () => {
  try {
      const res = await axios.get(`${COURSETIME}/${id}`, config)
// console.log(res,"time")
setSelectedCoursetime(res?.data?.message)
  } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
          localStorage.removeItem('token')
          navigate('/admin/login')
      }

  }
}
useEffect(() => {
  fetchCourseCategory();
  fetchCourseTime();
}, []);
  const fecthCourse = async () => {
    try {
      setLoading(true);
      const responce = await axios.get(
        `${COURSEEDIT}/${decryptedData}`,
        config
      );
      // console.log(responce?.data);
      setOption(responce?.data?.batches);
      setData(responce?.data?.course);
      setSelectedCoursevalue(responce?.data?.category)
      setSelectedCoursetimevalue(responce?.data?.courseType)

      const result = [];
      responce?.data?.batches.map((batch) => {
        responce?.data?.CourseBatchRelation?.map((course) => {
          if (batch?.id == course.fk_batch_id) {
            result?.push(batch);
          }
        });
      });
      // console.log(result,"result")
      setRelation(result);
      setRadio(responce?.data?.course?.amount > 0 ? "option1" : "option2");
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error","Internal Server Error")
      }
    }
  };


  useEffect(() => {
    fecthCourse();
  }, []);
  // console.log(relation,"relation")
  const initalValue = {
    name: data?.name?data?.name:"",
    duration: data?.duration?data?.duration:"",
    status: data?.status,
    amount: data?.amount?data?.amount:"",
    description: data?.description?data?.description:"",
    image: data?.image,
    batch: relation?.map(({ name, id
    }) => ({ label: name, value: id    })),
    category_id: selectedCoursevalue?selectedCoursevalue?.id:"",
    course_type_id:selectedCoursetimevalue?selectedCoursetimevalue?.id:"",
    universal_course_type:data?.universal_course_type?data?.universal_course_type:""

    
  };
  // console.log(initalValue,"krishna",)
  const courseSchema = Yup.object({
    name: Yup.string().min(2).required("Please enter your name"),
    duration: Yup.string().required("Please enter your duration"),
    status: Yup.string().required("Please select your status"),
    amount: Yup.string().required("Enter your date of amount"),
    description: Yup.string().required("Please enter your description"),
    course_type_id:Yup.string().required("Please Selecte your course Time"),
    category_id:Yup.string().required("Please Selecte your course Time"),
     universal_course_type:Yup.string().required("Please Selecte your Login CourseType"),
    // batch: Yup.array()
    // .min(1, "Pick at least one items")
    // .of(
    //   Yup.object().shape({
    //     label: Yup.string().required(),
    //     value: Yup.string().required(),
    //   })
    // ),
  });
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Course Updated</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon2 = (type,text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      
      duration: 6,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmit(true);
      console.log("krishna")

      try {
        // console.log(value);
        setErrorI("");
        const formData = new FormData();
        const batch = values.batch.map((e) => e.value);
        if (file !== null) {
          const image = new Image();
          image.src = URL.createObjectURL(file);
          await new Promise(resolve => { image.onload = resolve });
          if (image.width > 630 || image.height > 420) {
            openNotificationWithIcon2("error", "Image dimensions should be 630px width and 420px height.", );
            // setSubmit(false);
            return;
          }
          formData.append("image", file);
        }
        formData.append("name", values?.name);
        formData.append("duration", values?.duration);
        formData.append("description", values?.description);
        formData.append("status", values?.status);
        formData.append("amount", values?.amount);
        for (let i = 0; i < batch.length; i++) {
          formData.append("batch[]", batch[i]);
        }
        formData.append("category_id",values?.category_id)
        formData.append("course_type_id",values?.course_type_id)
        formData.append("universal_course_type",values?.universal_course_type)

        const response = await axios.post(
          `${COURSEUPDATE}/${decryptedData}`,
          formData,
          config
        );
        // toast.info("Course Updated", {
        //   position: "top-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: 0,
        //   theme: "colored",
        //   transition:"flip"
        //   });

        if (response) {
          openNotificationWithIcon("success");
          setTimeout(() => {
            navigate(-1);
          }, 600);
        }
      } catch (error) {
        console.log(error?.response?.data?.message);

        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
       else if (error?.response?.status === 422) {
          // navigate(-1)
          openNotificationWithIcon2("error",error?.response?.data?.message);

        }
     
        else{
          openNotificationWithIcon2("error",error?.response?.data?.message);

        }
          setSubmit(false);

       
      }
    },
    validationSchema: courseSchema,
  });
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      // height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Course</h3>{" "}
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate("/admin/course")}>
                            Courses
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Edit Course
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* <div className="course-group mb-0 d-flex">
                    <div className="course-group-img d-flex align-items-center">
                      <Link to="student-profile">
                        <img src={User11} alt="" className="img-fluid" />
                      </Link>
                      <div className="course-name">
                        <h4>
                          <Link to="student-profile">Your avatar</Link>
                        </h4>
                        <p>PNG or JPG no bigger than 800px wide and tall.</p>
                      </div>
                    </div>
                    <div className="profile-share d-flex align-items-center justify-content-center">
                      <Link to="#;" className="btn btn-success">
                        Update
                      </Link>
                      <Link to="#;" className="btn btn-danger">
                        Delete
                      </Link>
                    </div>
                  </div> */}
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Course Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                              />
                              {errors.name && touched.name ? (
                                <span style={{ color: "red" }}>
                                  {errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Course Duration (Days)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Duration"
                                id="duration"
                                name="duration"
                                value={values.duration}
                                onChange={handleChange}
                              />
                              {errors.duration && touched.duration ? (
                                <span style={{ color: "red" }}>
                                  {errors.duration}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label>Payment Type :</label>
                            <br />
                            <div
                              className="form-check form-check-inline"
                              style={{
                                marginBottom: "15px",
                                marginTop: "10px",
                              }}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                                onChange={(e) => {
                                  handleRadio(e.target.value);
                                  // setRadio(e.target.value);
                                }}
                                checked={radio === "option1"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                Paid Course
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                                onChange={(e) => {
                                  handleRadio(e.target.value);
                                  // setRadio(e.target.value);
                                }}
                                checked={radio === "option2"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Free Course
                              </label>
                            </div>
                          </div>
                          {/* {radio === "option1" && ( */}
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Amount (₹)
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Your Amount"
                                id="amount"
                                name="amount"
                                value={values?.amount}
                                onChange={handleChange}
                                disabled={radio !== "option1"}
                              />
                            </div>
                            {errors.amount && touched.amount ? (
                              <span style={{ color: "red" }}>
                                {errors.amount}
                              </span>
                            ) : null}
                          </div>
                          {/* )} */}

                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Cover Image Size(630px*420px)
                              </label>
                              <input
                                type="file"
                                id="image"
                                name="image"
                                // value={values.image}
                                className="form-control"
                                placeholder="Cover Image"
                                onChange={(e) => {
                                  setFile(e.target.files[0]); // Update the state with the new file
                                  setFieldValue("image", URL.createObjectURL(e.target.files[0])); // Update the form field value with the new image URL
                                }}                              />
                              {/* {values.image && (
                                <img
                                  src={`https://lmscode.stellarflux.in/${values.image}`}
                                  alt="no image"
                                  height={50}
                                  width={90}
                                />
                              )} */}
                              {errorI ? (
                                <span style={{ color: "red" }}>{errorI}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <div
                                className="add-image-box"
                                style={{ minHeight: "0", height: "200px" }}
                              >
                                {values.image === null ? (
                                  <img
                                    src={Share}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                ) : (
                                  <img
                                    src={`${values.image}`}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-0">
                            <label className="form-control-label">
                              Course Description
                            </label>
                            <div id="editor">
                              <TextEditor
                                type="description"
                                id="description"
                                name="description"
                                value={values.description}
                                setFieldValue={setFieldValue}
                              />
                              {/* <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                name="description"
                                // onBlur={handleBlur}
                                value={values.description}
                                onChange={handleChange}
                                rows="3"
                              ></textarea> */}
                              {errors.description && touched.description ? (
                                <span style={{ color: "red" }}>
                                  {errors.description}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div
                            className="col-lg-12"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="form-group">
                              <label className="form-label">Select Batch</label>

                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                menuPlacement="bottom"
                                name="batch"
                                isMulti
                                options={option?.map((ele) => {
                                  return {
                                    label: ele.name,
                                    value: ele.id,
                                  };
                                })}
                                value={values.batch}
                                // defaultValue={options[0]}
                                placeholder="Select Batch"
                                onChange={(assignedUser) =>
                                  setFieldValue("batch", assignedUser)
                                }
                                // onChange={setCountry}
                                styles={style}
                              ></Select>
                              {errors.batch && touched.batch ? (
                                <span style={{ color: "red" }}>
                                  {errors.batch}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Course Category</label>
                            <select
                              className="form-control"
                              id="category_id"
                              name="category_id"
                              value={values?.category_id}
                              onChange={(e) => setFieldValue("category_id", e.target.value)}
                            >
                              <option value="">Select a Category</option>
                              {selectedCourse?.map((course) => (
                                <option key={course?.id} value={course?.id}>
                                  {course?.name}
                                </option>
                              ))}
                            </select>
                            {/* <Select
      className="form-control"
      id="category_id"
      name="category_id"
      value={values.category_id}
      onChange={(value) => setFieldValue("category_id", value)}
    >
      <Select.Option value="">Select a Category</Select.Option>
      {selectedCourse?.map((course) => (
        <Select.Option key={course?.id} value={course?.id}>
          {course?.name}
        </Select.Option>
      ))}
    </Select>
                                <Select
                              className=" select country-select"
                              menuPlacement="bottom"
                              name="batch"
                              isMulti
                              options={selectedCourse?.map((ele) => {
                                return {
                                  label: ele.name,
                                  value: ele.id,
                                };
                              })}
                              placeholder="Select Batch"
                              onChange={(assignedUser) =>
                                setFieldValue("category_id", assignedUser)
                              }
                              // onChange={setCountry}
                              styles={style}
                            ></Select> */}
                            {!selectedCourse && (
                              <div className="text-danger">
                                Please select a course Category.
                              </div>
                            )}
                          </div>

                          
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Course Type
                            </label>
                            
                              <select
                              className="form-control"
                              id="course_type_id"
                              name="course_type_id"
                              value={values.course_type_id}
                              onChange={(e) => setFieldValue("course_type_id", e.target.value)}
                            >
                              <option value="">Select Course Type</option>
                              {selectedCoursetime?.map((course) => (
                                <option key={course?.id} value={course?.id}>
                                  {course?.name}
                                </option>
                              ))}
                            </select>
                            {touched?.course_type_id && errors?.course_type_id && (
            <div className="text-danger">{errors.course_type_id}</div>
          )}
                          </div>


                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Login CourseType
                            </label>
                            
                              <select
                              className="form-control"
                              id="universal_course_type"
                              name="universal_course_type"
                              value={values?.universal_course_type}
                              onChange={(e) => setFieldValue("universal_course_type", e.target.value)}

                            >
                              <option value="">Select Login CourseType</option>
                              <option key={"0"} value={0}>
                                  {"With Login"}
                                </option>
                                <option key={"1"} value={1}>
                                  {"Without Login"}
                                </option>
                            </select>
                            {touched?.universal_course_type && errors?.universal_course_type && (
            <div className="text-danger">{errors.universal_course_type}</div>
          )}
                          </div>


                        </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value={0 || values?.status}
                                  checked={values?.status == 0}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  checked={values?.status == 1}
                                  onChange={getFieldProps("status").onChange}
                                  value={1 || values?.status}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="update-profile" id="submit-button">
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={submit}
                                onClick={(e) => {
                                  setShowToast(true);
                                  e.preventDefault();
                                  handleSubmit();
                                }}
                              >
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>

      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
