import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import CryptoJS from "crypto-js";
import Footer from "../../footer";
import { useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../instructor/adminHeader";
import {   notification, Space  } from "antd";
import "./index.css"
const ADDTESTSERIES = "https://zoomservice.chahalacademy.com/api/sub-module/create";
// const CLASSMODULE = "/api/live-classes/create";

// const ADDCLASS = "/api/live-classes/store";
const initalValue = {
  name: "",
  status:""
};
const studentSchema = Yup.object({
  name: Yup.string().min(2).max(100).required("Please enter your name"),
});
export default function AdminClassLiveBatchAdd() {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  // const { featureTypeId } = useParams();
  const[submit,setSubmit]=useState(false)

  // let bytes = CryptoJS.AES.decrypt(
  //   featureTypeId,
  //   "my-secret-key@admin8623009959"
  // );
  // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const [api, contextHolder] = notification.useNotification();
  
  const openNotificationWithIcon = (type,text,col) => {
    
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:col}}>
         <strong>{text}</strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Created
  
      // </div>)
      duration:1
      
    });
  
    };
    const openNotificationWithIconSesion = (type) => {
      notification[type]({
        message: (
          <div style={{ color: "red" }}>
            <strong>Session Expired Please Login </strong>
          </div>
        ),
        duration: 1,
      });
    };
  const { errors, values, touched, handleChange, handleSubmit,getFieldProps } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value) => {
      const token = localStorage.getItem("token");
      const config = {
        headers: { api_key:"lmsv2" }
      };
      setSubmit(true)
      try {
        const response = await axios.post(
          ADDTESTSERIES,
          { name: value.name, status:value?.status },
          config
        );
        // console.log(response);
        if (response) {
          openNotificationWithIcon("success",'SubModule Created',"#52c41a")
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setSubmit(false)
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red")
        }
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else {
          openNotificationWithIcon("error", 'Internal Server Error', "red")
        }
      
      }
      // console.log(value);
    },
    validationSchema: studentSchema,
  });
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  const openNotificationWithIcon2 = (type) => {
  
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add SubModule</h3>
                    <nav aria-label="breadcrumb" style={{ marginTop: "0.5em" }}>
                      <ol className="breadcrumb" style={{marginBottom:0.5}}>
                        <li
                          className="breadcrumb-item"
                          style={{ cursor: "pointer" }}
                        >
                          <a onClick={() => navigate("/admin/live-classes")}>
                            Live Classes
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item"
                          aria-current="page"
                          onClick={()=>navigate('/admin/live-class-batches')}
                          style={{ cursor: "pointer",fontWeight:'normal' }}
                        >
                          Live Class SubModule
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          style={{ cursor: "pointer",marginLeft:'-0.7em' }}
                        >
                         Add SubModule
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter SubModule Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your SubModule Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  onChange={getFieldProps("status").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                        

                        <div className="update-profile"
                          id="submit-button"

                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button type="submit" className="btn btn-primary"
                            disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer autoClose={3000} hideProgressBar={true} />
      <Footer />
    </div>
  );
}
