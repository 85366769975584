import React, { useContext, useEffect, useRef, useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";
import "./index.css";
import {
  InstructorProfileBg,
  ReferImage1,
  ReferImage2,
  ReferImage3,
  ReferImage4,
  ReferImage5,
  User15,
} from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import { Input, Tooltip } from "antd";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Switch from "react-switch";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import AuthContext from "../../../context/AuthProvider";
import Pagination from "../../pages/course/addCourse/Pagination";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
const STUDENTLIST = "/api/student/view";
const DELETESTUDENT = "/api/student/delete";
const STUDENTSTATUS = "/api/student/status";
const TESTEXCEL = "/api/student/export";
const IMPORT = "/api/student/import";
const STUDENTSERCH = "/api/student/allstudent-search";
const RESETPASSWORD = "/api/admin/user/resetPassword";
const EXCEll = "/api/student/export-user";
const FREESTUDENTS = "/api/admin/freeStudent";
const STUDNETCOURSES = "/api/admin/stu_course_list";
const DELETESTUDENTCOURSE="/api/admin/course_list/delete"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notification, Modal } from "antd";
const CENTERSLIST = "/api/public/academy_centers";

const { confirm } = Modal;

// toast.configure()

export default function AdminInstructorStudentList() {
  const { Search } = Input;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [last_page, setLast_page] = useState("");
  const [message, setmessage] = useState("No Data");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [excel, setExcel] = useState(null);
  const [id, setId] = useState("");
  const [file, setFile] = useState(null);
  const [showImport, setShowImport] = useState(false);
  const [showImport1, setShowImport1] = useState(false);
  const [courseslist, setCoursesList] = useState([]);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [word, setWord] = useState(false);
  const [total, setTotal] = useState("");
  const [freeUser, setFreeUser] = useState([]);
  const [text, settext] = useState("");
  const [error, seterror] = useState("");
  const [userButton, setFreeUserButton] = useState(false);
  const inputFile = useRef(null);
  const [freecount, setFreeCount] = useState("");
  const [centers, setCenters] = useState([]);
  const [centersId, setCentersId] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  // console.log(token)
  const { auth } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    const CentersList = async () => {
      try {
        const response = await axios.get(CENTERSLIST);
        setCenters(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    };

    CentersList();
  }, []);

  const fecthSudentList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(STUDENTLIST, config);
      // console.log(resonse, "test");
      setLast_page(resonse.data.last_page);
      setList(resonse?.data?.data);
      setData(resonse.data.data);
      setLoading(false);
      setTotal(resonse?.data?.total);
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIconImport("error", "Internal Server Error", "red");

      }
    }
  };
  const freeStudnetsList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(FREESTUDENTS, config);
      // console.log(resonse, "test");
      setFreeCount(resonse?.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const StudnetsCoursesList = async (id) => {
    try {
      setLoading(true);
      const resonse = await axios.get(`${STUDNETCOURSES}/${id}`, config);
      //  console.log(resonse, "list response");
      setCoursesList(resonse?.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  useEffect(() => {
    fecthSudentList();
    freeStudnetsList();
  }, []);
  //  console.log(courseslist,"freecount")
  const handleShowImport = () => {
    setShowImport(true);
    setIsModalVisible(true);
  };
  const handleShowImport1 = () => {
    setShowImport1(true);
    setIsModalVisible1(true);
  };
  const handleCloseImoprt = () => setShowImport(false);
  const handleCloseImoprt1 = () => setShowImport1(false);

  const openNotificationWithIconImport = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleDelteImoprt = async () => {
    seterror("");
    const formData = new FormData();
    formData.append("user_import", file);
    try {
      const response = await axios.post(IMPORT, formData, config);

      fecthSudentList();
      openNotificationWithIconImport("success", "Student Created", "#52c41a");
      if (response) {
        setShowImport(false);
        setIsModalVisible(false);
        if (inputFile.current) {
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      if (error?.response?.status === 422) {
        openNotificationWithIconImport(
          "error",
          error.response.data.message,
          "red"
        );
      } else {
        openNotificationWithIconImport("error",error?.response?.data?.message, "red");
      }
      seterror(error.response.data.message);
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
    
    }
  };

  const config1 = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "blob" },
    responseType: "arraybuffer",
  };

  useEffect(() => {
    // if(word==true){
    // fetchExcel();
    // }
    // setWord(false)
  }, []);

  const handleClose = () => setShow(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Student Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconDelete = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Course Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const handleKeysPass = async (id) => {
    try {
      const responce = await axios.post(RESETPASSWORD, { user_id: id }, config);
      if (responce.status === 200) {
        openNotificationWithIconImport(
          "success",
          responce.data.message,
          "#52c41a"
        );
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleDeleteStudent = async () => {
    if (id) {
      setLoading(true);
      const resonse = await axios.get(`${DELETESTUDENT}/${id}`, config);
      setLoading(false);
      openNotificationWithIcon("info");
      fecthSudentList();
      setShow(false);
      // toast.warning("Student Deleted");
      return resonse;
      // console.log("resonse", resonse);
    }
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this student?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETESTUDENT}/${id}`, config);
          setLoading(false);
          openNotificationWithIcon("success");
          fecthSudentList();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  function showConfirmDelete(id) {
    confirm({
      title: "Do you want to delete this Course?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.delete(`${DELETESTUDENTCOURSE}/${id}`, config);
          setLoading(false);
          openNotificationWithIconDelete("success");
          setShow(false);
          setIsModalVisible1(false);
          fecthSudentList();

        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      STUDENTSTATUS,
      { id: id, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    fecthSudentList();
    // toast.success("Status Change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
    // console.log(list);
  };

  const handleEdit = (id) => {
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/edituser/${ciphertexts}`);
  };
  const handleCourse = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/buy-course/${ciphertexts}`);
  };
  const handleinstallments = (id) => {
    // var ciphertext = CryptoJS.AES.encrypt(
    //   JSON.stringify(id),
    //   "my-secret-key@admin8623009959"
    // ).toString();
    // let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/course-instalments/${id}`);
  };
  const exportCsv = async (excel) => {
    try {
      const response = await axios.get(TESTEXCEL, config1);
      setExcel(response.data);
      const fileExtension = ".xlsx";
      const data = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      FileSaver.saveAs(data, "Student" + fileExtension);
    } catch (error) {
      console.log(error);
      setWord(false);
    }
  };
  const exportCsv1 = async (excel) => {
    try {
      const response = await axios.get(EXCEll, config1);
      setExcel(response.data);
      const fileExtension = ".xlsx";
      const data = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      FileSaver.saveAs(data, "Student" + fileExtension);
    } catch (error) {
      console.log(error);
      setWord(false);
    }
  };
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(STUDENTSERCH, { name: searchValue }, config);
    if (res.data.data.length > 0) {
      setList(res.data.data);
      setLast_page(res.data.last_page);
      setmessage("No Data");
    } else {
      // setList(data)
    }
    if (res.data.data.length == 0) {
      setList(res.data.data);
      setmessage("No Data Found");
    }
    if (searchValue.length === 0) {
      const res = await axios.get(`${STUDENTLIST}?page=${currentPage}`, config);
      setList(data);
      setCurrentPage(1);
      setLast_page(res.data.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  const itemsPerPage = 10;
  const freeUsersData = list?.filter((item) => item?.usersubs?.length == 0);

  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"StudentList"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3 style={{ textTransform: "capitalize" }}>
                            All Students
                          </h3>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                          // className="settings-referral-blk table-responsive"
                          // id="table-value"
                          >
                            {/* Instructor Users */}

                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row  gx-2 align-items-center">
                                    <div className="col-md-5 col-item">
                                      <div
                                        className="button-container-admin justify-content-start"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className=" search-group custom-button1 ">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            style={{
                                              position: "absolute",
                                              left: "7px",
                                              color: "rgb(246, 106, 98)", // Change the color to yellow
                                            }}
                                          >
                                            <circle
                                              cx="11"
                                              cy="11"
                                              r="8"
                                            ></circle>
                                            <line
                                              x1="21"
                                              y1="21"
                                              x2="16.65"
                                              y2="16.65"
                                            ></line>
                                          </svg>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Name"
                                            // allowClear
                                            onChange={Filter}
                                            onKeyPress={handleKeyPress}
                                            // style={{ width: "100%" }}
                                          />
                                        </div>
                                        <button
                                          className="custom-button-total"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          Total{":"}
                                          {userButton
                                            ? freeUser?.length
                                            : total}
                                        </button>
                                        <button
                                          className="custom-button-free"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          Free :{freecount}
                                        </button>
                                      </div>
                                    </div>

                                    <div className="col-md-7 col-lg-7 col-item">
                                      <div
                                        className="button-container-admin"
                                        style={{
                                          justifyContent: "flex-end",
                                          gap: "5px",
                                        }}
                                      >
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            exportCsv1(excel);
                                            // setWord(true),
                                            // e.preventDefault();
                                          }}
                                          // style={{marginRight:'.5em'}}
                                          className="custom-button-excel"
                                        >
                                          Download Excel
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            exportCsv(excel),
                                              setWord(true),
                                              e.preventDefault();
                                          }}
                                          className="custom-button-total"
                                        >
                                          Export Sample
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            handleShowImport();
                                            e.preventDefault();
                                          }}
                                          className="custom-button-free"
                                          style={{}}
                                        >
                                          Import Excel
                                        </button>
                                        <Button
                                          onClick={() =>
                                            navigate("/admin/adduser")
                                          }
                                          className="custom-button"
                                          style={{}}
                                        >
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                             
                              {/* Import Student  */}
                              <Modal
                                title="Import Student"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisible}
                                okText="Import"
                                onOk={() => {
                                  handleDelteImoprt();
                                }}
                                onCancel={() => {
                                  setIsModalVisible(false);
                                  seterror("");
                                }}
                              >
                                <div className="mb-3">
                                  <label htmlFor="formFile" className="form-label">
                                    only support excel file
                                  </label>
                                  <input
                                    className="form-control"
                                    autoFocus
                                    onChange={(e) => setFile(e.target.files[0])}
                                    ref={inputFile}
                                    type="file"
                                    id="formFile"
                                  />
                                  {error && (
                                    <span style={{ color: "red" }}>
                                      {error}
                                    </span>
                                  )}
                                </div>
                              </Modal>
                              {/* course list view modal */}
                              <Modal
                                title="View Courses List"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisible1}
                                okText="ok"
                                onOk={() => {
                                  setIsModalVisible1(false);
                                }}
                                onCancel={() => {
                                  setIsModalVisible1(false);
                                }}
                              >
                                <Form>
                                  <Form.Group className="mb-3">
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr className="text-center">
                                          <th>Course Name</th>
                                          <th>Course Type</th>
                                          <th>Expiry Date</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <>
                                     
                                        {courseslist?.length>0
                                          ? courseslist?.map((item) => {
                                             return(
                                              <tbody className="text" key={item?.id}>
                                                <tr key={item?.id}>
                                                  <td className="text-center">
                                                    {item?.course[0]?item?.course[0]?.name:""}
                                                  </td>
                                                  <td>
                                                    <>
                                                    {item?.type=="paid"?
                                                    <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                     <a
                                                        className="badge badge-success text-white"
                                                        style={{
                                                          margin: "auto",
                                                          padding: "5px",
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        Paid
                                                      </a>
                                                  </div>:
                                                   <div
                                                   style={{
                                                     display: "flex",
                                                   }}
                                                 >
                                                   {item?.type== "free" ? (
                                                     <a
                                                       className="badge badge-success text-white"
                                                       style={{
                                                         margin: "auto",
                                                         padding: "5px",
                                                         marginTop: "5px",
                                                       }}
                                                     >
                                                       free
                                                     </a>
                                                   ) : (
""
                                                   )}
                                                 </div>
                                                  }
                                                 
                                                      </>
                                                  </td>
                                                  <td className="text-center">
                                                    {item?.expiry_date}
                                                  </td>
                                                  <td>
                                                    <div className="d-flex">
                                                      <Tooltip title="Delete">
                                                        <Button
                                                          type="button"
                                                          className="btn btn-danger"
                                                          onClick={() =>
                                                            showConfirmDelete(item?.id)
                                                          }
                                                          style={{
                                                            margin: "auto",
                                                          }}
                                                        >
                                                          <i className="fa-regular fa-trash-can"></i>
                                                        </Button>
                                                      </Tooltip>
                                                    </div>
                                                  </td>
                                                </tr>
                                                </tbody>
                                             )
                                              
                                            })
                                          : 
                                          <tbody>
                                            <tr style={{display:"flex",margin:"auto",padding:"10px",border:"none"}}>
                                              <td style={{border:"none"}} >
                                              <h5 style={{textAlign:"center"}}>No Data Found</h5>
                                              </td>
                                            </tr>
                                          </tbody>

                                          }
                                          
                                      </>
                                    </table>
                                  </Form.Group>
                                </Form>
                              </Modal>
                              {/* <Modal show={showImport} onHide={handleCloseImoprt}>
                              <Modal.Header closeButton>
                                <Modal.Title> Import Student </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Upload File</Form.Label>
                                    <Form.Control
                                      type="file"
                                      autoFocus
                                      onChange={(e) =>
                                        setFile(e.target.files[0])
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                  >
                                    <Form.Label>Example textarea</Form.Label>
                                  <Form.Control as="textarea" rows={3} />
                                  </Form.Group>
                                </Form>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseImoprt}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleDelteImoprt}
                                >
                                  Import
                                </Button>
                              </Modal.Footer>
                            </Modal> */}
                              <table className="table table-nowrap mt-2">
                                <thead>
                                  <tr>
                                    <th className="text-center">SR. NO.</th>
                                    <th className="text-center">NAME</th>
                                    <th className="text-center">PHONE</th>
                                    <th className="text-center">TYPE</th>
                                    <th className="text-center">CENTER NAME</th>

                                    <th
                                      style={{ width: "100px" }}
                                      className="text-center"
                                    >
                                      <p
                                        className="text-center"
                                        style={{ marginBottom: "-0px" }}
                                      >
                                        STATUS
                                      </p>
                                    </th>

                                    <th
                                      style={{ width: "250px" }}
                                      className="text-center"
                                    >
                                      <p
                                        className="text-center"
                                        style={{ marginBottom: "-0px" }}
                                      >
                                        ACTIONS
                                      </p>
                                    </th>
                                  </tr>
                                </thead>
                                {loading === true ? (
                                 
                                    <tbody   className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}>
                                      <tr>
                                        <td>
                                          <div
                                            className="spinner-border text-warning"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                
                                ) : (
                                  list?.map((item, i) => {
                                    // console.log(item?.id)
                                    const sNo =
                                      (currentPage - 1) * itemsPerPage + i + 1;
                                    const hasPaidSubscription =
                                      item?.usersubs?.some(
                                        (sub) => sub.type === "paid"
                                      ) ?? false;
                                    const result = hasPaidSubscription
                                      ? "paid"
                                      : "free";
                                    // console.log(result,"haspaid")
                                    const centerObj = centers.find((center) => center?.id == item?.fk_academy_center_id);  
                                  //  console.log(centerObj,"obj")
                                    return (
                                      <tbody
                                        key={item?.id}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <tr className="text-center">
                                          <td className="text-center">{sNo}</td>
                                          <td className="text-center">
                                            {/* <Link
                                              // to="student-profile"
                                              className="refer-avatar-blk  align-items-center"
                                            >
                                              <p>{item?.name}</p>
                                            </Link> */}
                                            {item?.name}
                                          </td>
                                          <td className="text-center">
                                            {/* {item.gender === "0"
                                              ? "Male"
                                              : item.gender === "1"
                                                ? "Female"
                                                : "Other"} */}
                                            {item?.phone}
                                          </td>

                                          <td className="text-center">
                                            {item?.usersubs?.length > 0 ? (
                                              <>
                                                {result == "paid" ? (
                                                  <a className="badge badge-success text-white">
                                                    Paid User
                                                  </a>
                                                ) : (
                                                  <a className="badge badge-warning text-white">
                                                    Free User
                                                  </a>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>{item?.center?.name}</td>

                                          <td className="text-center">
                                            <div
                                              className="form-check form-switch"
                                              style={{ marginRight: "3em" }}
                                            >
                                              <Switch
                                                onChange={(e) => {
                                                  handleChange(
                                                    item.id,
                                                    item.status
                                                  );
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={20}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={15}
                                                width={38}
                                                className="react-switch"
                                                id="flexSwitchCheckChecked"
                                                checked={
                                                  item.status == 1
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </td>
                                          {/* <td><Button variant='info' className="text-white"
                                           onClick={()=>{handleCourse(item.id)}}
                                           >Subscription </Button> </td> */}
                                          <td className="text-center">
                                            <Tooltip title="Edit">
                                              <button
                                                onClick={() =>
                                                  handleEdit(item.id)
                                                }
                                                type="button"
                                                className="btn btn-secondary"
                                              >
                                                <i className="fa-regular fa-pen-to-square"></i>
                                              </button>{" "}
                                            </Tooltip>
                                            <Tooltip title="Reset Password">
                                              <button
                                                onClick={() =>
                                                  handleKeysPass(item.id)
                                                }
                                                type="button"
                                                className="btn btn-info text-white"
                                              >
                                                <i
                                                  className="fa fa-key"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>{" "}
                                            </Tooltip>
                                            <Tooltip title="SubScription List">
                                              <button
                                                onClick={(e) => {
                                                  handleShowImport1();
                                                  e.preventDefault();
                                                  StudnetsCoursesList(item?.id);
                                                }}
                                                type="button"
                                                className="btn btn-success text-white"
                                              >
                                                ₹
                                              </button>{" "}
                                            </Tooltip>
                                            {/* <Tooltip title=" Courses Installments">
                                              <button
                                                onClick={() => { handleinstallments(item.id) }}

                                                type="button"
                                                className="btn btn-info text-white"
                                              >
                                                Installments
                                              </button>{" "}
                                            </Tooltip> */}
                                            <Tooltip title="Delete">
                                              <Button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  showConfirm(item.id)
                                                }
                                              >
                                                <i className="fa-regular fa-trash-can"></i>
                                              </Button>
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                                )}
                              </table>
                            </div>

                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                text={text}
                                setLast_page={setLast_page}
                                setCurrentPage={setCurrentPage}
                                link={STUDENTLIST}
                                setData={setList}
                                linkSearch={STUDENTSERCH}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
