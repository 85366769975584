import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../footer";
import { Tooltip } from "antd";
import AdminInstructorSidebar from "../adminSidebar";
import AdminHeader from "../adminHeader";
import AuthContext from "../../../context/AuthProvider";
import CryptoJS from "crypto-js";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import axios from "../../../api/axios";
const SHOWTSET = "/api/recorded-video/show-submodule";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./insex.css";
import PaginationSub from "../../pages/course/addCourse/PaginationSub";
const QUESSTIONSTATUS = "/api/recorded-video/status-submodule";
const DELETEQUESTION = "/api/test-series/delete-question";
const DELETESUB = "/api/recorded-video/delete-submodule";
const SUBMODULESEARCH = "/api/recorded-video/recordedvideo-submodule-search";
import { Modal } from "antd";
import { notification, Space } from "antd";

const { confirm } = Modal;

export const AddAdminRecordedVideos = () => {
  const { slug, id, feature_type_id } = useParams();
  const [moduleName, setModuleName] = useState("");

  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const [show, setShow] = useState(false);
  const [list, setList] = useState(null);
  const [data, setData] = useState(null);
  const [showTest, setShowTest] = useState(false);
  const [message, setmessage] = useState("No Data");
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, setLast_page] = useState("");
  const [ids, setIds] = useState("");
  const [loading, setLoading] = useState(false);
  const [text,settext]=useState('')

  const navigate = useNavigate();
  const handleEdit = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/recordedvideos/subedit/${ciphertexts}`);
  };

  const [checked, setChecked] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseTest = () => setShowTest(false);
  const handleChange = async (ids, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      `${QUESSTIONSTATUS}`,
      { id: ids, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    // toast.success("status change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === ids);
    res.status = Number(!bool) + "";
    // console.log(editCheck);
    fetchShow();
    setList(editCheck);
  };
  const handleShow = async (id) => {
    setShow(true);
    setIds(id);
  };
  const handleShowTest = async (id) => {
    setShowTest(true);
    // setId(id);
  };
  const fetchShow = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${SHOWTSET}/${slug}`, config);

      setModuleName(response.data.module.name);
      setList(response.data.subModule.data);
      setData(response.data.subModule.data);
      setLast_page(response.data.subModule.last_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {

    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>SubModule Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type) => {

    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type) => {

    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const DeleteTestSeries = async () => {
    if (ids) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETESUB}/${ids}`, config);
        resonse;
        toast.warning("SubModule Deleted");
        setLoading(false);
        fetchShow();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this submodule?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETESUB}/${id}`, config);
          resonse;
          if (resonse) {
            openNotificationWithIcon("success");
          }
          // toast.warning("SubModule Deleted");
          setLoading(false);
          fetchShow();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  useEffect(() => {
    fetchShow();
  }, []);

  var bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      settext(searchValue)
      const res = await axios.post(
        SUBMODULESEARCH,
        { name: searchValue, module: decryptedData },
        config
      );

      if (res.data.subModule.data.length > 0) {
        setList(res.data.subModule.data);
        setCurrentPage(res.data.subModule.current_page)
        setLast_page(res.data.subModule.last_page)
        setmessage('No Data')
      } else {
        // setList(data)
      }
      if (res.data.subModule.data.length === 0){
        setList(res.data.subModule.data);
        setmessage('No Data Found')
      }
      if (searchValue.length === 0) {
        const res = await axios.get(
          `${SHOWTSET}/${slug}?page=${currentPage}`,
          config
        );
        setList(res.data.subModule.data);
        setCurrentPage(1);
        setLast_page(res.data.subModule.last_page)
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const itemsPerPage = 10;

  const handleLinkVideo = (ids) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(ids),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(
      `/admin/recorded-videos/video-link/${encodeURIComponent(
        id
      )}/${ciphertexts}`
    );
  };
  const handleView=(id)=>{
    navigate(`/admin/recorded-videos/videos-list/${id}`)
  }

  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={""} />
      <div className="page-content instructor-page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-20px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget ttt">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3 style={{ textTransform: "capitalize" }}>
                          {" "}
                           {moduleName}
                        </h3>
                        <nav
                          aria-label="breadcrumb"
                          style={{ marginTop: "0.5em", marginBottom: "-1.6em" }}
                        >
                          <ol className="breadcrumb">
                            <li
                              className="breadcrumb-item "
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/admin/recorded-videos-list")
                              }
                            >
                              {" "}
                              Recorded Videos
                            </li>
                            <li
                              className="breadcrumb-item "
                              aria-current="page"
                              style={{
                                cursor: "pointer",
                                fontWeight: "normal",
                              }}
                              onClick={() => navigate("/admin/recorded-videos")}
                            >
                              {" "}
                              Recorded Modules
                            </li>
                            <li
                              className="breadcrumb-item active "
                              aria-current="page"
                              style={{
                                marginLeft: "-1em",
                                cursor: "pointer",
                              }}
                            >
                              {moduleName}
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="show-filter choose-search-blk">
                          <form action="#">
                            <div
                              className="row gx-2 align-items-center"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="col-md-3 col-item">
                                <div className=" search-group" id="search-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                      position: "absolute",
                                      left: "7px",
                                      color: "rgb(246, 106, 98)", // Change the color to yellow
                                    }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Name"
                                    // allowClear
                                    onChange={Filter}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-item">
                                <div className="form-group select-form mb-0 d-flex justify-content-end">
                                  <Button
                                    onClick={() =>
                                      navigate(
                                        `/admin/recordedvideos/add/${encodeURIComponent(
                                          id
                                        )}/${encodeURIComponent(
                                          feature_type_id
                                        )}`
                                      )
                                    }
                                    id="custom-button"
                                    className="btn btn-primary"
                                  >
                                    {" "}
                                    <i className="fa-solid fa-plus"></i> Create
                                    New
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          
                          <table className="table table-nowrap mb-0 mt-4">
                            <thead>
                              <tr className="text-center">
                                <th>SR. NO.</th>
                                <th>Name</th>
                                {/* <th>Under</th> */}
                                <th>Status</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {loading === true ? (
                             
                                <tbody className="d-flex justify-content-center align-items-center"
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  left: 0,
                                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                                }}>
                                  <tr>
                                    <td>
                                      <div
                                        className="spinner-border text-warning"
                                        role="status"
                                      >
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                            ) : (
                              list?.map((item, i) => {
                                const serialNumber =
                                  (currentPage - 1) * itemsPerPage + i + 1;
                                return (
                                  <tbody
                                    key={i}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <tr className="text-center">
                                      <td>{serialNumber}</td>
                                      <td>{item.name}</td>
                                      {/* <td>
                                      <i style={{color:'gray'}} className="fa fa-info-circle" aria-hidden="true"></i>
                                      </td> */}
                                      <td>
                                        {" "}
                                        <Switch
                                          onChange={() => {
                                            handleChange(item.id, item.status);
                                          }}
                                          checked={
                                            item.status ==1 ? true : false
                                          }
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={15}
                                          width={38}
                                          className="react-switch"
                                        />
                                      </td>
                                      <td>
                                        <Tooltip title="Link Video">
                                          <button
                                            onClick={() =>
                                              handleLinkVideo(item?.id)
                                            }
                                            type="button"
                                            className="btn btn-info text-white"
                                          >
                                            <i
                                              className="fa fa-link"
                                              aria-hidden="true"
                                            ></i>
                                          </button>{" "}
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                          <button
                                            onClick={() => handleEdit(item.id)}
                                            type="button"
                                            className="btn btn-secondary"
                                          >
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </button>{" "}
                                        </Tooltip>
                                        <Tooltip title="View Videos">
                                              <a
                                                onClick={() =>
                                                  handleView(item?.id)
                                                }
                                                className="btn btn-info text-white"
                                              >
                                                <i className="fa-regular fa-eye"></i>
                                              </a>
                                            </Tooltip>{" "}
                                        <Tooltip title="Delete">
                                          <Button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => showConfirm(item.id)}
                                          >
                                            <i className="fa-regular fa-trash-can"></i>
                                          </Button>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            )}
                          </table>
                          {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                             {list===null ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                          {/* Referred Users */}
                        </div>
                        <div className="fixtable">
                        {list?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationSub
                              nPages={last_page}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              link={SHOWTSET}
                              slug={slug}
                              text={text}
                              module= {decryptedData}
                              linkSearch={SUBMODULESEARCH}
                              setData={setList}
                            />
                            )}
                       
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
            <ToastContainer />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
