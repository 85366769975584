import React, { useContext, useEffect, useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";
// import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip, Input } from "antd";
import { notification, Space } from "antd";
import { Spin } from "antd";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
const COURSELIST = "/api/course-type/view";
const DELETECOURSE = "/api/course-type/delete";
const COURSESTATUS = "/api/course-type/update/status";
const COURSESEARCH = "/api/course-type/search";
const COURSECATEGORYDELETE="api/course-type/delete/all"

import { Modal } from "antd";
import PaginationCourseType from "./Pagination";

const { confirm } = Modal;
// const windowTop1 = (arg1, arg2) => {
//   window.scrollTo(arg1, arg2);
// };
export default function CourseType() {
  const { id } = useParams();
  const { Search } = Input;
  const [show, setShow] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [text, settext] = useState("");
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState([]);
  const [moduleid,setId]=useState(id)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const[emaildelete,setEmailDelete]=useState("");
  const[passworddelete,setPasswordDelete]=useState("");
  const [deleteid,setDeleteId]=useState("")


  //   const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  const [message, setmessage] = useState("No Data");
  const { auth } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.put(
      `${COURSESTATUS}/${id}`,
      {  status: result },
      config
    );
    response;
    // toast.success("Status Change");
    // console.log(response);
    if (response) {
      openNotificationWithIcon1("success");
      fecthCoureList()
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
    // console.log(list);
  };
  const openNotificationWithIcon2 = (text,type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const fecthCoureList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${COURSELIST}/${id}`, config);
      // console.log(response?.data?.data, "data");
      setList(response.data?.data);
      setName(response?.data?.message);
        setLast_page(response?.data?.last_page);
      //   setData(resonse.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{      
      openNotificationWithIcon2("error");
      }
    }
  };
  useEffect(() => {
    fecthCoureList();
  }, []);
  // console.log(list);
  const handlePayment = (payid) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(payid),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/show-course-payment/${ciphertexts}`);
  };
  const handleClose = () => setShow(false);
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>CourseType Delted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const handleDeleteCourse = async () => {
    if (id) {
      try {
        setLoading(true);
        const resonse = await axios.delete(`${DELETECOURSE}/${id}`, config);
        openNotificationWithIcon("info");

        setLoading(false);
        fecthCoureList();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };
  function showConfirm(id) {
    setDeleteId(id)

    confirm({
      title: "Do you want to delete this course SubCategory?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.delete(`${DELETECOURSE}/${id}`, config);
          // console.log("resonse", resonse);
          if(resonse?.status==205){
            handleShowForm()
          }
          else{
            openNotificationWithIcon("success");

            setLoading(false);
            fecthCoureList();
            setShow(false);
          }
     
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          if (error?.response?.status === 422) {
            openNotificationWithIcon11("error",error?.response?.data?.message,"red");

          }
          else{
            openNotificationWithIcon11("error",error?.response?.data?.message,"red");

          }

        }
      },
      onCancel() {},
    });
  }
  const openNotificationWithIcon11 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const handleModalDeleteClose=()=>{
    setIsModalVisibleForm(false)

   }
   const handeleDeleteCategory=async()=>{
    try {
      // setLoading(true);
      const resonse = await axios.post(`${COURSECATEGORYDELETE}/${deleteid}`,
      {email:emaildelete,password:passworddelete}, config)
    openNotificationWithIcon11("error"," SubCategory Deleted","red");
     handleModalDeleteClose();
     fecthCoureList();
  
  
      setLoading(false);
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon11("error",error?.response?.data?.message,"red");
      }
  
    }
   }
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);
  const handleEdit = (id,) => {
    // console.log(id,"id in module",id)
    // var ciphertext = CryptoJS.AES.encrypt(
    //   JSON.stringify(id),
    //   "my-secret-key@admin8623009959"
    // ).toString();
    // let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/edit/course-type/${id}`);
  };
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(COURSESEARCH, { name: searchValue ,"fk_courseMaster_id":id}, config);
    if (res.data.data.length > 0) {
      setList(res.data.data);
      setCurrentPage(res.data.current_page);
      setLast_page(res.data.last_page);
      setmessage("No Data");
    }
    if (res.data.data.length === 0) {
      setList(res?.data?.data);
      setmessage("No Data Found");
    }
    if (searchValue.length === 0) {
      const res = await axios.get(`${COURSELIST}/${id}?page=${currentPage}`, config);
      setList(res?.data?.data);
      setCurrentPage(1);
      setLast_page(res.data.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  // console.log(list, "list");
  // useEffect(() => {
  //   windowTop1(0, 0);
  // }, []);
  const itemsPerPage = 10;
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={`CoursesModule${id}`} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div
                          className="sell-course-head comman-space d-flex"
                          // style={{ justifyContent: "space-between" }}
                        >
                          <div>
                          <h3>Course SubCategory</h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{ marginBottom: "-1.6em" }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate(`/admin/course-module/${id}`)
                                }
                              >
                                {list?list[0]?.course_master?.name:""}

                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                // onClick={() =>
                                //   navigate("/admin/recorded-videos")
                                // }
                              >
                                {" "}
                                Course Category
                              </li>
                            </ol>
                          </nav>
                          </div>
                       
                        </div>

                        <div className="comman-space pb-0">
                          <div
                          // className="instruct-search-blk"
                          // id="table-value"
                          >
                            {/* Instructor Users */}

                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Course SubCategory Name"
                                          // allowClear
                                         onChange={Filter}
                                          onKeyDown={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="form-group select-form mb-0 d-flex justify-content-end"
                                        // style={{  marginBottom:"-5px"}}
                                      >
                                        <Button
                                          onClick={() =>
                                            navigate(`/admin/add/course-type/${id}`)
                                          }
                                          style={
                                            {
                                              // marginLeft: "5.2em",
                                              // width:"100%"
                                            }
                                          }
                                          className="btn btn-primary"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                              
                              <Modal
            title="Delete Course SubCategory"
            okButtonProps={{
              style: { backgroundColor: "#FF875A" },
              children: "Import",
            }}
            open={isModalVisibleForm}
            okText="Delete"
            onOk={() => {
              handeleDeleteCategory();
            }}
            // onCancel={() => {
            //   setIsModalVisibleForm1(false);
            // }}
            onCancel={handleModalDeleteClose}
          >
           <Form>
           <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
      <Form.Label>Email</Form.Label>
      <Form.Control
        type="email"
        placeholder="Enter email"
        value={emaildelete} // Set the value if you want to control it
        onChange={(e) => setEmailDelete(e.target.value)} // Handle the change event
      />
    </Form.Group>

    {/* Password Input */}
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
      <Form.Label>Password</Form.Label>
      <Form.Control
        type="password"
        placeholder="Enter password"
        value={passworddelete} // Set the value if you want to control it
        onChange={(e) => setPasswordDelete(e.target.value)} // Handle the change event
      />
    </Form.Group>

</Form>

          </Modal>
                              <table className="table table-nowrap">
                                <thead>
                                  <tr className="text-center">
                                    <th>Sr. No.</th>
                                    <th>Course SubCategory</th>
                                    {/* <th>DURATION</th>
                                    <th>AMOUNT</th> */}
                                     <th>Status</th>
                                  <th>Actions</th> 
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                {loading === true ? (
                                 
                                    <tbody   className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}>
                                      <tr>
                                        <td>
                                          <div
                                            className="spinner-border text-warning"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                ) : (
                                  list?.map((item, i) => {
                                          const sNo =
                                        (currentPage - 1) * itemsPerPage +
                                        i +
                                        1;
                                  

                                    return (
                                        <tbody
                                          key={item.id}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <tr className="text-center">
                                            <td className="text-center">
                                              {sNo}
                                            </td>
                                            <td className="text-center">
                                              <Tooltip title={item?.name}>
                                                {`${item?.name?.slice(
                                                  0,
                                                  20
                                                )} ${
                                                  item?.name?.length > 20
                                                    ? "..."
                                                    : ""
                                                }`}
                                              </Tooltip>
                                            </td>
                                            {/* <td className="text-center">
                                              <Tooltip title={item?.name}>
                                                {`${item?.name?.slice(0, 25)} ${
                                                  item?.name?.length > 25
                                                    ? "..."
                                                    : ""
                                                }`}
                                              </Tooltip>
                                            </td>
                                            <td>{item?.course_type?.name}</td>

                                            <td>{item.duration} Days</td> */}
                                            {/* <td>₹ {Math.round(item.amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td> */}
                                            
                                            <td>
                                          <Switch
                                            onChange={(e) => {
                                              handleChange(
                                                item.id,
                                                item.status
                                              );
                                            }}
                                            checked={
                                              item.status == 1 ? true : false
                                            }
                                            // id={item.id}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>
                                           
                                            <td className="text-center">

                                          <Tooltip title="Edit">
                                            {" "}
                                            <button
                                              onClick={() =>
                                                handleEdit(item?.id,
                                                  // moduleid
                                                  )
                                              }
                                              type="button"
                                              className="btn btn-secondary"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                          </Tooltip>
                                          <Tooltip title="Delete">
                                            <Button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() =>
                                                showConfirm(item?.id)
                                              }
                                            >
                                              <i className="fa-regular fa-trash-can"></i>
                                            </Button>
                                          </Tooltip>
                                        </td>
                                          </tr>
                                        </tbody>
                                      );
                                  })
                                )}
                              </table>
                            </div>

                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {list === null ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list ?.length <= 0  ? (
                              ""
                            ) : (
                              <PaginationCourseType
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={COURSELIST}
                                id={id}
                                setData={setList}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={COURSESEARCH}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}
