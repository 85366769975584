import React, { useContext, useEffect, useRef, useState } from "react";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";

import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import { Input, Tabs, Tooltip } from "antd";
import Button from "react-bootstrap/Button";

import axios from "../../../api/axios";
import CryptoJS from "crypto-js";

const IMAGESLIST = "/api/view-slider/image";
const DELETEIMAGE = "api/delete-slider/image";
const ADDCATEGORY = "/api/slider/image/create";
const STATUSCHANGE = "/api/status/slider/image";
const COURSESEARCH = "/api/course-category/category-search";
const COURSECATEGORYDELETE = "api/course-category/destroy/all";
const APPHOMESTATUS = "api/admin/popular/course_master/visible_app";
const UPDATEIMAGEINDEX = "/api/set/order/slider/image";
const IMAGEINDEX = "/api/student/slider/image";
const VIDEOLINKUPLOAD="/api/slider/video/create"
import "react-toastify/dist/ReactToastify.css";
import { notification, Modal } from "antd";
import Form from "react-bootstrap/Form";
import "../addAdminRecordedVideos/insex.css";
import moment from "moment";
const { confirm } = Modal;
import Switch from "react-switch";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
import { PlayVideo } from "../../imagepath";
import TabPane from "antd/es/tabs/TabPane";

export default function AdminImages() {
  const { Search } = Input;
  const [install, setinstall] = useState(null);
  // const [status, setSatus] = useState("");
  const [category, setCategory] = useState("");
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [text, settext] = useState("");
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [image, setImage] = useState([]);

  // const [itemsPerPage, setitemsPerPage] = useState('')
  const [userid, setuserid] = useState("");
  const [batchid, setbatchid] = useState("");
  const [courseid, setcourseid] = useState("");
  const [paydata, setpaydata] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [emaildelete, setEmailDelete] = useState("");
  const [passworddelete, setPasswordDelete] = useState("");
  const [deleteid, setDeleteId] = useState("");
  const [message, setmessage] = useState("No Data");
  const [total_amount, settotal_amount] = useState("");
  const [remaing, setRemaing] = useState("");
  const [sum, setsum] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(new Date());
  const [uploadimage, setUploadImage] = useState(true);
  const [uploadvideo, setUploadvideo] = useState(false);
  const [videolink, setVideoLink] = useState(null);
  const[status,setStatus]=useState("")

  const [imagevalue, setimagevalue] = useState(false);
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fetchImages = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(IMAGESLIST, config);
      //  console.log(resonse,"resonse")

      // setitemsPerPage(batch_detail_relation.length)
      setList(resonse?.data);
      setData(resonse?.data);
      setLast_page(resonse?.data?.last_page);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error");
        localStorage.removeItem("token");
        navigate("/admin/login");
      } else {
        openNotificationWithIcon2("error", "Internal Server Error", "red");
      }
    }
  };
  const YouTubeGetID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  useEffect(() => {
    fetchImages();
  }, []);
  const handleEdit = (id) => {
    navigate(`/admin/category-edit/${id}`);
  };
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      STATUSCHANGE,
      { id: id, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success", "Status Updated", "#52c41a");
      fetchImages();
    }
    if (!response) {
      openNotificationWithIcon2("error", "Internal Server", "red");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };
  const handleChangeHomeApp = async (id, visible_app) => {
    let bool = eval(visible_app);
    let result = Number(!bool) + "";
    const response = await axios.put(
      `${APPHOMESTATUS}/${id}`,
      { status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1(
        "success",
        " App HomeScreen Status Updated",
        "#52c41a"
      );
    }
    if (!response) {
      openNotificationWithIcon2("error", "Internal Server", "red");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.visible_app = Number(!bool) + "";
    setList(editCheck);
  };

  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const handleShowForm1 = () => {
    setIsModalVisibleForm1(true);
  };
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon1 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblerecordsData = list?.slice(indexOfFirstCourse, indexOfLastCourse);
  const recordsDataLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math.ceil(recordsDataLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);
  const statusRef = useRef(null);
  const imageRef = useRef(null);
  const VideoRef = useRef(null);

  const handleAddUser = async () => {
    let thumnail=null
    let type;
    let vimeo_Reg = /(videos|video|channels|\.com)\/([\d]+)/;

    try {
      if(uploadimage==true){

      const formData = new FormData();
      formData.append("status", status);
      formData.append("image", file);
      const response = await axios.post(ADDCATEGORY, formData, config);
      if (response) {
        setIsModalVisibleForm(false);
        openNotificationWithIcon1("success", "Image Created", "#52c41a");
        setStatus("");
        setCategory("");
        setFile("");
        setFilePre("");
        setimagevalue(true);
        if (imageRef.current) imageRef.current.value = "";
        fetchImages();
      }
    }
    else{
      
      if (videolink.includes('youtube.com') || videolink.includes('youtu.be')) {
        type = 'youtube';
      } else if (videolink.includes('vimeo.com')) {
        type = 'vimeo';
      } else {
        console.error('Unsupported video link');
        openNotificationWithIcon1("error", "Unsupported video link", "red");
        return; // Exit the function if the link is not supported
      }
      if(type=='youtube'){
        const ytcode = YouTubeGetID(videolink);
        thumnail=`https://img.youtube.com/vi/${ytcode}/mqdefault.jpg`
        //  files = await getFileFromUrl(`https://img.youtube.com/vi/${ytcode}/mqdefault.jpg`, 'youtube.jpg');
      }
      else if(type=='vimeo'){
        thumnail=`https://vumbnail.com/${videolink.match(vimeo_Reg)[2]}.jpg`
        // files = await getFileFromUrl(`https://vumbnail.com/${values.video_link.match(vimeo_Reg)[2]}.jpg`, 'vimeo.jpg');
      }

      const response = await axios.post(VIDEOLINKUPLOAD, {"status":status,"link":videolink,"thumnail":thumnail}, config);
      if (response) {
        setIsModalVisibleForm(false);
        openNotificationWithIcon1("success", "Video Created", "#52c41a");
        // setSatus("");
        // setCategory("");
        // setFile("");
        // setFilePre("");
        // setimagevalue(true);
        setVideoLink("")
        if (VideoRef.current) VideoRef.current.value = "";

        // if (imageRef.current) imageRef.current.value = "";
        fetchImages();
      }
    }
    } catch (error) {
      console.log(error);
      if (error?.response?.status == 403) {
        openNotificationWithIcon1("error", "403", "red");
      } else {
        openNotificationWithIcon1("error", error.response.data.message, "red");
      }
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error");
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  // console.log(status,"status",
  // file,"file")

  const handleCreate = () => {
    handleShowForm();
  };
  const handleCreate1 = () => {
    handleShowForm1();
  };

  const handleForceDelete = () => {
    handleShowForm1();
  };
  const handleModalClose = () => {
    setIsModalVisibleForm(false);
    setUploadImage(false)
    setUploadvideo(false)
    setCategory("");
    setStatus(""); // Resetting status
    setFile(null); // Resetting file
    if (imageRef.current) imageRef.current.value = "";
    if (VideoRef.current) VideoRef.current.value = "";
    setVideoLink("")

    setFilePre(""); // Resetting filePre
  };
  const handleModalClose1 = () => {
    setIsModalVisibleForm1(false);
  };
  const handleModalDeleteClose = () => {
    setIsModalVisibleForm1(false);
  };
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(COURSESEARCH, { name: searchValue }, config);
    if (res.data.data.length > 0) {
      setList(res.data.data);
      setCurrentPage(res.data.current_page);
      setLast_page(res.data.last_page);
      setmessage("No Data");
    }
    if (res.data.data.length === 0) {
      setList(res?.data?.data);
      setmessage("No Data Found");
    }
    if (searchValue.length === 0) {
      const res = await axios.get(`${IMAGESLIST}?page=${currentPage}`, config);
      setList(data);
      setCurrentPage(1);
      setLast_page(res.data.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  let itemsPerPage = 10;

  const handleClose = () => setShow(false);
  

  function showConfirm(id) {
    setDeleteId(id);
    confirm({
      title: "Do you want to delete this Image?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETEIMAGE}/${id}`, config);
          //  console.log("resonse", resonse?.status);
          if (resonse?.status == 205) {
            handleShowForm1();
          } else {
            openNotificationWithIcon1("success", "Image Deleted", "#52c41a");

            setLoading(false);
            fetchImages();
          }

          setShow(false);
        } catch (error) {
          // console.log(error?.response?.data?.message);
          if (error?.response?.status == 401) {
            openNotificationWithIconSesion("error");
            localStorage.removeItem("token");
            navigate("/admin/login");
          } else if (error.response.status == 422) {
            openNotificationWithIcon2(
              "error",
              error?.response?.data?.message,
              "red"
            );
            fetchImages();
          } else {
            openNotificationWithIcon2(
              "error",
              error?.response?.data?.message,
              "red"
            );
          }
        }
      },
      onCancel() {},
    });
  }
  const handeleDeleteCategory = async () => {
    try {
      // console.log("krishna",deleteid)
      // setLoading(true);
      const resonse = await axios.post(
        `${COURSECATEGORYDELETE}/${deleteid}`,
        { email: emaildelete, password: passworddelete },
        config
      );
      //  console.log(resonse,"resonse delete")
      openNotificationWithIcon1("error", "Category Deleted", "red");
      handleModalDeleteClose();
      //  fetchImages();

      // setitemsPerPage(batch_detail_relation.length)
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      } else {
        openNotificationWithIcon2("error", error?.response?.data, "red");
      }
    }
  };
  const ImageIndexList = async () => {
    try {
      const response = await axios.get(IMAGEINDEX, config);
      //  console.log(response.data,"response")
      setImage(response?.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error");
        localStorage.removeItem("token");
        navigate("/admin/login");
      } else {
        openNotificationWithIcon2("error");
      }
    }
  };
  const UpdateCourseIndex = async () => {
    const datavalue = image?.map((item, index) => ({
      id: item?.id,
      random_id: index,
    }));
    // console.log(datavalue)
    // const value=[]
    // value.push(datavalue)
    try {
      const response = await axios.post(
        UPDATEIMAGEINDEX,
        { data: datavalue },
        config
      );
      if (response) {
        handleModalClose1();
        openNotificationWithIcon1("success", "Image Index Updated", "#52c41a");
      }
      // console.log(response?.data?.message, "data");
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error");
        localStorage.removeItem("token");
        navigate("/admin/login");
      } else {
        openNotificationWithIcon2("error");
      }
    }
  };
  const handleDragStart = (id) => (e) => {
    e.dataTransfer.setData("text/plain", id);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (id) => (e) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData("text/plain");
    const updatedCourses = [...image];
    const draggedItem = updatedCourses.find((item) => item.id == draggedId);

    const targetIndex = updatedCourses.findIndex((item) => item.id == id);

    updatedCourses[updatedCourses.findIndex((item) => item.id == draggedId)] =
      updatedCourses[targetIndex];
    updatedCourses[targetIndex] = draggedItem;

    setImage(updatedCourses);
  };
  useEffect(() => {
    ImageIndexList();
  }, []);    // in the dependency we are calling list state 
  const [videoid,setvideoid]=useState([])
  const [isModalVisibleForm2, setIsModalVisibleForm2] = useState(false);
  const iframeRef = useRef(null);


  const handelvimeovideo=(link)=>{
    setvideoid(link)
    handleShowForm2();
  }
  const handleCloseVideo = () => {
    if (iframeRef.current) {
      // Reset the src attribute to stop the video
      iframeRef.current.src = iframeRef.current.src;
    }
    setIsModalVisibleForm2(false);
  };
  const handleCloseVideo1 = () => {
    if (iframeRef.current) {
      iframeRef.current.src = ''; // Stop the video by clearing the src
    }
    setIsModalVisibleForm2(false);
  };

  const handleShowForm2 = () => {
    setIsModalVisibleForm2(true);
  };

   console.log(uploadimage,"list",uploadvideo)
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"images"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget ttt">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div>
                            <h3 style={{ textTransform: "capitalize" }}>
                              Professional Images
                            </h3>
                            <nav
                              aria-label="breadcrumb"
                              style={{ marginBottom: "-1.6em" }}
                            >
                              {/* <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/course")
                                }
                              >
                                Courses
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                // onClick={() =>
                                //   navigate("/admin/recorded-videos")
                                // }
                              >
                                {" "}
                                Course Category
                              </li>
                            </ol> */}
                            </nav>
                          </div>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                          // className="settings-referral-blk table-responsive"
                          // id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    {/* <div className="col-md-6 col-lg-6">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          // allowClear
                                          onChange={Filter}
                                          onKeyPress={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div> */}
                                    <div className="col-md-12 col-lg-12 col-item">
                                      <div
                                        className="button-conatiner  justify-content-end"
                                        // style={{  marginBottom:"-5px"}}
                                      >
                                        {/* <Button
                                          onClick={() =>
                                            navigate("/admin/course/index")
                                          }
                                          
                                          className="btn btn-danger"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Popular Courses22
                                        </Button> */}
                                        <Button
                                          onClick={() => handleCreate1()}
                                          className="btn btn-danger"
                                          id="custom-button"
                                        >
                                          Image Index
                                        </Button>
                                        <Button
                                          onClick={() => handleCreate()}
                                          style={
                                            {
                                              // marginLeft: "5.2em",
                                              // width:"100%"
                                            }
                                          }
                                          className="btn btn-primary"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                            <Modal
  title="Add"
  okButtonProps={{
    style: { backgroundColor: "#FF875A" },
    children: "Create",
  }}
  open={isModalVisibleForm}
  okText="Create"
  onOk={handleAddUser}
  onCancel={handleModalClose}
>
  <Tabs
    defaultActiveKey="1"
    onChange={(key) => {
      if (key === "1") {
        setUploadImage(true);
        setUploadvideo(false)
      } 
      if(key==="2"){
        setUploadvideo(true)
        setUploadImage(false)
      }
     
    }}
  >
    <TabPane tab="Add Image" key="1">
      <Form>
        <div className="form-group">
          <label>Status :</label>
          <br />
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="inlineRadios1"
              onClick={() => setStatus("0")}
              value="0"
              checked={status === "0"}
            />
            <label className="form-check-label" htmlFor="inlineRadios1">
              Inactive
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="inlineRadios2"
              onClick={() => setStatus("1")}
              value="1"
              checked={status === "1"}
            />
            <label className="form-check-label" htmlFor="inlineRadios2">
              Active
            </label>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label className="form-control-label">Image</label>
            <input
              type="file"
              name="image"
              id="image"
              ref={imageRef}
              onChange={(e) => {
                setFile(e.target.files[0]);
                setFilePre(URL.createObjectURL(e.target.files[0]));
              }}
              className="form-control"
              placeholder="Cover Image"
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <div className="add-image-box" style={{ minHeight: "0", height: "200px" }}>
              {!filePre && (
                <a href="#" style={{ fontSize: "200px" }}>
                  <i className="far fa-image" />
                </a>
              )}
              {filePre && (
                <img
                  src={filePre}
                  style={{
                    height: "200px",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Form>
    </TabPane>
    <TabPane tab="Add Video" key="2">
      <Form>
        <div className="form-group">
          <label>Status :</label>
          <br />
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="inlineRadios1"
              onClick={() => setStatus("0")}
              value="0"
              checked={status === "0"}
            />
            <label className="form-check-label" htmlFor="inlineRadios1">
              Inactive
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="inlineRadios2"
              onClick={() => setStatus("1")}
              value="1"
              checked={status === "1"}
            />
            <label className="form-check-label" htmlFor="inlineRadios2">
              Active
            </label>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label className="form-control-label">Video</label>
            <input
              type="text"
              name="link"
              ref={VideoRef}

              id="link"
              onChange={(e) => setVideoLink(e.target.value)}
              className="form-control"
              placeholder="Video Link"
            />
          </div>
        </div>
      </Form>
    </TabPane>
  </Tabs>
</Modal>

                              <Modal
                            title="Video"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                                height:"450px"
                              }}
                              open={isModalVisibleForm2}
                            okText="Ok"
                              onOk={() => {
                                // handleAddNotification();
                                // setIsModalVisibleForm2(false);
                                handleCloseVideo()

                              }}
                              onCancel={() => {
                                // setIsModalVisibleForm2(false);
                                handleCloseVideo()
                              }}
                            >
             
<div>
 <div style={{height:"400px",width:"100%"}}>
 
      <iframe
        title="Video"
        ref={iframeRef}
        src={videoid}
        width="80%"
        // height="400"
        frameBorder="0"
        allowFullScreen
        style={{marginTop:"-40px",height:"400px"}}
      ></iframe>
       {/* <VideoJs
                                      src={`https://www.youtube.com/embed/${ytcode}`}
                                      type="video/youtube"
                                    /> */}
    
    
  </div>
</div>
                            </Modal>

                              <Modal
                                title="Add Image Index"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                width={320}
                                open={isModalVisibleForm1}
                                okText="Save"
                                onOk={() => {
                                  UpdateCourseIndex();
                                }}
                                onCancel={handleModalClose1}
                              >
                                <div
                                  style={{
                                    maxHeight: "420px",
                                    overflow: "auto",
                                  }}
                                >
                                  {image?.length > 0
                                    ? image?.map((item) => {
                                        return (
                                          <div
                                            className="box source col-4 d-flex"
                                            style={{
                                              margin: "auto",
                                              marginTop: "10px",
                                              boxShadow: "none",
                                            }}
                                            key={item?.id}
                                            draggable
                                            // onDragStart={() => handleDragStart(item.id)}
                                            onDragStart={handleDragStart(
                                              item?.id
                                            )}
                                            onDragOver={handleDragOver}
                                            onDrop={handleDrop(item?.id)}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                margin: "auto",
                                              }}
                                            >
                                              <img
                                                src={
                                                  item?.image
                                                    ? item?.image
                                                    : CoursesImage
                                                }
                                                style={{
                                                  width: "70px",
                                                  height: "70px",
                                                  borderRadius: "50px",
                                                }}
                                                alt=""
                                              />
                                            </div>

                                            {/* <img src={MoveIcon} alt="move" style={{width:"25px",height:"25px",marginLeft:"25px"}}/> */}
                                          </div>
                                        );
                                      })
                                    : "No Data"}
                                </div>
                              </Modal>

                              <table className="table table-nowrap mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>Sr.No</th>
                                    <th>Category</th>

                                    <th>Image</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                {loading === true ? (
                                  <tbody
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : list?.length > 0 ? (
                                  list?.map((item, i) => {
                                    const serialNumber =
                                      (currentPage - 1) * itemsPerPage + i + 1;
                                    return (
                                      <tbody
                                        key={i}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <tr className="text-center">
                                          <td>{serialNumber}</td>
                                          <td>{item?.category}</td>
                                          <td>
                                            <div
                                              style={{
                                                justifyContent: "center",
                                              }}
                                              className="sell-table-group d-flex align-items-center"
                                            >
                                              <div
                                                className="sell-group-img"
                                                style={{ height: "none" }}
                                              >
                                                <Link>
                                                {item?.category=="image"?<img
                                                    src={item?.image}
                                                    style={{
                                                      borderRadius: "15px",
                                                      width: "90%",
                                                      height: "90%",
                                                      objectFit: "cover",
                                                    }}
                                                    className="img-fluid "
                                                    alt=""
                                                  />:
                                                  <div  onClick={()=>{
                                                    let embedUrl;
                          
                              // Check if it's a YouTube link
                              if (item?.image?.includes('youtube.com')) {
                                const videoId = item.image.split('v=')[1];
                                embedUrl = `https://www.youtube.com/embed/${videoId}`;
                              }
                              // Check if it's a Vimeo link
                              else if (item?.image?.includes('vimeo.com')) {
                                const videoId = item?.image.split('/').pop();
                                embedUrl = `https://player.vimeo.com/video/${videoId}`;
                              }
                              // For any other link
                              else {
                                embedUrl = item?.video; // Assuming it's a valid video URL
                              }
                              handelvimeovideo(embedUrl)
                                                  }}>
                                                    {item?.thumnail? <img style={{
                                                    borderRadius: "15px",
                                                    width: "100%",
                                                    height: "100%",
                                                    marginTop:"10px",
                                                    objectFit: "cover",
                                                  }}src={item?.thumnail} />: <img style={{
                                                    borderRadius: "15px",
                                                    width: "60%",
                                                    height: "60%",
                                                    marginTop:"10px",
                                                    objectFit: "cover",
                                                  }}src={PlayVideo} />}
                                                 
                                                  </div>
                                                  }
                                                  
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <Switch
                                              onChange={(e) => {
                                                handleChange(
                                                  item?.id,
                                                  item?.status
                                                );
                                              }}
                                              checked={
                                                item?.status == 1
                                                  ? true
                                                  : false
                                              }
                                              // id={item?.id}
                                              onColor="#86d3ff"
                                              onHandleColor="#2693e6"
                                              handleDiameter={20}
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                              height={15}
                                              width={38}
                                              className="react-switch"
                                            />
                                          </td>

                                          <td>
                                            <Tooltip title="Delete">
                                              {" "}
                                              <Button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  showConfirm(item?.id)
                                                }
                                              >
                                                <i className="fa-regular fa-trash-can"></i>
                                              </Button>
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </table>
                            </div>
                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          {/* <div className="fixtable">
                            {list ?.length <= 0  ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={IMAGESLIST}
                                setData={setList}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={COURSESEARCH}
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
