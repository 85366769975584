import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../footer";
import Users from "../../../assets/img/user/Userspic.png";
import Courses from "../../../assets/img/user/Courseicon.jpg";
import Video from "../../../assets/img/user/Videoicon.jpg"
// import { InstructorHeader } from "../../instructor/header";
import AdminHeader from "../adminHeader";
import AdminInstructorSidebar from "../adminSidebar";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import { Switch, notification } from "antd";
import { Tooltip, Input } from "antd"; 

const COURSELIST = "/api/course/view";
const USERS = "/api/admin/dashboard/all-users";
const VIDEOS = "/api/admin/dashboard/all-video";
const COURSES = "/api/admin/dashboard/all-courses";
const NEW ="/api/admin/dashboard/new-student";
const EXPIRED="/api/admin/dashboard/new-expired-student"

export const AdminDashboard = () => {
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);
  const [initialState, setinitialState] = useState({
    series: [
      {
        name: "Current Month",
        data: [0, 10, 40, 43, 40, 25, 35, 25, 40, 30],
      },
    ],

    options: {
      chart: {
        height: 300,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#FF875A"],
      fill: {
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.5,
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: [
          "",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },

      title: {
        text: undefined,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-title",
        },
      },
      yaxis: {
        // seriesName: "seriesY",
        logbase: 10,
        // logarithmic: true,
        tickAmount: 5,
        axisBorder: {
          show: true,
          color: "#E5E4E2",
          offsetX: 0,
          offsetY: 0,
        },
      },
      grid: {
        show: true, // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: false, //or just here to disable only y axis
          },
        },
      },
      markers: {
        size: 3,
        colors: undefined,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: 8,
          sizeOffset: 3,
        },
      },
      tooltip: {
        x: {
          // format: "dd/MM/yy HH:mm",
        },
      },
    },
  });
  const [usersdata, setUsersData] = useState([]);
  const [videodata, setVideoData] = useState([]);
  const [coursedata, setCourseData] = useState([]);
  const[studentdata,setStudentData]=useState([])
  const[expireddata,setExpiredData]=useState([])

  const [orderChart, setOrderChart] = useState({
    series: [
      {
        name: "Current Month",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "19%",
          endingShape: "rounded",
          borderRadius: 7,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      colors: ["#1d9cfd"],
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        // seriesName: "seriesY",
        logbase: 10,
        // logarithmic: true,
        tickAmount: 5,
        axisBorder: {
          show: false,
          color: "#E5E4E2",
          offsetX: 0,
          offsetY: 0,
        },
      },
      grid: {
        show: true, // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
    },
  });
  const openNotificationWithIcon2 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const fecthCoureList = async () => {
    const resonse = await axios.get(COURSELIST, config);
    if (resonse == 401) {
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    fecthCoureList();
  }, []);
  const users = async () => {
    try{
      const resonse = await axios.get(USERS, config);
      setUsersData(resonse?.data);
    }
    catch(err){
      console.log(err)
      if (err?.response?.status == 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login")

      }
      else{
        openNotificationWithIcon2("error",err?.response?.data?.message)
      }
    }
    
 
  };
  const Videos = async () => {
    try{
      const resonse = await axios.get(VIDEOS, config);
      setVideoData(resonse?.data);
    }
    catch(err){
      console.log(err)
      if (err?.response?.status == 401) {
        localStorage.removeItem("token");
        navigate("/admin/login")

      }
      else{
        openNotificationWithIcon2("error",err?.response?.data?.message)
      }
    }
   
   
  };
  const courses = async () => {
    try{
      const resonse = await axios.get(COURSES, config);
      setCourseData(resonse?.data);
  
    }
    catch(err){
      console.log(err)
      if (err?.response?.status == 401) {
        localStorage.removeItem("token");
        navigate("/admin/login")
      }
      
    }
   
  };
const student=async()=>{
  try{
    const resonse = await axios.get(NEW, config);
    // console.log(resonse?.data)
    setStudentData(resonse?.data);
  }
  catch(err){
console.log(err)
if (err?.response?.status == 401) {
  localStorage.removeItem("token");
  navigate("/admin/login")

}
  }
  

}
const expired=async()=>{
  try{
    const resonse = await axios.get(EXPIRED, config);
    setExpiredData(resonse?.data)
  
  }
  // setCourseData(resonse?.data);
  catch(err){
    console.log(err)
    if (err?.resonse?.satus == 401) {
      localStorage.removeItem("token");
      navigate("/admin/login")

    }
  }

}
  useEffect(() => {
    users();
    Videos();
    courses();
    student();
    expired();
  }, []);
  const currentDate = new Date();
const day = String(currentDate.getDate()).padStart(2, '0');
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Note: Month is 0-indexed
const year = currentDate.getFullYear();

const formattedDate = `${day}/${month}/${year}`;
  
  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"Dashboard"} />
      <div className="page-content instructor-page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-20px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Dashboard"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
             
            <div className="row">
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner"
                      style={{marginLeft:"30px"}}
                      >
                        <h6>Total Users</h6>
                        <h4 className="instructor-text-success">{usersdata}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner"
                      style={{marginLeft:"30px"}}
                      >
                        <h6>Total Videos</h6>
                        <h4 className="instructor-text-info">{videodata}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner"
                      style={{marginLeft:"30px"}}
                      >
                        <h6>Total Courses</h6>
                        <h4 className="instructor-text-warning">{coursedata}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>Students List</h3>
                      </div>
                      <div className="comman-space">
                        <div className="settings-tickets-blk course-instruct-blk table-responsive"
                        style={{padding:"1rem 1rem"}}
                        >
                          {/* Referred Users */}
                          <table className="table table-nowrap">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Phone No</th>
                                <th>Admission Date</th>
                                {/* <th>Status</th> */}


                              </tr>
                            </thead>
                            {loading === true ? (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}
                                >
                                  <tbody
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"

                                          role="status"
                                        >
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>

                                </div>

                              ) : (
                                <>{studentdata?.length>0? studentdata?.map((item, i) => {
                                  // const sNo = (currentPage - 1) * itemsPerPage + i + 1;
                                  return (
                                    <tbody key={item.id}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <tr className="text-center">
                                        <td className="text-center">
                                          {i+1}
                                        </td>
                                        <td className="text-center">
                                       
                                           
                                              <Tooltip title={item.name}>
                                                {`${item.name.slice(0, 25)} ${item.name.length > 25
                                                  ? "..."
                                                  : ""
                                                  }`}</Tooltip>
                                          
                                        </td>
                                        <td>{item.phone}</td>
                                        <td>{formattedDate}</td>

                                        {/* <td>
                                          <Switch
                                            // onChange={(e) => {
                                            //   handleChange(
                                            //     item.id,
                                            //     item.status
                                            //   );
                                            // }}
                                            checked={
                                              item.status === "1"
                                                ? true
                                                : false
                                            }
                                            id={item.id}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td> */}
                                        
                                      </tr>

                                    </tbody>
                                  );
                                }):
                                // <h4 style={{marginTop:"20px",textAlign:"center"}}>No  Students Registered on this date</h4>
                                ""
                                }</>
                               
                              
                              )}
                          </table>
                          {studentdata?.length <= 0 ? (
                              <h4 className="text-center">No Data</h4>
                            ) : (
                              ""
                            )}
                              {studentdata===null ? (
                              <h4 className="text-center">No Data</h4>
                            ) : (
                              ""
                            )}
                          {/* Referred Users */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>Expired Students</h3>
                      </div>
                      <div className="comman-space">
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          {/* Referred Users */}
                          <table className="table table-nowrap">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Phone No</th>
                                <th>Expiry Date</th>

                              </tr>
                            </thead>
                            {loading === true ? (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}
                                >
                                  <tbody
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"

                                          role="status"
                                        >
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>

                                </div>

                              ) : (
                              
                              <>
                              {expireddata?.length>0? expireddata?.map((item, i) => {
                                // const sNo = (currentPage - 1) * itemsPerPage + i + 1;
                                return (
                                  <tbody key={item.id}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <tr className="text-center">
                                      <td className="text-center">
                                        {i+1}
                                      </td>
                                      <td className="text-center">
                                     
                                         
                                            <Tooltip title={item.name}>
                                            {`${item?.user_detail?.name.slice(0, 25)} ${item?.user_detail?.name.length > 25
                                                ? "..."
                                                : ""
                                                }`}
</Tooltip>
                                        
                                      </td>
                                      <td>{item?.user_detail?.phone}</td>
                                    
                                   <td>
                                    {item?.expiry_date}
                                   </td>
                                      
                                    </tr>

                                  </tbody>

                                );
                              }):
                              ""
                              } 
                              </>
                              
                              )}
                          </table>
                          {expireddata?.length <= 0 ? (
                              <h4 className="text-center">No Data</h4>
                            ) : (
                              ""
                            )}
                              {expireddata===null ? (
                              <h4 className="text-center">No Data</h4>
                            ) : (
                              ""
                            )}
                          {/* Referred Users */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
