import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Course15,
  Course16,
  Course17,
  Icon1,
  Icon2,
  User,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  Share,
} from "../../../imagepath";
import axios from "../../../../api/axios";
import axiosdata from "axios"
import { useState } from "react";
const GETCOURSE = "api/course-category/students/course";
const CONTACT="api/contact-details"
const PAYMENTVALUE="api/transaction/redirect"
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { event } from "jquery";
import "./gridinner.css";
import { notification, Space, Tooltip } from "antd";
import PaginationStudent from "../addCourse/PaginationStudentSub";
import axiosInstance from "../../../../api/axios1";
import  WhatsApp  from "../../../../assets/img/logo/whatsapp.png";
// import PhonePeSDK from 'phonepesdk-web';



const GridInnerPage = ({id}) => {
  // console.log(id,"id")

  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const [data, setData] = useState([]);
  const tokenStudent = localStorage.getItem("tokenStudent");

  const phoneno=localStorage.getItem("phoneno")
  const [list, setList] = useState(null);
  const [listdata, setlistdata] = useState(null);
  const [number, setNumber] = useState(null);

  const [loading, setLoading] = useState(false);
  const [all, setAll] = useState(false);
  const [free, setFree] = useState(false);
  const [searchvalue, setSearch] = useState("");
  const [paid, setPaid] = useState(false);
  const [freeCourses, setFreeCourses] = useState([]);
  const [PaidCourses, setPaidCourses] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [last_page, setLast_page] = useState("");
  const scrollRef = useRef(null);
const [ammount,setAmmount]=useState(null)
const [courseid,setCourseId]=useState(null)
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const [coursesToShow, setCoursesToShow] = useState(4);
  const userid=localStorage.getItem("userid")
  

  const handleViewMore = () => {
    // Increase the number of courses to display
    setCoursesToShow(coursesToShow + 4);
  };
  
  const getCourse = async () => {
      try {
          setLoading(true);
          const response = await axiosInstance.get(`${GETCOURSE}/${id}`, config);
          const coursedata = response?.data?.message?.data;
          // console.log(coursedata,"response")
  setLoading(true)
          const organizedCourses = organizeCoursesByType(coursedata);
  
          // console.log(organizedCourses);
  
          setList(organizedCourses);
          setData(response?.data?.message);
          setLoading(false)
      } catch (error) {
          console.log(error);
          if (error.response && error?.response?.status === 401) {
            openNotificationWithIconSesion("error")

              localStorage.removeItem("tokenStudent");
              navigate("/login");
          }
          else{
            openNotificationWithIcon3("error",error?.response?.data?.message);

          }
      } finally {
          setLoading(false);
      }
  };
  const getCotact = async () => {
    try {
        setLoading(true);
        const response = await axiosInstance.get(CONTACT, config);
        // console.log(response,"res")
        setNumber(response?.data?.detail)

        setLoading(false)
    } catch (error) {
        console.log(error);
        if (error.response && error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/login");
        }
        openNotificationWithIcon2("error");
    } finally {
        setLoading(false);
    }
};
  // Function to organize courses based on course_type.id
  const organizeCoursesByType = (coursedata) => {
    const organizedCourses = {};
  
    coursedata?.forEach((course) => {
      const courseType = course.course_type;
      const courseId = courseType?.id;
  
      if (courseId) {
        if (!organizedCourses[courseId]) {
          organizedCourses[courseId] = {
            name: courseType.name,
            course_type_id: courseId,
            courses: [],
          };
        }
  
        organizedCourses[courseId].courses.push(course);
      }
    });
  
    return Object.values(organizedCourses);
  };
  
  
  
  
  useEffect(() => {
    getCourse();
    getCotact()
  }, [id]);
  // console.log(currentPage,"pages",last_page)
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const visibleCourse = list?.slice(indexOfFirstCourse, indexOfLastCourse);

  const courseLength = data?.length ? data?.length : 0;
  const numOFTotalPage = Math.ceil(courseLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);

  const navigate = useNavigate();


  useEffect(() => {
    if (data) {
      const filteredFreeCourses = list?.filter((item) => item?.amount == 0);
      setFreeCourses(filteredFreeCourses);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      const filteredPaidCourses = list?.filter((item) => item?.amount != 0);
      setPaidCourses(filteredPaidCourses);
    }
  }, [data]);


  const Filter = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    // console.log(searchTerm, "searchterm"); 
    setSearch(searchTerm);

    if (!searchTerm) {
      getCourse();
      return;
    }
  
    const searchlist = data?.filter((t) =>
      t.name.toLowerCase().includes(searchTerm)
    );
    // console.log(searchlist,"serachlist")
    setData(searchlist);
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const RadioCourses = (event) => {
    if (event?.name == "paid") {
      var paidcourse = list?.filter((item) => Number(item?.amount) > 0);
      
      if (event.checked) {
        setPaid(!paid);
        setFree(false);
        setAll(false);
      }

      setData(paidcourse);
    }
    if (event?.name == "free") {
      var freeCourses = list?.filter((item) => item.amount == 0);
      // console.log(freeCourses)
      if (event?.checked) {
        setFree(!free);
        setPaid(false);
        setAll(false);
      }
      setData(freeCourses);
    }
    if (event?.name == "all") {
      // var allCourse = data?.slice(index
      if (event?.checked) {
        setAll(!all);
        setPaid(false);
        setFree(false);
      }
      getCourse();
      return;
    }
  };
  // console.log(searchvalue,"serach")
  const ClearOptions = () => {
    // console.log(searchvalue,"serach in clear ")
    setSearch(" ");
    // console.log(searchvalue,"serach in after ")

    setAll(false);
    setPaid(false);
    setFree(false);
    // setData(data)
    getCourse();
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1023);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const itemsPerPage = 10;
  // const scrollToItem = (itemName) => {
  //   const element = document.getElementById(itemName);
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };
  const scrollToItem = (itemName) => {
    const element = document.getElementById(itemName);
    if (element) {
      // Scroll to the target div
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  
      // Adjust the scroll position to ensure it stops at the top of the div
      const yOffset = -100; // Adjust this value as needed
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  // const requestData={
  //   // "PHONEPE_MERCHANT_ID":"PGTESTPAYUAT",
  //   // "PHONEPE_MERCHANT_USER_ID":"",
  //   // "PHONEPE_ENV":"staging",
  //   // "PHONEPE_SALT_KEY":"099eb0cd-02cf-4e2a-8aca-3e6c6aff0399",
  //   // "PHONEPE_SALT_INDEX":"1",
    
  //     "merchantId": "PGTESTPAYUAT",
  //     "merchantTransactionId": "MT7850590068188104",
  //     "merchantUserId": "698ed8ad-baa6-4890-8efb-fe21a8e9c797",
  //     "amount": "10000",
  //     "redirectUrl": "https://webhook.site/redirect-url",
  //     "redirectMode": "REDIRECT",
  //     "callbackUrl": "https://webhook.site/callback-url",
  //     "mobileNumber": "9640671866",
  //     "paymentInstrument": {
  //       "type": "PAY_PAGE"
  //     }
  //   }
  const generateMerchantTransactionId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';
    const idLength = 15; // Maximum length allowed for merchantTransactionId
    let merchantTransactionId = '';
  
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      merchantTransactionId += characters.charAt(randomIndex);
    }
    
  
    return merchantTransactionId;
  };
//   const dynamicMerchantTransactionId = generateMerchantTransactionId();
// console.log(dynamicMerchantTransactionId);



// console.log(ammount,courseid)
  

   
    const handelfreeCourse=async(userid,id,duration)=>{
      try{
        const response=await axiosInstance.post(COURSESUBSCRPTION,{
          "user_id":userid,
          "course_id":id,
          "expiry_date":duration
        }, config);
        if(response){
          openNotificationWithIcon1("success");
          getDetail1()
          navigate(-1)

        }
        
        
        } 
        catch(error){
          console.log(error,"error")
        
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
        openNotificationWithIcon("error",error?.response?.data?.message,"red")
          }
        }

  }
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Course Subscriped Sucessfully</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  
  
  const merId = "CHAHALPGONLINE";
  const saltKey = "698ed8ad-baa6-4890-8efb-fe21a8e9c797";
  const saltIndex = "1";
  
  async function sha256(checksum) {
    const encoder = new TextEncoder();
    const data = encoder.encode(checksum);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }
  
  const paymentData = async (amount, courseid) => {
    // Generate a unique merchantTransactionId
    const merchantTransactionId = generateMerchantTransactionId();
  
    // Construct the request data payload
    const requestData = {
      "merchantId": merId,
      "merchantTransactionId": merchantTransactionId,
      "merchantUserId": "ChahalMUID8343",
      "amount": +(amount * 100),
      "redirectUrl": `https://lmscodenew.chahalacademy.com//api/transaction/redirect?course=${courseid}&user=${userid}`,
      "redirectMode": "POST",
      "callbackUrl": "https://lmscodenew.chahalacademy.com//api/transaction/redirect",
      "mobileNumber": phoneno ? phoneno : number,
      "paymentInstrument": {
        "type": "PAY_PAGE"
      },
    };
  
    // Convert the payload to JSON string
    const payload = JSON.stringify(requestData);
    console.log(payload,"payload");
  
    // Base64 encode the payload
    const payloadBase64 = window.btoa(payload);
    console.log(payloadBase64,"payloadBase64");

  
    // Concatenate payload with "/pg/v1/pay" and salt key
    const concatenatedString = payloadBase64 + '/pg/v1/pay' + saltKey;
    
    console.log(concatenatedString,"concatenatedString");

    // Calculate SHA256 hash of the concatenated string
    const hash = await sha256(concatenatedString);
    console.log(hash,"hash");
  
    // Concatenate the hash with "###" and salt index
    const checksum = hash +' ### '+ saltIndex;
  
    console.log(checksum, "check", );
  
    // Construct HTTP request options
    const URL = "https://api.phonepe.com/apis/hermes/pg/v1/pay";
    const options = {
      method: 'POST',
      url: URL,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'X-VERIFY': checksum
      },
      data: {
        request: payloadBase64
      }
    };
  
    try {
      // Send the request and handle the response
      const response = await axiosdata.request(options);
      console.log(response.data);
      const url = response.data.data.instrumentResponse.redirectInfo.url;
      window.location = url;
    } catch (error) {
      console.error(error);
    }
  };
  

  


  return (
    <>
      <div className="showing-list">
        <div className="row">
          <div className="">
            <div className="d-flex"
            style={{justifyContent:"space-evenly",gap:"10px",flexWrap: "wrap",maxWidth:"100%"}}
            >
       {list?.length>0?list?.map((item)=>{
        return(
          <div className="d-flex col-lg-2 col-sm-12"
          key={item?.course_type_id}
        
             style={{justifyContent:"center",}}
             
             >
           
           <div className="about-button more-details d-flex">
                    <button to="" 
                    onClick={() => scrollToItem(item?.name)}    
                    className="discover-btn"
                    style={{padding:"15px 35px"}}
                    >
                     {item?.name}
                    </button>
                  </div>
          </div>

        )
       }):""}
      </div>
          </div>
         
        </div>
      </div>
    
      <div className="row">
      
        <div
          className="col-lg-12 datavalue"
          
          // style={{ marginTop: "-35px" }}
        >
          <div className="row mt-2"
          
          
          >
            {loading === true ? (
              <div style={{ display: "block" }} id="cover-spin"></div>
            ) : list?.length > 0 ? (
              list?.map((item,index) => {
                return (
                  <div key={index}
                  ref={scrollRef}
                  id={item?.name}

                  >
                  <div
                className="show-result d-flex"
                style={{
                  justifyContent: "space-between",
                  // marginBottom:"0px"
                }}

                
              >
                <h3 className="mb-3 mb-md-4">{item?.name}</h3>
            <button className="btn btn-link"
            onClick={() => handleViewMore()}

            >View More</button>
              </div>
              <div className="row">
              {item?.courses?.
               slice(0, coursesToShow)?.
              map((item1 ,i1)=>{
              
                return(
                  
            <div className="col-lg-3 col-md-6 d-flex"
              key={item1?.id}
                >
                    <div
                      className="course-box course-design"
                      style={{
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div className="product ">
                        <div className="product-img">
                          <Link>
                            {item1?.image === null ? (
                              <img
                                style={{ maxHeight:"220px",width:"100%" }}
                                id="img-grid"
                                className="img-fluid"
                                alt=""
                                src={Share}
                              />
                            ) : (
                              <img
                                // style={{ width: "389px", height: "271px" }}
                                className="img-fluid"
                                id="img-grid"
                                style={{maxHeight:"200px",width:"100%"}}
                                alt=""
                                src={`${item1?.image}`}
                              />
                            )}
                          </Link>
                          <div className="price" style={{ cursor: "pointer",}}>
                            <h3  style={{color:"#080F5B",}}>
                              {+item1.amount === 0 ? (
                                <div>
                                <h3 className="free-color"
                                style={{color:"#080F5B"}}
                                >FREE</h3>
                                </div>
                              ) : (
                                <>
                                  ₹{" "}
                                  {parseInt(item1.amount).toLocaleString(
                                    "en-IN",
                                    {
                                      maximumFractionDigits: 0,
                                    }
                                  )}
                                </>
                              )}
                            </h3>
                          </div>
                        </div>
                        <div className="product-content">
                          <div className="course-group d-flex"
                          style={{justifyContent:"space-between"}}
                          >
                            <div className="course-group-img d-flex"
                            style={{alignItems:"center",alignSelf:"center"}}
                            >
                             
                              <div className="course-name">
                                <Tooltip title={item1?.name}>
                                <h3
                                  className="mt-3"
                                  style={{
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                    fontWeight:600,
                                    fontSize:"20px"
                                  }}
                                >
                                  <Link>
                                    {item1?.name.slice(0, 10)}
                                    {item1?.name?.length > 10 ? "..." : ""}
                                  </Link>
                                  {/* <Link>{item?.name}</Link> */}
                                </h3>
                                </Tooltip>
                                {/* <p>Instructor</p> */}
                              </div>
                             
                            </div>
                            {/* <div className="d-flex align-items-center"
                            style={{marginTop:"5px"}}
                            >
                               <button
                                onClick={() =>
                                  navigate(`/student/courses-slug/${item1.slug}`)
                                }
                                className="btn btn-dark"
                                id=""
                                style={{
                                // padding:"20px"
                              }}
                              >
                                Know More                              
      
                              </button>
                            </div> */}
                           
                          </div>
                          
                 
                          <div className="all-btn all-category align-items-center d-flex justify-content-between flex-wrap "
                          
                          >
  <a
    target="_blank"
    href={`https://wa.me/${number}?text=I%20need%20to%20buy%20this%20course%20${name}`}
    className="whatsapp-button "
    // style={{ backgroundColor: "white" }}
  >
    WhatsApp
  </a>
  {item1?.amount==0?
  
  <a
    className="payment-button"
      // target="_blank"
       href={`/student/courses-slug/${item1.slug}`}
    >
      Subscribe 
    </a>
  :
  
    // <a
    // className="payment-button"
    //   target="_blank"
    //   href={`https://wa.me/${number}?text=I%20need%20to%20buy%20this%20course%20${name}`}
    // >
    //   Buy Now 
    // </a>
    <button
    onClick={() =>
      navigate(`/student/courses-slug/${item1.slug}`)
    }
    className="btn btn-dark"
    id=""
    style={{
    // padding:"20px"
  }}
  >
    Know More                              

  </button>
              }
</div>

                        </div>
                      </div>
                    </div>
                  </div>
                  
                )
              })
              
              }
              </div>

                  
                  </div>
                );
              })
            ) : (
              <h2 className="text-center">No Courses</h2>
            )}
          </div>
        </div>
        {/* {!isSmallScreen && (
          <div
            className="col-lg-3 col-item "
            id="filter"
            // style={{marginBottom:"30px"}}
            // style={{marginTop:"-38px"}}
          >
            <div
              className="col-lg-12"
              style={{ justifyContent: "space-between" }}
            >
              <div className="clear-filter d-flex align-items-center">
                <h4>
                  <FeatherIcon icon="filter" />
                  Filters
                </h4>
                <div className="clear-text">
                  <button onClick={ClearOptions} style={{ border: "none" }}>
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-12"
              // style={{border:"1px solid red"}}
            >
              <div className="show-filter add-course-info">
                <form action="#">
                  <div className="">
                    <div className="">
                      <div
                        className=" search-group"
                        style={{ marginTop: "-15px" }}
                      >
                        <i className="feather-search">
                          <FeatherIcon icon="search" />
                        </i>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search our Courses"
                          onChange={Filter}
                          value={searchvalue}

                          // onKeyDown={handleKeyPress}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-12" style={{ marginTop: "20px" }}>
              <div className="card search-filter ">
                <div className="card-body">
                  <div className="filter-widget mb-0">
                    <div className="categories-head d-flex align-items-center">
                      <h4>Price</h4>
                      <i className="fas fa-angle-down" />
                    </div>
                    <div>
                      <label className="custom_check custom_one">
                        <input
                          type="checkbox"
                          name="all"
                          checked={all}
                          onClick={(event) => {
                            RadioCourses(event.target), setAll(true);
                          }}
                        />
                        <span className="checkmark" /> All ({list?.length})
                      </label>
                    </div>
                    <div>
                      <label className="custom_check custom_one">
                        <input
                          type="checkbox"
                          name="free"
                          checked={free}
                          onClick={(event) => {
                            RadioCourses(event.target), setFree(true);
                          }}
                        />
                        <span className="checkmark" /> Free (
                        {freeCourses?.length})
                      </label>
                    </div>
                    <div>
                      <label className="custom_check custom_one">
                        <input
                          type="checkbox"
                          name="paid"
                          checked={paid}
                          onClick={(event) => {
                            RadioCourses(event.target), setPaid(true);
                          }}
                        />
                        <span className="checkmark" /> Paid Courses (
                        {PaidCourses?.length})
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
      {/* {data?.length > 0 ? (
        <div className="fixtable">
          <PaginationStudent
            nPages={last_page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            link={GETCOURSE}
            setData={setData}
          />
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default GridInnerPage;
