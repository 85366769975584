import React, { useContext, useEffect, useRef, useState } from "react";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";

import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import { Input, Tooltip } from "antd";
import Button from "react-bootstrap/Button";

import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
import Select from "react-select";
const COURSELIST = "/api/admin/popular/non_popular_courses";
const COURSELISTDETAILS = "/api/admin/homescreen/items";
const APPHOMELIST = "/api/admin/homescreen";
const DELETEAPPHOMESCREEN = "/api/admin/homescreen/delete";
const STATUSCHANGE = "/api/admin/homescreen/status";
const APPCOURSECRETE="/api/admin/homescreen/items"
import "react-toastify/dist/ReactToastify.css";
import { notification, Modal } from "antd";
import Form from "react-bootstrap/Form";
import "../addAdminRecordedVideos/insex.css";
import moment from "moment";
const { confirm } = Modal;
import Switch from "react-switch";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";

export default function AppHomeScreen() {
  const { Search } = Input;
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [dataid, setdataid] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [deleteid, setDeleteId] = useState("");
  const [message, setmessage] = useState("No Data");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [coursedataList, setCourseDataList] = useState([]);
  const [loading1, setLoading1] = useState(false);

  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "auto",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "white" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };

  const fetchCourseCategory = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(APPHOMELIST, config);
      //  console.log(resonse?.data,"resonse")

      // setitemsPerPage(batch_detail_relation.length)
      setList(resonse?.data);
      setLast_page(resonse?.data?.last_page);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
        openNotificationWithIconSesion("error")
      }
      else{
        openNotificationWithIcon2("error", "Internal Server", "red");
      }
    }
  };
  const coursesList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(COURSELIST, config);
      // console.log(resonse?.data, "resonse");

      setCourseDataList(resonse?.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")

        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error", "Internal Server", "red");

      }
    }
  };
  const coursesListDetails = async (id) => {
    // console.log(id,"id")
    setdataid(id);

    try {
      setLoading1(true);
      const resonse = await axios.get(`${COURSELISTDETAILS}/${id}`, config);
      // console.log(resonse?.data, "resonse");
      const selectedCourseIds = resonse?.data.map((item) => +item?.fk_item_id);
      // console.log(selectedCourseIds, "selected courseids");

      // Map selectedCourseIds to corresponding Select options
      const selectedOptionsdata = coursedataList
        .filter((course) => selectedCourseIds.includes(course?.id))
        .map((course) => ({ label: course?.name, value: course?.id }));
      // console.log(selectedOptions, "options");

      setSelectedOptions(selectedOptionsdata);
      setLoading1(false);
      handleShowForm1()

    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      setLoading1(false);
    }
  };
  // console.log(dataid,"dataid")
  const handleAddCourselist = async () => {
    const datavalue=selectedOptions?.map((item)=>(item.value))
    try {
      setLoading1(true);
      const resonse = await axios.post(APPCOURSECRETE,{fk_home_screen_id:dataid,fk_item_id:datavalue} , config);
      if(resonse){
        // console.log("krishna")
        setIsModalVisibleForm1(false);
        openNotificationWithIcon1("success", "Courses List Updated", "#52c41a");

      }

    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      setLoading1(false);
    }
  };

  useEffect(() => {
    fetchCourseCategory();
    coursesList();
  }, []);
  const handleEdit = (id) => {
    navigate(`/admin/edit/app/homescreen/${id}`);
  };
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.put(
      `${STATUSCHANGE}/${id}`,
      { status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success", "Status Updated", "#52c41a");
    }
    if (!response) {
      openNotificationWithIcon2("error", "Internal Server", "red");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };

  const handleShowForm1 = () => {
    setIsModalVisibleForm1(true);
  };

  const openNotificationWithIcon1 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblerecordsData = list?.slice(indexOfFirstCourse, indexOfLastCourse);
  const recordsDataLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math.ceil(recordsDataLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);
  const statusRef = useRef(null);
  const imageRef = useRef(null);
  

  const handleModalDeleteClose = () => {
    setIsModalVisibleForm1(false);
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  let itemsPerPage = 10;

  function showConfirm(id) {
    setDeleteId(id);
    confirm({
      title: "Do you want to delete this App HomeScreen?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.delete(
            `${DELETEAPPHOMESCREEN}/${id}`,
            config
          );
          //  console.log("resonse", resonse?.status);
          if (resonse?.status == 205) {
            handleShowForm1();
          } else {
            openNotificationWithIcon1("success", "App HomeScreen Deleted", "#52c41a");

            setLoading(false);
            fetchCourseCategory();
          }

          setShow(false);
        } catch (error) {
          console.log(error?.response?.data?.message);
          if (error?.response?.status == 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          } else if (error.response.status == 422) {
            openNotificationWithIcon2(
              "error",
              error?.response?.data?.message,
              "red"
            );
            fetchCourseCategory();
          } else {
            openNotificationWithIcon2(
              "error",
              error?.response?.data?.message,
              "red"
            );
          }
        }
      },
      onCancel() {},
    });
  }

  // console.log(imagevalue,"list")
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget ttt">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div>
                            <h3 style={{ textTransform: "capitalize" }}>
                              App HomeScreen
                            </h3>
                            <nav
                              aria-label="breadcrumb"
                              style={{ marginBottom: "-1.6em" }}
                            >
                              <ol className="breadcrumb">
                                <li
                                  className="breadcrumb-item "
                                  aria-current="page"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "0.5em",
                                  }}
                                  onClick={() =>
                                    navigate("/admin/course/category")
                                  }
                                >
                                  Course Category
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "0.5em",
                                  }}
                                  // onClick={() =>
                                  //   navigate("/admin/recorded-videos")
                                  // }
                                >
                                  {" "}
                                  App HomeScreen
                                </li>
                              </ol>
                            </nav>
                          </div>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                          // className="settings-referral-blk table-responsive"
                          // id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    <div className="col-md-6 col-lg-6"></div>
                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="button-conatiner d-flex justify-content-end"
                                        // style={{  marginBottom:"-5px"}}
                                      >
                                        {/* <Button
                                          onClick={() =>
                                            navigate("/admin/course/index")
                                          }
                                          
                                          className="btn btn-danger"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Popular Courses22
                                        </Button> */}
                                        <Button
                                          onClick={() =>
                                            navigate(
                                              "/admin/create/app/homescreen"
                                            )
                                          }
                                          style={
                                            {
                                              // marginLeft: "5.2em",
                                              // width:"100%"
                                            }
                                          }
                                          className="btn btn-primary"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                              <Modal
                                title="Add Course"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisibleForm1}
                                okText="Create"
                                onOk={() => {
                                     handleAddCourselist();
                                }}
                                onCancel={() => {
                                  setIsModalVisibleForm1(false);
                                }}
                              >
                                <div
                                  className="col-lg-12"
                                  style={{ marginTop: "15px" }}
                                >
                                  <div className="form-group">
                                    <label className="form-label">
                                      Select Course
                                    </label>

                                    <Select
                                      className="select country-select"
                                      menuPlacement="bottom"
                                      name="course"
                                      isMulti
                                      // options={getSelectOptions()}
                                      options={coursedataList?.map((ele) => {
                                        return {
                                          label: ele.name,
                                          value: ele.id,
                                        };
                                      })}
                                      placeholder="Select Course"
                                      // onChange={(selectedOptions) => {
                                      //   // Filter out the pre-selected values from the new selection
                                      //   const newSelectedOptions = selectedOptions.filter(
                                      //     (option) => !batchsubjectlist.some((subject) => subject.courseId === option.value)
                                      //   );
                                      //   setSelectedOptions(newSelectedOptions);
                                      // }}
                                      onChange={(selectedOptions) => {
                                        setSelectedOptions(selectedOptions);
                                      }}
                                      value={
                                        selectedOptions ? selectedOptions : ""
                                      }
                                      styles={style}
                                    />

                                    {/* {errors.batch && touched.batch ? (
      <span style={{ color: "red" }}>{errors.batch}</span>
    ) : null} */}
                                  </div>
                                </div>
                              </Modal>

                              <table className="table table-nowrap mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>SR.NO</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>ACTION</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                {loading === true ? (
                                  <tbody
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : list?.length > 0 ? (
                                  list?.map((item, i) => {
                                    const serialNumber =
                                      (currentPage - 1) * itemsPerPage + i + 1;
                                    return (
                                      <tbody
                                        key={i}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <tr className="text-center">
                                          <td>{serialNumber}</td>
                                          <td>
                                            <Link
                                              to={`/admin/course-module/${item?.id}`}
                                              className="refer-avatar-blk align-items-center"
                                            >
                                              <p>{item?.name}</p>
                                            </Link>
                                          </td>

                                          <td>
                                            <Switch
                                              onChange={(e) => {
                                                handleChange(
                                                  item?.id,
                                                  item?.status
                                                );
                                              }}
                                              checked={
                                                item?.status == 1
                                                  ? true
                                                  : false
                                              }
                                              // id={item?.id}
                                              onColor="#86d3ff"
                                              onHandleColor="#2693e6"
                                              handleDiameter={20}
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                              height={15}
                                              width={38}
                                              className="react-switch"
                                            />
                                          </td>
                                          <td>
                                            <Tooltip title="Edit">
                                              <Button
                                                onClick={() =>
                                                  handleEdit(item?.id)
                                                }
                                                type="button"
                                                className="btn btn-secondary"
                                              >
                                                <i className="fa-regular fa-pen-to-square"></i>
                                              </Button>{" "}
                                            </Tooltip>
                                            <Tooltip title="Add Courses">
                                              <button
                                                onClick={() =>
                                                   coursesListDetails(item?.id)
                                                  // setIsModalVisibleForm1(true)
                                                }
                                                // type="button"
                                                className="btn btn-info"
                                              >
                                                <i
                                                  style={{ color: "white" }}
                                                  className="fa-solid fa-plus"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>{" "}
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                              {" "}
                                              <Button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  showConfirm(item?.id)
                                                }
                                              >
                                                <i className="fa-regular fa-trash-can"></i>
                                              </Button>
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </table>
                            </div>
                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          {/* <div className="fixtable">
                            {list ?.length <= 0  ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={APPHOMELIST}
                                setData={setList}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={COURSESEARCH}
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
