import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notification, Space } from "antd";

const ADDZOOM = "https://zoomservice.chahalacademy.com/api/zoom/create-account";

const initalValue = {
  clientid: "",
  clentsecret: "",
  redirecturl: "",
  email: "",
  serverid: "",
  serversecret: "",
  status:"",
  app_key:"lmsv2",
  server_client_account:"",
  

};
const studentSchema = Yup.object({
  clientid: Yup.string().min(1).required("Please enter client id"),
  clentsecret: Yup.string().min(1).required("Please enter your clent secret"),
  redirecturl: Yup.string().min(1).required("Please enter your redirect url"),
  email: Yup.string().email().required("Please enter your email"),
});
export default function AdminAddZoom() {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [submit, setSubmit] = useState(false)

  const [toast, setToast] = useState(false)
  const openNotificationWithIcon = (type) => {
   
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong> Account Created</strong>
              </div>
            ),
            duration:1

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted

          // </div>)

        });






  };
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  const openNotificationWithIcon2 = (type) => {

    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
    const openNotificationWithIconLogin = (type,text) => {

      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>{text} </strong> 
          </div>
        ),
     
        duration:1
      });
      };
  const { errors, values, touched, handleChange, handleSubmit,getFieldProps } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value) => {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        const response = await axios.post(
          ADDZOOM,
          {
            meeting_client_id: value.clientid,
            meeting_client_secret: value.clentsecret,
            meeting_redirect_url: value.redirecturl,
            email: value.email,
            server_client_id: value?.serverid,
            server_client_secret: value?.serversecret,
            status:value?.status,
            app_key:"lmsv2",
            server_client_account:value?.server_client_account
          },
          config
        );
        // console.log(response);
        if (response) {
          setSubmit(true)
          openNotificationWithIcon("success")
          setTimeout(() => {
            // navigate("/admin/test-series");
            navigate(-1);
          }, 1000);
          // toast.success("Account Created");
        }
      } catch (error) {
        console.log(error);
        setSubmit(false)
        if (error?.response?.status === 401) {
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIconLogin("error", error?.response?.data?.message);
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else{
        openNotificationWithIcon2("error")
        }
      }
    },
    validationSchema: studentSchema,
  });

  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"Profile"} />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
                style={{minWidth:"100%",marginTop:"-15px"}}

        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Manage Account</h3>
                    {/* <p>Add Test Series information.</p> */}
                    <nav aria-label="breadcrumb" style={{ marginTop: "0.5em" }}>
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li
                          className="breadcrumb-item "
                          aria-current="page"
                          role="button"
                          onClick={() => navigate("/admin/live-classes")}
                        >
                          Live Classes
                        </li>
                        <li
                          className="breadcrumb-item"
                          role="button"
                          onClick={() => navigate("/admin/manage-details")}
                          aria-current="page"
                          style={{ fontWeight: 'normal' }}
                        >
                          Manage Accounts
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                          style={{ marginLeft: '-1em' }}
                        >
                          Add Manage Account
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head">
                      {/* <h4>Add Test Series</h4>
                      <p>Add Test Series information.</p> */}
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Email</label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              step="0.01"
                              value={values?.email}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Email"
                            />
                            {errors.email && touched.email ? (
                              <span style={{ color: "red" }}>
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Client Id
                            </label>
                            <input
                              type="text"
                              name="clientid"
                              id="clientid"
                              value={values.clientid}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Client Id"
                            />
                            {errors.clientid && touched.clientid ? (
                              <span style={{ color: "red" }}>
                                {errors.clientid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Client SecretId
                            </label>
                            <input
                              type="text"
                              name="clentsecret"
                              id="clentsecret"
                              value={values.clentsecret}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Client SecretId"
                            />
                            {errors.clentsecret && touched.clentsecret ? (
                              <span style={{ color: "red" }}>
                                {errors.clentsecret}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Redirect Url
                            </label>
                            <input
                              type="text"
                              name="redirecturl"
                              id="redirecturl"
                              value={values.redirecturl}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Redirect Url"
                            />
                            {errors.redirecturl && touched.redirecturl ? (
                              <span style={{ color: "red" }}>
                                {errors.redirecturl}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Server ClinetId
                            </label>
                            <input
                              type="text"
                              name="serverid"
                              id="serverid"
                              value={values.serverid}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Server ClinetId"
                            />
                            {errors.serverid && touched.serverid ? (
                              <span style={{ color: "red" }}>
                                {errors.serverid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Server SecretId
                            </label>
                            <input
                              type="text"
                              name="serversecret"
                              id="serversecret"
                              value={values.serversecret}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Server SecretId"
                            />
                            {errors.serversecret && touched.serversecret ? (
                              <span style={{ color: "red" }}>
                                {errors.serversecret}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Server Client Account</label>
                            <input
                              type="text"
                              name="server_client_account"
                              id="server_client_account"
                              // step="0.01"
                              value={values?.server_client_account}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Server Client Account"
                            />
                            {errors?.server_client_account && touched?.server_client_account ? (
                              <span style={{ color: "red" }}>
                                {errors?.server_client_account}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "10px" }}
                          >
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Status</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  onChange={getFieldProps("status").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      
                        <div className="update-profile"
                        id="submit-button"
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                           
                              <button type="submit" className="btn btn-primary"
                                disabled={submit}

                              >
                                Submit
                              </button>

                          

                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
}
