import React, { useContext, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { LoginImg, logo, NetIcon1, NetIcon2,ChahalLogo } from "../../../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import AuthContext from "../../../../context/AuthProvider";
import axios from "../../../../api/axios";
import { notification,  Space } from "antd";
import Select from "react-select";



const LOGIN_URL = "/api/student-signup";
const CENTERSLIST = "/api/public/academy_centers";


const initalValue = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  gender: "",
  dob: "",
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const loginSchema = Yup.object({
  name: Yup.string().required("Please enter your Name"),
  email: Yup.string().email().required("Please enter your Email"),
  password: Yup.string()
    .required("Please enter a Password")
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@$!%*?&)."
    ),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password Must Match"),
  phone: Yup.string().max(10)
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter your phone"),
  gender: Yup.string().required("Please select gender"),
  dob: Yup.date().min("1969-11-13", "Date is too early").max('2023-11-13', "Date is too further").required("Please Enter date of birth"),});
const StudentSingIn = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [centers, setCenters] = useState([]);

  const [centersId, setCentersId] = useState("");

  const openNotificationWithIcon = (type, text, col) => {
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: col }}>
                <strong>{text}</strong>
              </div>
            ),
          duration: 1
        });
  };
  useEffect(() => {
    const CentersList = async () => {
      try {
        const response = await axios.get(CENTERSLIST);
        setCenters(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    };

    CentersList();
  }, []);
  const {
    values,
    errors,
    touched,
    setFieldError,
    handleChange,
    handleSubmit,
    getFieldProps,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (val) => {
      // console.log(val);
      setLoading(true)
      try {
        const response = await axios.post(
          LOGIN_URL,
          {
            name: val.name,
            email: val.email,
            phone: val.phone,
            password: val.password,
            password_confirmation: val.confirmPassword,
            gender: val.gender,
            dob: val.dob,
            fk_center_id: centersId
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        // console.log(response?.data);
        if (response) {
          setTimeout(() => {
            navigate("/login");
          }, 1000);
          openNotificationWithIcon("success","Student Created","#52c41a");
        }
      } catch (err) {
        openNotificationWithIcon('error',err.response.data.message,'red');
        console.log(err);
        if (err.response.data.errors.email) {
          setFieldError("email", err.response.data.errors.email.toString());
        }
        if (err.response.data.errors.phone) {
          setFieldError("phone", err.response.data.errors.phone.toString());
        }
        if (err.response.data.errors.password) {
          setFieldError(
            "password",
            err.response.data.errors.password.toString()
          );
        }
      }
      finally {
        setLoading(false); 
      }
    },
    validationSchema: loginSchema,
  });
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };
  // const today = new Date();
  // const minDate = new Date(today.getFullYear() - 12, today.getMonth(), today.getDate());
  // const formattedMinDate = `${minDate.getFullYear()}-${(minDate.getMonth() + 1).toString().padStart(2, '0')}-${minDate.getDate().toString().padStart(2, '0')}`;
  const today = new Date();
today.setFullYear(today.getFullYear() - 12);

const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

const handelcenterchange=(value)=>{
  console.log(value)
setCentersId(value?.value)
}

  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme"
            >
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Chahal Academy 
                  </h2>
                  {/* <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p> */}
                </div>
              </div>
              {/* <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Stellarflux .
                  </h2>
                  <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Stellarflux .
                  </h2>
                  <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo">
                    <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                    <div className="back-home">
                      <Link to="/">Back to Home</Link>
                    </div>
                  </div>
                  <h1>Student Registration Form</h1>
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="form-control-label">Name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="off"
                        autoCorrect="off"
                        value={values.name}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your name"
                        required
                      />
                      {errors.name && touched.name ? (
                        <span style={{ color: "red" }}>{errors.name}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        autoCorrect="off"
                        value={values.email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your email address"
                        required
                      />
                      {errors.email && touched.email ? (
                        <span style={{ color: "red" }}>{errors.email}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Phone</label>
                      <input
                        type="phone"
                        name="phone"
                        id="phone"
                        autoCorrect="off"
                        autoComplete="off"
                        value={values.phone}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your phone"
                        required
                      />
                      {errors.phone && touched.phone ? (
                        <span style={{ color: "red" }}>{errors.phone}</span>
                      ) : null}
                    </div>
                    <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">Select Center</label>
                            <Select
  options={centers?.map(center => ({ value: center.id, label: center.name }))}
  placeholder="Select Center"
  onChange={handelcenterchange}
  style={{width:"100%"}}
/>


                            {errors.fk_center_id && touched.fk_center_id ? <span style={{ color: "red" }}>{errors.fk_center_id}</span> : null}
                          </div>
                        </div>
                    <div className="form-group">
                      <label className="form-control-label">
                        Date Of Birth
                      </label>
                      <input
  type="date"
  name="dob"
  id="dob"
  autoCorrect="off"
  autoComplete="off"
  value={values.dob}
  onChange={handleChange}
  max={maxDate}
  className="form-control"
  placeholder="Enter your date of birth"
  required
/> 
                      {errors.dob && touched.dob ? ( 
                        <span style={{ color: "red" }}>{errors.dob}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label
                        className="radio control-label"
                        style={
                          {
                            // marginRight: "10px",
                            // marginTop: "-30px",
                          }
                        }
                      >
                        Gender :
                      </label>
                      <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="inlineRadio1"
                          onChange={getFieldProps("gender").onChange}
                          value="0"
                          // checked={values?.gender === "0"}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          Male
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="inlineRadio2"
                          onChange={getFieldProps("gender").onChange}
                          value="1"
                          // checked={values?.gender === "1"}
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Female
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="inlineRadio2"
                          onChange={getFieldProps("gender").onChange}
                          value="2"
                          // checked={values?.gender === "2"}
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Other
                        </label>
                      </div>
                      <br />
                      {errors.gender && touched.gender ? (
                        <span style={{ color: "red" }}>{errors.gender}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Password</label>
                      <div className="pass-group">
                        <div className="password-field-wrapper">
                          <input
                            type={passwordType}
                            onChange={handleChange}
                            value={values.password}
                            required
                            autoComplete="off"
                            autoCorrect="off"
                            name="password"
                            id="password"
                            className="form-control password-field"
                            placeholder="Password"
                          />
                          <span
                            className="toggle-password feather-eye"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <FeatherIcon icon="eye" />
                            ) : (
                              <FeatherIcon icon="eye-off" />
                            )}
                          </span>
                        </div>

                        {errors.password && touched.password ? (
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        ) : null}
                        {error ? (
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">
                        Confirm Password
                      </label>
                      <div className="pass-group">
                        <div className="password-field-wrapper">
                          <input
                            onChange={handleChange}
                            value={values.confirmPassword}
                            required
                            type={passwordType1}
                            autoComplete="off"
                            name="confirmPassword"
                            id="confirmPassword"
                            className="form-control"
                            placeholder="Password"
                          />

                          <span
                            className="toggle-password feather-eye"
                            onClick={togglePassword1}
                          >
                            {passwordType1 === "password" ? (
                              <FeatherIcon icon="eye" />
                            ) : (
                              <FeatherIcon icon="eye-off" />
                            )}
                          </span>
                        </div>

                        {errors.confirmPassword && touched.confirmPassword ? (
                          <span style={{ color: "red" }}>
                            {errors.confirmPassword}
                          </span>
                        ) : null}
                        {error ? (
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {error ? <div style={{ color: "red" }}>{error}</div> : null}
                    <div className="forgot">
                      <span>
                        <Link className="forgot-link" to="/forgot-password">
                          Forgot Password ?
                        </Link>
                      </span>
                    </div>
                    {/* <div className="remember-me">
                      <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                        {" "}
                        Remember me
                        <input type="checkbox" name="radio" />
                        <span className="checkmark" />
                      </label>
                    </div> */}
                    <div className="d-grid">
                    <button
        className="btn btn-start"
        type="submit"
        onClick={handleSubmit}
        disabled={loading} // Disable the button when loading is true
      >
        {loading ? 'Loading...' : 'Register'}
      </button>                    </div>
                  </form>
                </div>
              </div>
              <div className="google-bg text-center">
                {/* <span>
                  <Link to="#">Or sign in with</Link>
                </span>
                <div className="sign-google">
                  <ul>
                    <li>
                      <Link to="#">
                        <img src={NetIcon1} className="img-fluid" alt="Logo" />{" "}
                        Sign In using Google
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={NetIcon2} className="img-fluid" alt="Logo" />
                        Sign In using Facebook
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <p className="mb-0">
                  Already have an account? <Link to="/login">Sign in.</Link>
                </p>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default StudentSingIn;
