import React, { useRef, useState } from "react";
// import { InstructorHeader } from "../../../instructor/header";
import Footer from "../../footer";
// import TextEditor from "../../../pages/course/addCourse/editor";
import { Link, useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Button from "react-bootstrap/Button";

import AdminInstructorSidebar from "../../instructor/adminSidebar";
import axios from "../../../api/axios";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
const ADDTESTSERIES = "/api/add-batches-to-batchdeatils";
import AdminHeader from "../../instructor/adminHeader";
import Form from "react-bootstrap/Form";

import * as FileSaver from "file-saver";

const LISTSTUDENT = "/api/student-batcheslink";
const EXPORT = "/api/download/user-batch/excel/sample";
const Import = "/api/import/batchUser";
const MULTIUSER = "/api/add/exiting/batchUsers";
import { notification, Modal } from "antd";
import moment from "moment";
import PaginationStudents from "../../instructor/addBatchStudents/Pagination";

const { confirm } = Modal;

export default function AdminBatchLink() {
  const [loading, setLoading] = useState(false);
  const [batcheDetails, setbatcheDetails] = useState([]);
  const [data, setdata] = useState(null);
  const [userid, setuserid] = useState("");
  const [checked, setChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [error, seterror] = useState("");
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { slug, id } = useParams();

  const navigate = useNavigate();
  const [batchData, setBatchData] = useState([]);
  const [showImport, setShowImport] = useState(false);
  const [text, settext] = useState("");
  const [message, setmessage] = useState("No Data");
  const [excel, setExcel] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [paid, setpaid] = useState("");
  const [file, setFile] = useState(null);
  const [last_page, setLast_page] = useState(null);
  const inputFile = useRef(null);

  const fetchStudentList = async () => {
    try{
      setLoading(true);
      const response = await axios.get(`${LISTSTUDENT}/${slug}`, config);
      setbatcheDetails(response?.data?.batcheDetails);
      setLast_page(response?.data?.user?.last_page);
      setuserid(response?.data?.batche?.id);
      // const res = response.data.user?.filter((e) => {
      //   const isMatching = response?.data?.batcheDetails?.some(
      //     (s) => e?.fk_student_id == s?.id
      //   );
      // console.log(isMatching,"ismatching")
      //   return !isMatching;
      // });
      const filteredArray = response?.data?.user.filter((first) => {
        // console.log(first,"first")
        return  !response?.data?.batcheDetails.some((second) => first?.id.toString() == second?.fk_student_id);
      });
  
  
      // console.log(filteredArray,"res")
      setBatchData(filteredArray);
      setdata(filteredArray);
      setLoading(false);
    }
    catch(error){
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/login')
      }
      openNotificationWithIcon2("error")
      setLoading(false);
    }
    
 
  };
  useEffect(() => {
    fetchStudentList();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  let visiblebatchData = batchData?.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );
  // console.log(batcheDetails,"batchdetails")

  const Filter = async (e) => {
    e.preventDefault();
    const searchValue = e.target.value;
    settext(searchValue);
    const response = await axios.post(
      "/api/user-batch-search",
      { search: searchValue,
      slug:slug },
      config
    );
    // console.log(response,"serach response")
    if (response?.data?.users?.data?.length > 0) {
      const res = response?.data?.users?.data?.filter(
        (e) => !batcheDetails?.some((s) => e?.id == +s?.fk_student_id)
      );
      setBatchData(res);
      setCurrentPage(response.data.users.current_page);
      setLast_page(response.data.users.last_page);
      setmessage('No Data')
    }
   else if (response?.data?.users?.data?.length == 0) {
    // console.log("krishna length")

      setBatchData(response?.data?.users?.data);
      setmessage('No Data Found')
    }

   else if (searchValue.length == 0) {
      // console.log("krishna search is 0")
      // const response = await axios.get(
      //   `${LISTSTUDENT}/${slug}?page=${currentPage}`,
      //   config
      // );
      // setLast_page(response?.data?.user?.last_page);
      // const res = response.data.user?.data.filter(
      //   (e) => response?.data?.batcheDetails?.some((s) => e.id == s.fk_student_id)
      // );
      // setCurrentPage(1);
      // setBatchData(res);
      fetchStudentList();
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };

  const handleChange = (id, check) => {
    if (check == true) {
      handleShowForm();
    }
    const arr = [...checked];
    if (!arr.includes(id)) {
      setChecked([...arr, id]);
    } else {
      const res = arr.filter((e) => e !== id);
      setChecked(res);
    }
  };
  const handleSubmit = async (e) => {
    checked.length === 0
      ? openNotificationWithIconImport("error", "Select Batch")
      : "";
    e.preventDefault();
    try {
      const response = await axios.post(
        ADDTESTSERIES,
        {
          student_ids: checked,
          batch_id: userid,
        },
        config
      );
      if (response) {
        setTimeout(() => {
          navigate(-1);
        }, 1000);
        // toast.success("Link Created");
        openNotificationWithIconImport("success", "Link Created");
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon2("error");
    }
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setChecked(batchData.map((li) => li.id));
    if (isCheckAll) {
      setChecked([]);
    }
  };

  // useEffect(()=>{
  //   setBatchData(visiblebatchData)
  // })
  const batchDataLength = batchData?.length ? batchData?.length : 0;
  const numOFTotalPage = Math.ceil(batchDataLength / 10);

  const itemsPerPage = 10;
  const config1 = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "blob" },
    responseType: "arraybuffer",
  };

  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(slug),
    "my-secret-key@admin8623009959"
  ).toString();
  let ciphertexts = encodeURIComponent(ciphertext);

  const openNotificationWithIconImport = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const exportCsv = async (excel) => {
    try {
      const response = await axios.get(
        "/api/download/user-batch/excel/sample",
        config1
      );
      const fileExtension = ".xlsx";
      const data = new Blob([response?.data], {
        type: "application/vnd.ms-excel",
      });
      FileSaver.saveAs(data, "Student" + fileExtension);
    } catch (error) {
      console.log(error);
      openNotificationWithIconImport("error", error?.response?.data?.error, "red");

    }
  };
  const handleShowImport = () => {
    setShowImport(true);
    setIsModalVisible(true);
  };
  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const handleCloseImoprt = () => {
    setShowImport(false);
    setFile(null);
  };
  const handleAddUser = async () => {
    try {
      const response = await axios.post(
        MULTIUSER,
        {
          batch_id: decryptedData,
          // paid_amount: paid,
          // exp_date: moment(fromDate).format("YYYY-MM-DD"),
          users: checked,
        },
        config
      );
      if (response) {
        setIsModalVisibleForm(false);
        openNotificationWithIconImport(
          "success",
          response.data.success,
          "#52c41a"
        );
        navigate(`/admin/batchListStudents/${slug}/${ciphertexts}`);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      if (error.response.status == 403) {
        openNotificationWithIconImport(
          "error",
          error.response.data?.message,
          "red"
        );
      }
      else if (error.response.status == 422) {
        openNotificationWithIconImport(
          "error",
          error.response.data?.message,
          "red"
        );
      } 
      else {
        openNotificationWithIconImport(
          "error",
          error.response.data?.message,
          "red"
        );
        openNotificationWithIconImport(
          "error",
          "Please Refresh the Page",
          "red"
        );
      }
      
    }
  };
  const handleDelteImoprt = async () => {
    seterror("");
    const formData = new FormData();
    formData.append("batch_id", userid);
    formData.append("user_import", file);

    try {
      const response = await axios.post(Import, formData, config);
      openNotificationWithIconImport("success", "Student Created", "#52c41a");
      fetchStudentList();
      if (response) {
        setShowImport(false);
        setIsModalVisible(false);
        if (inputFile.current) {
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        }
        navigate(`/admin/batchListStudents/${slug}/${ciphertexts}`);
      }
    } catch (error) {
      console.log(error);
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
     else if (error?.response?.status === 422) {
        openNotificationWithIconImport(
          "error",
          error.response.data.message,
          "red"
        );
      } else if (error.response.status === 403) {
        openNotificationWithIconImport(
          "error",
          error.response.data.error,
          "red"
        );
      } else {
        openNotificationWithIconImport("error", error?.response?.data?.error, "red");
        openNotificationWithIconImport("error", "Please Refresh the Page", "red");

      }

      seterror(error.response.data.message);
      
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };

  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  // console.log(checked,"checked")
  const renderPagination = () => {
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(batchDataLength / itemsPerPage);

    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()].map(pageNum => (
        <li
          key={pageNum}
          className={currentPage === pageNum + 1 ? "page-item active" : "page-item"}
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i + 1 ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        pages.push(
          <li
            key={totalPageCount - 1}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li
            key={0}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i + 1 ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li
            key={0}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(<li key="ellipsis2" className="page-item disabled"><span className="page-link">...</span></li>);
        pages.push(
          <li
            key={totalPageCount - 1}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Batches"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3 style={{ textTransform: "capitalize" }}>
                            Add Students
                          </h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "-1.6em",
                            }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item"
                                style={{ cursor: "pointer" }}
                              >
                                <a onClick={() => navigate("/admin/batchList")}>
                                  Batches
                                </a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                style={{ cursor: "pointer" }}
                              >
                                <a>Add Students</a>
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="comman-space pb-0">
                          <div>
                            
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form>
                                  <div className="row  gx-2 align-items-center">
                                    <div className="col-md-4 col-item">
                                      <div className=" search-group justify-content-start">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          // allowclear
                                          onChange={Filter}
                                          onKeyDown={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-8 col-lg-8 col-item">
                                      <div
                                        className="button-container"
                                        style={{
                                          justifyContent: "flex-end",
                                          gap: "5px",
                                        }}
                                      >
                                        <button
                                          onClick={(e) => {
                                            exportCsv(excel);
                                            e.preventDefault();
                                          }}
                                          className="custom-button-total"
                                        >
                                          Export Sample
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleShowImport();
                                          }}
                                          className="custom-button-free"
                                        >
                                          Import Excel
                                        </button>
                                        <Button
                                          onClick={(e) => {
                                            navigate(
                                              `/admin/batcheslink/add-user/${slug}/${encodeURIComponent(
                                                id
                                              )}`
                                            );
                                          }}
                                          className="btn custom-button"
                                          // style={{ paddingLeft: "5px", paddingRight: "5px", width: '18%', marginLeft: '.1em' }}
                                        >
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New{" "}
                                        </Button>
                                        <Button
                                          onClick={(e) => {
                                            // navigate(
                                            //   `/admin/batcheslink/add-user/${slug}/${encodeURIComponent(
                                            //     id
                                            //   )}`
                                            // );
                                            handleAddUser()
                                          }}
                                          className="btn custom-button btn-dark"
                                          // style={{ paddingLeft: "5px", paddingRight: "5px", width: '18%', marginLeft: '.1em' }}
                                        >
                                          {/* <i className="fa-solid fa-plus"></i>{" "} */}
                                          Submit{" "}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                            {/* <Modal show={showImport} onHide={handleCloseImoprt}>
                              <Modal.Header closeButton>
                                <Modal.Title> Import File </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Upload File</Form.Label>
                                    <Form.Control
                                      type="file"
                                      autoFocus
                                      onChange={(e) =>
                                        setFile(e.target.files[0])
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                  ></Form.Group>
                                </Form>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseImoprt}
                                >
                                  Closess
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleDelteImoprt}
                                >
                                  Import
                                </Button>
                              </Modal.Footer>
                            </Modal> */}
                            {/* import student */}
                            <Modal
                              title="Import Student "
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisible}
                              okText="Import"
                              onOk={() => {
                                handleDelteImoprt();
                              }}
                              onCancel={() => {
                                setIsModalVisible(false);
                                seterror("");
                              }}
                            >
                              <div className="mb-3">
                                <label for="formFile" className="form-label">
                                  only support excel file{" "}
                                </label>
                                <input
                                  className="form-control"
                                  autoFocus
                                  onChange={(e) => setFile(e.target.files[0])}
                                  ref={inputFile}
                                  type="file"
                                  id="formFile"
                                />
                                {error && (
                                  <span style={{ color: "red" }}>{error}</span>
                                )}
                              </div>
                            </Modal>
                            {/* user batch */}
                            {/* <Modal
                              title="Add Student"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              visible={isModalVisibleForm}
                              okText="Create"
                              onOk={() => {
                                handleAddUser();
                              }}
                              onCancel={() => {
                                setIsModalVisibleForm(false);
                              }}
                            >
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Paid Amount</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={paid}
                                    onChange={(e) => setpaid(e.target.value)}
                                    placeholder="enter paid amount"
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Expiry Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    value={
                                      fromDate.getFullYear().toString() +
                                      "-" +
                                      (fromDate.getMonth() + 1)
                                        .toString()
                                        .padStart(2, 0) +
                                      "-" +
                                      fromDate
                                        .getDate()
                                        .toString()
                                        .padStart(2, 0)
                                    }
                                    onChange={(e) => {
                                      setFromDate(new Date(e.target.value));
                                    }}
                                    placeholder="enter expiry date"
                                  />
                                </Form.Group>
                              </Form>
                            </Modal> */}
                            <table
                              className="table table-nowrap mt-2"
                              // style={{ tableLayout: "fixed", width: "1000px" }}
                            >
                              <thead>
                                <tr className="text-center">
                                  <th>
                                    {" "}
                                    <input
                                      className="form-check-input"
                                      style={{ marginRight: "-5px" }}
                                      onClick={(e) => handleSelectAll(e)}
                                      type="checkbox"
                                    />{" "}
                                  </th>
                                  <th>NAME</th>
                                  <th>EMAIL</th>
                                  <th>PHONE</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                               <tbody   className="d-flex justify-content-center align-items-center"
                               style={{
                                 position: "fixed",
                                 top: 0,
                                 right: 0,
                                 bottom: 0,
                                 left: 0,
                                 backgroundColor: "rgba(255, 255, 255, 0.6)",
                               }}>
                                 <tr>
                                   <td>
                                     <>
                                     <div
                                       className="spinner-border text-warning"
                                       role="status"
                                     >
                                       <span className="sr-only">
                                         Loading...
                                       </span>
                                     </div>
                                     </>
                                   </td>
                                 </tr>
                               </tbody>
                              ) : (
                                visiblebatchData?.map((item, i, row) => {
                                  const sNo =
                                    (currentPage - 1) * itemsPerPage + i + 1;
                                  const res = checked?.filter(
                                    (e) => e == item.id
                                  );
                                  return (
                                    <tbody key={item.id}>
                                      <tr className="text-center">
                                        <td className="text-center">
                                          <div className="form-check form-check-inline">
                                            {res.map((e,index) => {
                                              if (e) {
                                                return (
                                                  <input
                                                    checked
                                                    className="form-check-input"
                                                    style={{
                                                      marginLeft: "0px",
                                                    }}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                      handleChange(
                                                        item.id,
                                                        e.target.checked
                                                      );
                                                    }}
                                                    id="inlineCheckbox1"
                                                    key={item?.id}
                                                  />
                                                );
                                              } else {
                                                return (
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    style={{
                                                      marginLeft: "0px",
                                                    }}
                                                    onChange={(e) => {
                                                      handleChange(
                                                        item.id,
                                                        e.target.checked
                                                      );
                                                    }}
                                                    key={item?.id}
                                                    id="inlineCheckbox1"
                                                  />
                                                );
                                              }
                                            })}
                                            {res.length == 0 ? (
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                style={{ marginLeft: "0px" }}
                                                onChange={(e) => {
                                                  handleChange(
                                                    item.id,
                                                    e.target.checked
                                                  );
                                                }}
                                                key={item?.id}
                                                id="inlineCheckbox1"
                                              />
                                            ) : null}
                                          </div>
                                        </td>
                                        <td>{item?.name}</td>
                                        <td>{item?.email}</td>
                                        <td>{item?.phone}</td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}
                            </table>
                            </div>

                           
                            {batchData?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                        </div>
                        <div className="fixtable">
                          {batchData?.length <= 0 ? (
                            ""
                          ) : (
                            <PaginationStudents
                              nPages={numOFTotalPage}
                              text={text}
                              linkSearch={"/api/user-search"}
                              currentPage={currentPage}
                              batcheDetails={batcheDetails}
                              setCurrentPage={setCurrentPage}
                              link={LISTSTUDENT}
                              setData={setBatchData}
                              slug={slug}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </div>
  );
}
