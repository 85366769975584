import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import { Collapse } from "react-bootstrap";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../api/axios";
import AdminHeader from "../../instructor/adminHeader";
import { event, type } from "jquery";
import { notification, Space } from "antd";

const GETINSTALLMENTS = '/api/course-installment'
const SUBCRIPTION = "/api/std-courses-initiate";
const GETSUBSCRIPTION = '/api/get-Subscription-installment'
const PAYEDINSTALMENTS = "/api/course-installment-paid"
export default function AdminBuyCourse() {
    const { id, batch_id } = useParams();
    const [list, setList] = useState(null)
    const [loading, setLoading] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [paydate,setpaydate]=useState('')
    const [courseId, setCourseId] = useState(null)
    const [amount, setAmmount] = useState("")
    const [selectedDate, setSelectedDate] = useState('');
    const [courseName, setCourseName] = useState("")
    const [error, setError] = useState('')
    const [courseEroor, setCourseError] = useState("")
    const [amountEroor, setAmountError] = useState("")
    const [dateEroor, setDateError] = useState("")
    const [selectedCourse, setSelectedCourse] = useState("");


    const token = localStorage.getItem("token");
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    const [openIndex, setOpenIndex] = useState(null);
    const [referalId, setReferalId] = useState("")

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const navigate = useNavigate();
    const fetchCourse = async () => {
        try {
            setLoading(true)
            const res = await axios.get(`${GETINSTALLMENTS}/${id}/${batch_id}`, config)

            setList(res?.data?.course)
            setLoading(false)
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                openNotificationWithIconSesion("error")
                localStorage.removeItem('token')
                navigate('/admin/login')
            }
            else{
                openNotificationWithIcon2("error","Internal Server Error")
            }

        }
    }

    useEffect(() => {
        fetchCourse()
    }, [])
    const options = list?.map((item) => (
        {
            value: item?.id,
            label: item?.name,
            amount: item?.amount
        }
    ));
    const handleCourseChange = (selectedOption) => {
        setCourseId(selectedOption?.value);

        setCourseName(selectedOption?.label)
    };

    const currentDate = new Date().toISOString().split('T')[0];

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };
    const handlePayDate=(e)=>{
        setpaydate(e.target.value)
    }
    const [api, contextHolder] = notification.useNotification();

    const [toast, setToast] = useState(false)
    const openNotificationWithIcon = (type) => {
        notification[type]
            (

                {
                    message:
                        (
                            <div style={{ color: "#52c41a" }}>
                                <strong>Installment Payed</strong>
                            </div>
                        ),
                    duration: 1

                    // description:
                    // (<div style={{color:"green"}}>
                    // Your Batch has Sucessfully Created

                    // </div>)

                });






    };
    const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };
    // console.log(selectedCourse,"course")
    const handleSubmmit = async (event) => {
        event.preventDefault();
        setSubmit(true)
        try {

            const formData = new FormData();
            formData.append("courseId", selectedCourse)
            formData.append("amount", amount)
            formData.append("userId", id)
            formData.append("date", selectedDate)
            // formData.append("key", batch_id)
            formData.append("payment_date", paydate)


            if (amount == "") {
                return setAmountError("Enter The Amount")
            }

            if (selectedDate == "") {
                return setDateError("Select The Expiry Date")
            }
            if(paydate==''){
                setError("Select The Payment Date")
            }
            const responce = await axios.post(
                PAYEDINSTALMENTS,
                formData,
                config,
            );
            if (responce) {
                openNotificationWithIcon("success")
                navigate(-1)
            }

        }
        catch (error) {
            console.log(error)
            setSubmit(false)
            if (resonse?.status == 401) {
                openNotificationWithIconSesion("error")
                localStorage.removeItem("token");
                navigate("/admin/login")
        
              }
          else{  
            openNotificationWithIcon2("error",error?.response?.data?.message)
        }
    }

    };
    const style = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "white",
            width: "100%",
            height: "40px",
            color: "black",
            minHeight: "40px",
            border: "1px solid #e9ecef",
            paddingLeft: "5px",
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: state.isSelected ? "0" : "10px",
            fontSize: "14px",
            "&:hover": {
                cursor: "pointer",
            },
            outline: "none",
        }),
        menu: (base) => ({ ...base, marginTop: "0px" }),
        menuList: (base) => ({ ...base, padding: "0" }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#FFDEDA" : "white",
            color: "black",
            fontSize: "14px",
            "&:hover": {
                backgroundColor: "#FFDEDA",
                // #dddddd
            },
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: "black",
            transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
            transition: "250ms",
        }),
    };
    const PaymentSelect = () => {
        const paymentoptions = [{ name: "Cash" }, { name: "Online" }];
        const optionsvalue = paymentoptions.map((item) => ({
            value: item.name,
            label: item.name,
        }));

        return optionsvalue;
    };



    useEffect(() => {
        const lastVisitedRoute = localStorage.getItem("lastVisitedRoute")
        if (lastVisitedRoute) {
            localStorage.removeItem("lastVisitedRoute")
        }
        else {
            localStorage.setItem("lastVisitedRoute", window.location.pathname);

        }
    }, []);
    const openNotificationWithIcon2 = (type,text) => {

        notification[type]
            (

                {
                    message:
                        (
                            <div style={{ color: "red" }}>
                                <strong>{text}</strong>
                            </div>
                        ),

                    // description:
                    // (<div style={{color:"green"}}>
                    // Your Batch has Sucessfully Deleted

                    // </div>)
                    duration: 1
                });
    };
    // const windowTop = (arg1, arg2) => {
    //     window.scrollTo(arg1, arg2);
    //   };
      
    //     useEffect(() => {
    //       windowTop(0, 0);
    //     }, []);
    function getMinDate() {
        const today = new Date();
        const lastDate = new Date(today);
        lastDate.setDate(today.getDate() - 30); // Set it to the last day
    
        const minDate = lastDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
        return minDate;
    }
    
    function getMaxDate() {
        const today = new Date();
        const maxDate = today.toISOString().split('T')[0]; // Format to YYYY-MM-DD
        return maxDate;
    }
    const handelcourses = async (e) => {
        const list1 = list?.map(({ label, value }) => ({ name: label, id: value }));
        // console.log(list1);
        setList(list1);
      };
    return (
        <div className="main-wrapper">
            <AdminHeader />
            {/* Student Dashboard */}
            <div className="page-content">
                <div className="container"
                    style={{ minWidth: "100%", marginTop: "-15px" }}
                >
                    <div className="row">
                        {/* Sidebar */}
                        <AdminInstructorSidebar activeMenu={"Batches"} />
                        {/* Sidebar */}

                        {/* Profile Details */}
                        <div className="col-xl-9 col-md-8 main-page">
                            <div className="settings-widget profile-details">
                                <div className="settings-menu p-0">
                                    <div className="profile-heading">
                                        <h3>Course Installments</h3>
                                        <nav aria-label="breadcrumb" >
                                            <ol class="breadcrumb" style={{ marginBottom: 0.5 }}>
                                                <li
                                                    class="breadcrumb-item "
                                                    onClick={() => navigate("/admin/batchList")}
                                                    // aria-current="page"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Batches
                                                </li>
                                                <li
                                                    class="breadcrumb-item"
                                                    aria-current="page"
                                                    style={{
                                                        cursor: "pointer",
                                                        // marginLeft: "-.6em",
                                                        fontWeight: 'normal'
                                                    }}
                                                    onClick={() => navigate(-1)}
                                                >
                                                    All Students
                                                </li>
                                                <li
                                                    class="breadcrumb-item active"
                                                    aria-current="page"
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: "-.7em",
                                                        fontWeight: 'normal'
                                                    }}
                                                >
                                                    Course Installments
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>

                                    <div className="checkout-form personal-address add-course-info ">
                                        {loading === true ? <div
                                            class="d-flex justify-content-center"
                                            style={{ position: "absolute" }}
                                        >
                                            <div
                                                class="spinner-border text-warning"
                                                style={{
                                                    left: "50%",
                                                    marginLeft: "24em",
                                                    marginTop: "10em",
                                                }}
                                                role="status"
                                            >
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div> : <form>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                

                                                        <div className="form-group">
        <label className="form-label">Course Name</label>
        <select
          className="form-control"
          id="name"
          name="name"
          value={selectedCourse}
          onChange={(e) => setSelectedCourse(e.target.value)}
        >
          <option value="">Select a course</option>
          {list?.map((course) => (
            <option key={course.id} value={course?.id}>
              {course.name}
            </option>
          ))}
        </select>
        {!list && (
          <div className="text-danger">Please select a course.</div>
        )}
      </div>
     
   
                                                      
                                                   
                                                      

     
    {/* </div> */}
                                                    {/* <h3>{userChoice?.label}</h3> */}


                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Enter Amount
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter Amount"
                                                            id="name"
                                                            name="name"
                                                            onChange={(event) => {
                                                                setAmmount(event.target.value)
                                                            }}
                                                            value={amount}
                                                        />

                                                        {amount == "" ? <span style={{ color: 'red' }}>{amountEroor}</span> : ""}

                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Expiry Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Payment Date"
                                                            id="dob"
                                                            name="dob"
                                                            onChange={handleDateChange}
                                                            value={selectedDate}
                                                            min={currentDate}
                                                        />
   
                                                        {selectedDate == "" ? <span style={{ color: 'red' }}>{dateEroor}</span> : ""}

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Payment Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Payment Date"
                                                            id="dob"
                                                            name="dob"
                                                            onChange={handlePayDate}
                                                            value={paydate}
                                                            min={getMinDate()}
                                                            max={getMaxDate()}
                                                            // min={currentDate}
                                                        />
   
                                                        {paydate == "" ? <span style={{ color: 'red' }}>{error}</span> : ""}

                                                    </div>
                                                </div>

                                                <div className="update-profile"
                                                    id="submit-button"

                                                >
                                                    <div
                                                        class="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >

                                                        <button onClick={(e) => handleSubmmit(e)} type="submit" 
                                                        disabled={submit}
                                                        className="btn btn-primary"
                                                        >
                                                            Submit
                                                        </button>


                                                    </div>{" "}
                                                    <div
                                                        class="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                navigate(-1);
                                                            }}
                                                            className="btn btn-primary cancel-btn "
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </form>}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Profile Details */}
                    </div>
                </div>
            </div>
            {/* Student Dashboard */}
            {/* <ToastContainer /> */}
            <Footer />
        </div>
    );
}
