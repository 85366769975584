import React, { useContext, useEffect, useState } from "react";
import { InstructorHeader } from "../header";
import Footer from "../../footer";
import {
  InstructorProfileBg,
  ReferImage1,
  ReferImage2,
  ReferImage3,
  ReferImage4,
  ReferImage5,
  User15,
} from "../../imagepath";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import FeatherIcon from "feather-icons-react";
import AdminHeader from "../adminHeader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CryptoJS from "crypto-js";
import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import { Search } from "react-feather";
import "../adminTestSeries/index.css"
import * as Yup from "yup";
import axios from "../../../api/axios";
import { AddAdminRecordedVideos } from '../addAdminRecordedVideos/index';
import { Tooltip } from "antd";
import TextEditor from "../../pages/course/addCourse/editor";
import { event } from "jquery";
import { useFormik } from "formik";
import { notification, Space  } from "antd";

const EDITINSTITUTE="/api/Institute-Center/edit"
const UPDATEINSTITUTECENTER="/api/Institute-Center/update"
export default function AdminEditInstituteList() {
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const[submit,setSubmit]=useState(false)

  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const fetchTestEdit = async () => {
    try{
      setLoading(true);
      const response = await axios.get(
        `${EDITINSTITUTE}/${decryptedData}`,
        config
      );
      // console?.log(response?.data?.res)
      setData(response?.data?.res);

      setLoading(false);
    }
    catch(error){
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else{
        openNotificationWithIcon2("Internal Server Error","error")
      }
    }
   
  };
  useEffect(() => {
    fetchTestEdit();
  }, []);
  const initalValue = {
    name: data?.Name?data?.Name:"",
    status: data?.status,
    email:data?.email?data?.email:"",
    phoneno:data?.phone?data?.phone:"",
    pin:data?.pin?data?.pin:"",
  };
  const studentSchema = Yup.object({
    name: Yup.string().required("Please enter your Institute Center Name"),
    email: Yup.string().required("Please enter your Institute Center Email"),
    phoneno: Yup.string().required("Please enter your Institute Center Phone No"),
    pin: Yup.string().required("Please enter your Institute Center Pin Code"),

    status: Yup.string().required("Please enter your Status"),
  });
  const navigate = useNavigate();
  const[toast,setToast]=useState(false)
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"#52c41a"}}>
         <strong>Institute Center Updated</strong> 
        </div>
      ),
      duration:1

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Created
  
      // </div>)
      
    });
  
    
  
       
     
     
    };
    const openNotificationWithIconSesion = (type) => {
      notification[type]({
        message: (
          <div style={{ color: "red" }}>
            <strong>Session Expired Please Login </strong>
          </div>
        ),
        duration: 1,
      });
    };
  const { errors, values, touched, handleChange, handleSubmit, getFieldProps } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (value) => {
        setSubmit(true)
        try {
          const response = await axios.post(
            UPDATEINSTITUTECENTER,
            {
              "name": value?.name,
              status: value?.status,
              "email": value?.email,
              "phone": value?.phoneno,
              "pin": value?.pin,


              "id": decryptedData,
            },
            config
          );
          if (response) {
            openNotificationWithIcon("success")

            setTimeout(() => {
              // navigate("/admin/test-series");
              setToast(true)

              navigate(-1);
            }, 1000);
            toast.info("Batch Updated");
          }
        } catch (error) {
          console.log(error?.response?.data?.message);
          setSubmit(false)
          setToast(false)
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem('token')
            navigate('/admin/login')
          }
          else{
          openNotificationWithIcon2(error?.response?.data?.message,"error")
        }
      }
      },
      enableReinitialize: true,
      validationSchema: studentSchema,
    });
    useEffect(() => {
      const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
      if(lastVisitedRoute){
        localStorage.removeItem("lastVisitedRoute")
      }
      else{
        localStorage.setItem("lastVisitedRoute", window.location.pathname);
  
      }
    }, []);
    const openNotificationWithIcon2 = (text,type) => {
      // console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>{text}</strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      // const windowTop = (arg1, arg2) => {
      //   window.scrollTo(arg1, arg2);
      // };
      
      //   useEffect(() => {
      //     windowTop(0, 0);
      //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader/>
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"invoice"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Institute Center</h3>
                    {/* <p>Edit Test Series information.</p> */}
                    <nav aria-label="breadcrumb" style={{marginTop:'0.5em'}} >
                      <ol className="breadcrumb" style={{marginBottom:0.5,cursor:"pointer"}}>
                        <li className="breadcrumb-item" onClick={()=>navigate('/admin/batchList')} aria-current="page">
                         Institute
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                      Edit Institute Center
                        </li>
                      </ol>
                    </nav>
                  </div>
<div >
<div className="checkout-form personal-address add-course-info "
                  style={{backgroundColor:"white",padding:"10px"}}
                  >
                    <div className="personal-info-head"
                    style={{padding:"20px"}}
                    >
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row" >
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Institute Center 
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={values?.name}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter your Institute Center Name"
                              />
                              {errors?.name && touched?.name ? (
                                <span style={{ color: "red" }}>
                                  {errors?.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Email"
                              id="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            {errors?.email && touched?.email ? (
                              <span style={{ color: "red" }}>
                                {errors?.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Phone No
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Phone No"
                              id="phoneno"
                              name="phoneno"
                              value={values?.phoneno}
                              onChange={handleChange}
                            />
                            {errors?.phoneno && touched?.phoneno ? (
                              <span style={{ color: "red" }}>
                                {errors?.phoneno}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Pincode
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Pincode"
                              id="pin"
                              name="pin"
                              value={values?.pin}
                              onChange={handleChange}
                            />
                            {errors?.pin && touched?.pin ? (
                              <span style={{ color: "red" }}>
                                {errors?.pin}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label
                                className="radio control-label"
                                style={
                                  {
                                    // marginRight: "10px",
                                    // marginTop: "-30px",
                                  }
                                }
                              >
                                Status :
                              </label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                  checked={values?.status === "1"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Active
                                </label>
                              </div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="update-profile"
                              id="submit-button"

                          >
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >

      <button type="submit" className="btn btn-primary"
                              disabled={submit}
                             
                              >
                                Update
                              </button>
        
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(-1);
                                }}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                    </div>
                  </div>
</div>
                 
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
}
