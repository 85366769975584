import React, { useEffect, useState } from "react";
import StudentHeader from "../studentHeader";
import Footer from "../../footer";
import {
  Icon2,
  User1,
  User11,
  User12,
  User13,
  User14,
  User2,
  User3,
  User4,
  User5,
  User6,
} from "../../imagepath";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./index.css"
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
const VIDEO = "/api/student/videos/submodule";
import { notification, Space } from "antd";
import FooterStudent from "../../fotterstudent";
import axiosInstance from "../../../api/axios1";

export default function StudentVideoSub() {
  const { slug1, slug2 } = useParams();
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false)
  const [name,SetName]=useState("")
  const navigate = useNavigate();
  const tokenStudent = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const getVideo = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(`${VIDEO}/${slug1}/${slug2}`, config);
      // console.log(response?.data?.data,"response")
      if(response.data ==''){
        setLoading(false)
      }
      // let result = [];
      // for (const item of response.data.submodules) {
      //   // console.log(item);
      //   const submodule = [];

      //   submodule.push({ name: item.name, slug: item.slug, id: item.id });
      //   let obj = {};
      //   let videos = [];
      //   for (const videoRelation of item.videorelation) {
      //     for (const Videos of videoRelation.videos) {
      //       videos.push(Videos);
      //     }
      //   }
      //   const unique = [];
      //   videos.map(x => unique.filter(a => a.thumbnail == x.thumbnail && a.title == x.title).length > 0 ? null : unique.push(x));
      //   delete item.videorelation;
      //   obj["videos"] = unique;
      //   obj["submodule"] = item;

      //   result.push(obj);
      // }
     SetName(response?.data?.name)
 const data=response?.data?.data?.filter((item)=>{
  return item?.videos?.length>0
 })
//  console.log(data,"data")
      setList(data);
      setLoading(false)
      // console.log(result);
    }
    catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('tokenStudent')
        navigate('/login')
      }
      else{
        openNotificationWithIcon2("error")
      
      }
      setLoading(false)

    }
   

  };
  // console.log(list,"result")

  useEffect(() => {
    getVideo();
  }, []);
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  
  
  const handleId = (id) => {
    // console.log(id);
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(id), 'my-secret-key@admin8623009959').toString();
    let ciphertexts = encodeURIComponent(ciphertext)
    navigate(`/student/videos/video-details/${ciphertexts}/${slug1.substring(0, slug1.length - 4)}`)
  }
  const handelVideoId = (id) => {
    // console.log(id);
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(id), 'my-secret-key@admin8623009959').toString();
    let ciphertexts = encodeURIComponent(ciphertext)
    navigate(`/student/view-video/${ciphertexts}`)
  }
  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
    useEffect(() => {
      const lastVisiteStudentRoute=localStorage.getItem("lastVisiteStudentRoute")
      if(lastVisiteStudentRoute){
        localStorage.removeItem("lastVisiteStudentRoute")
      }
      else{
        localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);
  
      }
    }, []);
    useEffect(()=>{
      const lastVisiteStudentTest=  localStorage.getItem("lastVisiteStudentTest")
    if(lastVisiteStudentTest!==window.location.pathname){
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
     
    }
  },[]);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  const formatDuration = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const secs = duration.seconds().toString().padStart(2, '0');
    return `${hours}hrs${""}${minutes}mins`;
  };
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"videos"} />
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container"
                  style={{minWidth:"98%",margin:"auto"}}

        >
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/student/videos">Videos</Link>
                    </li>
                    <li className="breadcrumb-item"
                     onClick={() => navigate(-1)}
                    >
                      <Link  >
                        {slug1.substring(0, slug1.length - 4)[0].toUpperCase() + slug1.substring(0, slug1.length - 4).slice(1).replace('-', ' ')}
                      </Link>{" "}
                    </li>
                    <li className="breadcrumb-item"
                    style={{textTransform:"capitalize"}}
                    >
                      <Link >
                        {name}
                      </Link>{" "}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container"
                  style={{minWidth:"98%",margin:"auto"}}

        >
          <div className="row">
            {loading === true ? <div
              style={{ display: 'block' }}
              id="cover-spin"
            >
            </div> :
             list?.length > 0 ? list?.map((item,index) => {
              return (
                <div className="col-lg-3 d-flex" key={index}>
                  {/* Filter */}
                  <div className="showing-list"
                  style={{marginBottom:"26px",marginTop:"5px"}}
                  >
                    <div className="row ">
                      <div className="col-lg-12">
                        <div className="d-flex align-items-center">
                          <div className="show-result">
                            <h3 style={{ textTransform: "capitalize" }}>{item?.submodule?.name}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Filter */}

                  <div className="row ">
                    {item?.videos?.map((it ,index) => {
                      return (
                        <div className="col-lg-12 col-md-12 d-flex " key={index}>
                          <div
                            className="student-box flex-fill shadow-lg"
                            style={{ background: "white" }}
                            
                          >
                            <div className="student-img "
                            onClick={()=>{
                              handelVideoId(it?.id)
                            }}
                            >
                              <Link>
                                <img
                                  className="img-fluid"
                                  id="img"
                                 
                                  // style={{ height: "200px", width: "270px" }}
                                  alt="Students Info"
                                  src={`${it?.thumbnail_path}/${it?.thumbnail}`}
                                />
                              </Link>
                            </div>
                            <div className="student-content pb-0"
                            
                            >
                                      <div  onClick={()=>{
                              handelVideoId(it?.id)
                            }} >

                                      <h5>
                                <Link >{it?.title}</Link>
                              </h5>
                                      </div>

                              <div className="d-flex justify-content-between">
                              <div className="loc-blk d-flex align-items-center justify-content-center">
                                <img src={Icon2} className="me-1" alt="" />
                                <p>
                                {formatDuration(it?.video_length)}
                                </p>
                              </div>
                               <div className="loc-blk d-flex align-items-center justify-content-center">
                                {/* <img src={Icon2} className="me-1" alt="" /> */}
                                <button 
                                 onClick={() => {
                                  handleId(it?.id);
                                     }}
                            type="button" className="btn btn-link">View More</button>
                              </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* pagination */}
                  {/* <div className="row">
           <div className="col-md-12">
             <ul className="pagination lms-page">
               <li className="page-item prev">
                 <Link
                   className="page-link"
                   to="#;"
                   tabindex="-1"
                 >
                   <i className="fas fa-angle-left"></i>
                 </Link>
               </li>
               <li className="page-item first-page active">
                 <Link className="page-link" to="#;">
                   1
                 </Link>
               </li>
               <li className="page-item">
                 <Link className="page-link" to="#;">
                   2
                 </Link>
               </li>
               <li className="page-item">
                 <Link className="page-link" to="#;">
                   3
                 </Link>
               </li>
               <li className="page-item">
                 <Link className="page-link" to="#;">
                   4
                 </Link>
               </li>
               <li className="page-item">
                 <Link className="page-link" to="#;">
                   5
                 </Link>
               </li>
               <li className="page-item next">
                 <Link className="page-link" to="#;">
                   <i className="fas fa-angle-right"></i>
                 </Link>
               </li>
             </ul>
           </div>
         </div> */}
                  {/* pagination */}
                </div>
              );
            }) : <h2 className="text-center">No Students Videos</h2>}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <FooterStudent />
    </div>
  );
}
