import React, { useState, useContext, useEffect } from "react";

import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { Input, Tooltip } from "antd";
import axios from "../../../api/axios";
import { notification } from "antd";

import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
const VIMEOAPI = "/api/admin/vimeo/dirs?page=";
import { Modal } from "antd";
import PaginationVimeo from "../../pages/course/addCourse/PaginationVimeo";
import Folder from "../../../assets/img/user/folder.png"
const VIMEOSUBMODUULE = "api/recorded-video/submodule-list";
const COURSEVIDEO = "api/admin/course/active";
const VIDEOMODULE = "api/recorded-video/module-list";
const VIMEOIMPORT = "api/admin/vimeo/import/category";
const SWITCHUSERS="api/admin/vimeo_accounts/switch"
const VIMEOSELECT="api/admin/vimeo_accounts/current"
const VIMEOUSERS="/api/admin/vimeo_Account"
const { confirm } = Modal;
import { Select } from "antd";
import { event } from "jquery";

export default function AddImportVimeo() {
  const { Option } = Select;
  const [list, setList] = useState(null);
  const [last_page, setLast_page] = useState("");
  const [data, setData] = useState(null);
  const [text, settext] = useState("");
  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [course, setCourses] = useState([]);
  const [video, setVideo] = useState([]);
  const [uri, seturi] = useState("");
  const [subid, setsubid] = useState("");
  const [value, setValue] = useState(true);
  // console.log(value, "slected value");
  const [submodule, setsubmodule] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const[users,setUsers]=useState()
  const [selectedUser, setSelectedUser] = useState(null);

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon2 = (type,text) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const fetchApisVimeoUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(VIMEOUSERS, config);
      // console.log(response,"response")
      setUsers(response?.data)
   
      setLoading(false);
    } catch (error) {
      console.log(error.response.status==402);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
        
      }

      
      setLoading(false);

    }
  };
  useEffect(()=>{
    fetchApisVimeoUsers();
  },[])
  const fetchApisVimeo = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${VIMEOAPI}${currentPage}`, config);
   
      setList(response?.data?.dir);
      setData(response?.data?.dir);
      setLast_page(+response?.data?.pagging?.last);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      if(error.response.status==402){
        setValue(true)
        setmessage("Please Selecte the Vimeo Account")
      }
      setLoading(false);

    }
  };
  const fetchVimeoCurrent= async () => {
    try {
      setLoading(true);
      const response = await axios.get(VIMEOSELECT, config);
      // console.log(response,"response")
      const selectedUserId = response?.data[0]?.id;
      // Find the selected user in the array based on the unique identifier
      const selectedUser = users?.find((user) => +user?.Id == selectedUserId);
  
      // Set the selected user to the state
      setSelectedUser(selectedUser);   
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      setLoading(false);

    }
  };
  useEffect(()=>{
    fetchVimeoCurrent();
  },[])
  // console.log(selectedUser,"selectedUserId",users)

 
  const submoduledata=async()=>{
    try{
      setLoading(true);

    const response= await axios .get(VIMEOSUBMODUULE,config)
    setsubmodule(response?.data);
    setLoading(false);

    } catch(error){
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      setLoading(false);

    }

  }
  const fetchApisCourse = async () => {
    try {
      setLoading(true);

      const response = await axios.get(COURSEVIDEO, config);
      setCourses(response?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const fetchApisVideo = async () => {
    try {
      setLoading(true);

      const response = await axios.get(VIDEOMODULE, config);
      setVideo(response?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  // console.log(course, "data");

  useEffect(() => {
    fetchApisVimeo();
    fetchApisCourse();
    fetchApisVideo();
    submoduledata()
  }, []);
  const openNotificationWithIcon = (type, text) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleVimeo = (e) => {
    e.preventDefault();
navigate("/admin/vimeo-video/users")
  };
  const handleImport = () => {
    hadelSwitchUser();

    setTimeout(() => {
      hadelSwitchUser();
    }, 1000);
  };
  const hadelSwitchUser = async () => {
    try {
      setLoading(true);
      const responce = await axios.post(
        SWITCHUSERS,
        {
          account: selectedUser?.Id,
        },
        config
      );
      console.log(responce?.data?.message,"res")
      setLoading(false);
      if (responce.status == 200) {
        if(responce?.data?.message==="account switch successfully"){
        openNotificationWithIcon("success", responce.data.message);
        setIsModalVisible(false);
        fetchApisVimeo()
        }
        else{}
      }
    } catch (error) {
      console.log(error );
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error",error?.response?.data?.message      )

    }
  };
  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      settext(searchValue);
      const res = await axios.get(
        `api/admin/vimeo/dirs/search?page=1&search=${searchValue}`,
        config
      );
      if (res.data.dir.length > 0) {
        setList(res.data.dir);
        setLast_page(res.data.pagging.last);
        setmessage("No Data");
      } else {
        // setList(data)
      }
      if (res.data.dir.length == 0) {
        setList(res.data.dir);
        setmessage("No Data Found");
      }
      if (searchValue.length === 0) {
        const res = await axios.get(`${VIMEOAPI}${currentPage}`, config);
        setList(data);
        setCurrentPage(1);
        setLast_page(res.data.pagging.last);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const itemsPerPage = list?.length;
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setChecked(list.map((li) => li.uri));
    if (isCheckAll) {
      setChecked([]);
    }
  };
  const handleChange = (uri, check) => {
    if (check == true) {
      // handleShowForm();
    }
    const arr = [...checked];
    if (!arr.includes(uri)) {
      setChecked([...arr, uri]);
    } else {
      const res = arr.filter((e) => e !== uri);
      setChecked(res);
    }
  };

  const handleShowForm = (e) => {
    e.preventDefault();
    // console.log("krishna");
    // event.preventDefault()
    setIsModalVisible(true);
  };
const hadlevimeodrl=(uri)=>{
  // let ciphertext = CryptoJS.AES.encrypt(
  //   JSON.stringify(uri),
  //   "my-secret-key@admin8623009959"
  // ).toString();
  // let ciphertexts = encodeURIComponent(ciphertext);
  navigate(`/admin/vimeolist/${uri}`);
}

//  console.log(message,"messgaes",value,list)

  const [selectedFolders, setSelectedFolders] = useState([]);

  
  return (
    <div className="main-wrapper ">
      <AdminHeader />
      <div className="page-content ">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div className="d-flex"
                          style={{justifyContent:"space-between"}}
                          >
                          <div className="">
                          <h3>Import Vimeo Videos</h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{ marginBottom: "-1.6em" }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() => navigate("/admin/recorded-videos-list")}
                              >
                                Recorded Videos
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                              >
                                Import Vimeo Videos
                              </li>
                            </ol>
                          </nav>
                          </div>
                          <div
                                        className="d-flex justify-content-end"
                                        style={{ gap: "8px" }}
                                      >
                                        <button
                                          onClick={(e) => {
                                            handleShowForm(e)
                                          }}
                                          className="btn btn-danger"
                                          id="custom-button"
                                          style={{ padding: "5px 20px" }}
                                          // disabled={checked?.length<=0}
                                        >
                                          Select Vimeo Account
                                        </button>
                          </div>
                          </div>
                        
                          
                          
                        </div>
                        
                        <div className="comman-space pb-0">
                          <div
                          // className="settings-referral-blk table-responsive"
                          // id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row  gx-2 align-items-center">
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group justify-content-start">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search"
                                          // allowClear
                                          onChange={Filter}
                                          style={{ width: "100%" }}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="d-flex justify-content-end"
                                        style={{ gap: "8px" }}
                                      >
                                        <button
                                          onClick={(e) => {
                                            handleVimeo(e);
                                          }}
                                          className="btn btn-primary"
                                          id="custom-button"
                                          style={{ padding: "5px 20px" }}
                                          // disabled={checked?.length<=0}
                                        >
                                          Create Vimeo Account
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk">
                              <Modal
                                title="Import Vimeo"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisible}
                                okText="Import"
                                onOk={() => {
                                  handleImport()
                                }}
                                onCancel={() => {
                                  setIsModalVisible(false);
                                }}
                              >
                                <div className="mb-3">
                                  <label htmlFor="formFile" className="form-label">
                                    Select Vimeo Account
                                  </label>
                                  <Select
                                    showSearch
                                    style={{ width: 480 }}
                                    placeholder="Select Vimeo Account"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                      setSelectedUser(users.find((user) => user?.Id == e)); // Update the selected user when changed
                                    }}
                                    value={selectedUser?.Id} 
                                  >
                                    {users?.map((item)=>{
                                      return(
                            <Option value={item?.Id} key={item?.Id}>
                                      {item?.name}
                                    </Option>
                                      )
                                    })}
                                    
                                  </Select>
                                </div>
                              
                              </Modal>
                            <div>
                              {loading===true?(
                                 <tbody className="d-flex justify-content-center align-items-center"
                                 style={{
                                   position: "fixed",
                                   top: 0,
                                   right: 0,
                                   bottom: 0,
                                   left: 0,
                                   backgroundColor: "rgba(255, 255, 255, 0.6)",
                                 }}>
                                   <tr>
                                     <td className="spinner-border text-warning"
                                         role="status">
                                          <span className="sr-only">
                                           Loading...
                                         </span>
                                       
                                       
                                     </td>
                                   </tr>
                                 </tbody>
                              ):(
                                <div className="row d-flex">


                                {list?.map((folder) => (
                                  <div key={folder?.uri} className="col-lg-4 col-md-6"
                                  onClick={()=>{
                                    hadlevimeodrl(folder?.uri)
                                  }}
                                  
                                  >
                                    <div className="card  d-flex" style={{borderRadius:"10px"}}
                                   
                                    >
                                      <div className="card-body ">
                                        <div className="d-flex"
                                        style={{justifyContent:"space-between"}}
                                        >
                                           <Tooltip title={folder?.name}>
                                                                          
                                                    <h5 className="card-title">{`${folder?.name.slice(0, 15)} ${
                                                                            folder?.name.length > 15
                                                                              ? "..."
                                                                              : ""
                                                                          }`}</h5>
                              
                                                                        </Tooltip>
                              
                                       
                                        </div>
                                        <div className="">
                                          <div className="col-lg-12 col-md-12 col-sm-12 d-flex"
                                            style={{justifyContent:"center"}}
                                         
                                          >
                                            
                                          <img src={Folder} style={{ width: "140px", height: "140px",justifyContent:"center"}} alt="Folder Icon" className="" />
                              
                              
                                          </div>
                              
                                        </div>
                                        <div className="row" style={{marginTop:"10px"}}>
                                          <div className="col-lg-6"
                                          style={{margin:"auto",}}
                                         
                                          >
                                        <h6 style={{color:"#958e8d",textAlign:"center"}}>Floders :{" "}{folder?.folders}</h6>
                              
                                          </div>
                                          <div className="col-lg-6"
                                          style={{margin:"auto"}}
                                         
                                          >
                                        <h6 style={{color:"#958e8d",textAlign:"center"}}> Videos :{" "}{folder?.videos}</h6>
                              
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              )}
                           
                

                            </div>
                            </div>

                            {(list?.length <= 0||list==null)  && value==true ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              <></>
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list?.length <= 0 ? (
                              <></>
                            ) : (
                              <PaginationVimeo
                                nPages={last_page}
                                currentPage={+currentPage}
                                text={text}
                                setLast_page={setLast_page}
                                setCurrentPage={setCurrentPage}
                                link={VIMEOAPI}
                                setData={setList}
                                linkSearch={"api/admin/vimeo/dirs/search"}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
