import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BlogDetails from "./components/blog/blogDetails";
import BlogGrid from "./components/blog/blogGrid";
import BlogList from "./components/blog/bloglist";
import BlogMasonry from "./components/blog/blogMasonry";
import BlogModern from "./components/blog/blogModern";
import Cart from "./components/pages/cart";
import JobCategory from "./components/pages/category";
import Checkout from "./components/pages/checkout";
import AddCourse from "./components/pages/course/addCourse";
import CourseDetails from "./components/pages/course/courseDetails";
import CourseDetails1 from "./components/pages/course/courseDetails1";
import CourseDetails2 from "./components/pages/course/courseDetails2";
import CourseGrid from "./components/pages/course/courseGrid";
import CourseLesson from "./components/pages/course/courseLesson";
import CourseList from "./components/pages/course/courseList";
import ComingSoon from "./components/pages/error/comingSoon";
import Error404 from "./components/pages/error/error404";
import Error500 from "./components/pages/error/error500";
import UnderConstruction from "./components/pages/error/underConstruction";
import Faq from "./components/pages/faq";
import ForgotPassword from "./components/pages/forgotPassword";
import Login from "./components/pages/login";
import Notification from "./components/pages/notification";
import PrivacyPolicy from "./components/pages/policy";
import PricingPlan from "./components/pages/pricingPlan";
import PricingPlan2 from "./components/pages/pricingPlan/pricingPlan2";
import Register from "./components/pages/register";
import RegisterOne from "./components/pages/register/register1";
import RegisterTwo from "./components/pages/register/register2";
import RegisterThree from "./components/pages/register/register3";
import RegisterFour from "./components/pages/register/register4";
import RegisterFive from "./components/pages/register/register5";
import Support from "./components/pages/support";
import TermsCondition from "./components/pages/termsCondition";
import Wishlist from "./components/pages/wishlist";

//harish
import { Home } from "./components/home";
import { Home2 } from "./components/home2";
import { Home3 } from "./components/home3";
import { Home4 } from "./components/home4";
import { Dashboard } from "./components/instructor/dashboard";
import { AdminDashboard } from "./components/instructor/adminDashboard";

import { InstructorList } from "./components/instructor/list";
import InstructorGrid from "./components/instructor/grid";
import InstructorCourse from "./components/instructor/course";
import InstructorReviews from "./components/instructor/reviews";
import InstructorEarnings from "./components/instructor/earnings";
import InstructorOrders from "./components/instructor/orders";
import InstructorPayouts from "./components/instructor/payouts";
import InstructorEditProfile from "./components/instructor/editProfile";
import InstructorSecurity from "./components/instructor/security";
import InstructorSocialProfile from "./components/instructor/socialProfiles";
import InstructorNotification from "./components/instructor/notification";
import InstructorTickets from "./components/instructor/tickets";
import InstructorChat from "./components/instructor/chat";
import InstructorDeleteProfile from "./components/instructor/deleteProfile";
import InstructorEdit from "./components/instructor/edit";
import InstructorGrid2 from "./components/instructor/grid2";
import InstructorLinkedAccount from "./components/instructor/linkedAccount";
import InstructorNewTickets from "./components/instructor/newTickets";
import InstructorProfile from "./components/instructor/profile";
import InstructorProfilePrivacy from "./components/instructor/profilePrivacy";
import InstructorStudentGrid from "./components/instructor/studentGrid";
import InstructorStudentList from "./components/instructor/studentList";
import StudentEditProfile from "./components/student/editProfile";
import StudentAccounts from "./components/student/accounts";
import StudentBilling from "./components/student/billing";
import StudentDeleteProfile from "./components/student/deleteProfile";
import StudentInvoice from "./components/student/invoice";
import StudentNotification from "./components/student/notification";
import StudentPayment from "./components/student/payment";
import StudentPrivacy from "./components/student/privacy";
import StudentReferral from "./components/student/referral";
import StudentSecurity from "./components/student/security";
import StudentSocialProfile from "./components/student/socialProfile";
import StudentSubscription from "./components/student/subscription";
import StudentNewTickets from "./components/student/newTickets";
import StudentTickets from "./components/student/tickets";
import StudentViewTickets from "./components/student/viewTickets";
import StudentProfile from "./components/student/profile";
import StudentsGrid from "./components/student/grid";
import StudentsGrid2 from "./components/student/grid2";
import StudentsList from "./components/student/list";
import InstructorDepositDashboard from "./components/instructor/deposit";
import StudentDepositDashboard from "./components/student/depositDashboard";
import DashboardInstructor from "./components/instructor/dashboardInstructor";
import WithdrawalInstructor from "./components/instructor/withdrawalInstructor";
import DepositInstructor from "./components/instructor/depositInstructor";
import TransactionInstructor from "./components/instructor/transactionInstructor";
import DepositStudent from "./components/student/depositStudent";
import TransactionStudent from "./components/student/transactionStudent";

import CourseMessage from "./components/student/courseMessage";
import CourseWishlist from "./components/student/courseWishlist";
import CourseStudent from "./components/student/courseStudent";
import PurchaseHistory from "./components/student/purchaseHistory";
import ViewInvoice from "./components/student/invoice/viewInvoice";
import NewPassword from "./components/pages/newPassword";
import AdminLogin from "./components/pages/AdminLogin";
import AdminInstructorStudentGrid from "./components/instructor/adminStudentGrid";
import AdminInstructorStudentList from "./components/instructor/adminStudentList";
import Installments from "./components/instructor/AdminCourseInstallements";
import AddUser from "./components/student/addUser";
import AddUserEdit from "./components/student/addUserEdit";
import AdminCourseList from "./components/instructor/adminCourse";
import AddAdminCourse from "./components/student/addAdminCourse";
import EditAdminCourse from "./components/student/editAdminCourse";
import AdminPaymentMethodList from "./components/instructor/adminPaymentList";
import AdminBatchList from "./components/instructor/addBatchList";
import AdminBatchListEdit from "./components/instructor/adminEditBatchList";
import AddBatchStudents from "./components/instructor/addBatchStudents";
import AdminAddNewBatch from "./components/instructor/addNewBatch";

import AddPaymentTerm from "./components/student/addPaymentTerm";
import AddAdminCourseModule from "./components/student/addAdminCourseModule";
import ProtectedAdmin from "./components/pages/ProtectedAdmin";
import AddAdminFeed from "./components/adminFeed/AddAdminFeed";
import AddAdminFeed1 from "./components/adminFeed/adminFeedList";
import AddFeedEdit from "./components/adminFeed/addFeedEdit";
import AddRecordedVideo from "./components/recordedVideo/AddAdminFeed";
import RecordedVideoModuleAndSubModule from "./components/recordedVideo/Recorded Module & SubModule";
import AddVideo from "./components/recordedVideo/adminFeedList";
import AddRecordEdit from "./components/recordedVideo/addRecordEdit";
import { AdminTestSeries } from "./components/instructor/adminTestSeries";
import AdminAddTestSeries from "./components/student/adminAddTestSeries";
import AdminEditTestSeries from "./components/student/adminEditTestSeries";
import AdminAddTest from "./components/student/adminAddTest";
import AdminEditTest from "./components/student/adminEditTest";
import { AdminTestSeriesList } from "./components/instructor/adminTestSeriesList";
import AdminEditTestSeriesUser from "./components/student/adminEditTestSeriesUser";
import { AdminTestListUser } from "./components/instructor/adminTestListUser";
import AddAdminQuestion from "./components/student/addAdminQuestion";
import EditAdminQuestion from "./components/student/editAdminQuestion";
import EditPaymentTearms from "./components/student/editPaymentTearms";
import StudentHome from "./components/studentHome";
import StudentLogin from "./components/pages/studentLogin";
import { AdminTestListUsers } from "./components/instructor/adminTestListUsers";
import AdminAddTestSeriesSubModule from "./components/student/adminAddTestSeriesSubModule";
import AddAdminTestQuestion from "./components/student/addAdminTestQuestion";
import EditAdminTestQuestion from "./components/student/editAdminTestQuestion";
import AddRecordedVideoModule from "./components/recordedVideo/recordedVideoModule";
import AdminLiveClasses from "./components/liveClassesModule/LiveClasses";
import AddLiveClasses from "./components/liveClassesModule/LiveClasses/addLiveClasses";
import AdminViewVideo from "./components/recordedVideo/adminViewVideo";
import AdminViewLink from "./components/recordedVideo/adminLinkVideo";
import AddAdminVideoModule from "./components/student/addAdminVideoModule";
import AdminEditVideoModule from "./components/student/adminEditVideoModule";
import { AddAdminRecordedVideos } from "./components/instructor/addAdminRecordedVideos";
import AddAdminVideoSubModule from "./components/student/addAdminViedoSubmodule";
import AddAdminRecordedVideoSub from "./components/student/addAdminRecordedVideoSub";
import AdAdminRecoededSubEdit from "./components/student/addAdminRecoededSubEdit";
import AdAdminSubEdit from "./components/student/addAdminSubEdit";
import { AdminZoom } from "./components/instructor/adminZoom";
import AddStudentTest from "./components/instructor/addStudentTest";
import AddStudentTestSeires from "./components/student/addStudentTestSeires";
import StudentSingIn from "./components/pages/course/studentSignIn";
import StudentCourse from "./components/pages/course/studentCourse";
import DetailsContentStudent from "./components/pages/course/studentCourseDetails/detailsContent";
import StudentCourseDetails from "./components/pages/course/studentCourseDetails";
import StudentMyCourseDetails from "./components/pages/course/StudentSubscrptioncourse";

import BuyCourse from "./components/pages/course/buyCourse";
import CourseSubcription from "./components/pages/course/courseSubcription";
import StudentsVideo from "./components/student/studentVideo";
import StudentVideoSub from "./components/student/studentVideoSub";
import StudentView from "./components/student/studentView";
import ProtectedStudent from "./components/pages/protectedStudent";
import AdminLiveClassBatch from "./components/instructor/adminLiveClassBatch";
import AdminClassLiveBatchAdd from "./components/student/adminClassLiveBatchAdd";
import AdminClassLiveBatchEdit from "./components/student/adminClassLiveBatchEdit";
import EditLiveClasses from "./components/liveClassesModule/LiveClasses/editLiveClass";
import { StartMeeting } from "./components/liveClassesModule/LiveClasses/startMeeting";
import StudentLiveClasses from "./components/student/studentLive";
import studentTestResult from "./components/student/studentTestResult";
import testSeriesSummary from "./components/student/studentTestSummary";
import CourseGrids from "./components/pages/course/courseGrids";
import DemoDetails from "./components/pages/course/demoCourse";
import VideoDetails from "./components/pages/course/studentVideoDetails";
import { AdminZoomDetails } from "./components/instructor/adminZoomDetails";
import AdminAddZoom from "./components/student/adminAddZoom";
import AdminEditZoom from "./components/student/adminEditZoom";
import AdminBuyCourse from "./components/student/adminBuyCourse";
import AdminGridCourse from "./components/student/adminGirdCourse";
import AdminCourseEmi from "./components/student/adminCourseEMi";
import AdminBatchLink from "./components/student/addBatchLink";
import CreateTestSeries from "./components/student/adminAddTestSeries/createTestSeries";
import CreateBatch from "./components/student/addBatchLink/createBatch";
import AddRecordedLink from "./components/student/addRecordLink";
import StudentChangePassword from "./components/pages/StudentChangePassword";
import StudentTestResult from "./components/instructor/addStudentResult";
import StudentTestResultSingle from "./components/instructor/addStudentResutsingle"
import AddAdminExpiry from "./components/instructor/addAdminExpiry";
import StudentEditProfiles from "./components/student/studentEditProfile";
import AdminProfile from "./components/instructor/adminProfile";

import StudentSecuritys from "./components/student/studentSecurity";
import AdminRestrictedVideo from "./components/student/addRestrictedTime";
// import { Scrool } from "./components/pages/course/addCourse/";
import { AdminGroupTestseries } from "./components/instructor/adminGroupTestseries";
import { AdminTestListUsersGroup } from "./components/instructor/adminTestListUsersGroup";
import AddAdminTestQuestionGroup from "./components/student/addAdminTestQuestionGroup";
import EditAdminTestQuestionGroup from "./components/student/editAdminTestQuestionGroup";
import Scroll from "./components/pages/course/addCourse/WindowTop";
import TestseriesSubModule from "./components/instructor/addAdminTestseriesSubModule";
import AddImportVimeo from "./components/instructor/addImportVimeo";
import ExpiredCourse from "./components/student/StudentExpiredCourses";
import ExamAllerts from "./components/instructor/addAdminExamAllerts";
import CreateExamAllerts from "./components/instructor/addAdmincreateExam";
import VimeoList from "./components/instructor/addvimeovideolist";
import AddSubscrptions from "./components/instructor/addadminsubscrptionsbatches";
import CourseCategory from "./components/instructor/addCourseCategory";
import AdminFeedsList from "./components/instructor/addAdminFeeds";
import AddAdminFeedlist from "./components/instructor/addAdminCreateFeeds";
import AdminFeedsCategory from "./components/instructor/addAdminFeedCategory";
import EditFedds from "./components/instructor/addAdminFeedUpdate";
import AdminCourseIndex from "./components/instructor/addAdminCourseIndex";
import AdminInstructorTeacher from "./components/instructor/addAdminTeachers";
import AddTeacher from "./components/instructor/addAdminCreateTeachers";
import EditTeacher from "./components/instructor/addAdminEditTeacher";
import CourseModule from "./components/instructor/adminCoursemodule";
import CourseSubModule from "./components/instructor/addCourseSubModule";
import StudentFreeCourse from "./components/pages/course/StudentFreeCourse";
import StudentsVideoCourse from "./components/student/studentVideoCourse";
import AddStudentTestCourse from "./components/instructor/addstudentTestbycourse";
import CourseType from "./components/instructor/adminCourseType";
import CreateNewCourseType from "./components/instructor/adminaddCourseType";
import AdminCategoryEdit from "./components/instructor/addCourseCategoryEdit";
import CourseSubCategoryEdit from "./components/instructor/addEditCourseType";
import VideoUploadPdf from "./components/recordedVideo/addUplodPdf";
import AddVideosList from "./components/recordedVideo/addVideosList";
import AddCoursesVideoList from "./components/instructor/addCourseVideosList";
import TeacherLogin from "./components/Teacher/TeacherLogin";
import TeacherDashboard from "./components/Teacher/TeacherDashboard";
import TeacherChangePassword from "./components/Teacher/TeacherChangePassword";
import TeacherForgotPassword from "./components/Teacher/TeacherResetPassword";
import StudentChatApp from "./components/student/StudentChat";
import StudentLogiCourses from "./components/pages/course/StudentLoginCourses";
import StudentLoginCourseDetails from "./components/pages/course/StudentLoginCourseDetails";
import PopularCourses from "./components/instructor/addPopularCourses";
import AppHomeScreen from "./components/instructor/addAppHomeScreen";
import AppHomeScreenCreate from "./components/instructor/addAppHomeScreenCreate";
import AppHomeScreenEdit from "./components/instructor/addAppHomeScreenEdit";
import AddImportVimeoSubModule from "./components/instructor/addImportVimeoSub/index";
import VimeoUserList from "./components/instructor/addVimeoUser";
import CreateVimeoUser from "./components/instructor/addVimeouserCreate";
import VimeoUserEdit from "./components/instructor/addVimeouserEdit";
import AdminEditExamAllert from "./components/instructor/addAdminExamAllertEdit";
import AdminImages from "./components/instructor/addAdminImages";
import AdminInvoiceList from "./components/instructor/addAdminFeeInvoice";
import AddAdminInvouceCreate from "./components/instructor/addAdminCreateFeeInvoice";
import AdminInstuitueList from "./components/instructor/addAdminInstutiteList";
import AdminAddInstitute from "./components/instructor/addAdminCreateInstitue";
import AdminEditInstituteList from "./components/instructor/addAdminEditInstitue";
import AdminAddPayment from "./components/instructor/addAdminPaymentMethodCreate";
import AdminEditPaymentMethods from "./components/instructor/addAdminEditPaymentMethods";
import AdminPaymentList from "./components/instructor/addAdminPaymentMethods";
import AdminContacts from "./components/instructor/addAdminContacts";
import AddAdminCreateContact from "./components/instructor/addAdminCreateContact";
import EditContact from "./components/instructor/addAdminEditContacts";
import AdminContactsDetails from "./components/instructor/addAdminContactDetails";
import AddAdminCreateContactDetails from "./components/instructor/addAdminContactCreateDetails";
import EditContactDetails from "./components/instructor/addAdminEditContactDetails";
import InvoiceDownload from "./components/student/StudentInvoiceDownlaod";
import StudentTestResultDownload from "./components/instructor/addStudentTestresultDownload";
import StudentTestResultDownloadAnalysis from "./components/instructor/addStudentResultDownloadAnaylysi";
import StudentTestMultiResultDownload from "./components/instructor/addStudentMultiResultDownlaod";
import StudentTestMultiResultDownloadAnalysis from "./components/instructor/addStudentMultResultAnalysis";
import StudentSingleAnswersheet from "./components/instructor/adStudentAnwerSheet";
import StudentMultiAnswerSheet from "./components/instructor/addStudentMultAnswerSheet";
import ExamAlertsPdf from "./components/instructor/addAdminExamAlertPdf";
import AdminEditExamAllertPdf from "./components/instructor/addAdminExamAlertEditPdf";
import CreateExamAllertsPdf from "./components/instructor/addAdminExamAlertCreatePdf";
import StudentCourseList from "./components/HomePageCourses/CoursesList";
import StudentCourseDetailsWithoutLogin from "./components/studentCourseDetails";
import AddAdminTransactionList from "./components/instructor/addAdminTrasactionList";
import Updates from "./components/student/Updates";
import CenterList from "./components/instructor/addAdminCentersList";
import AddAdminCenterList from "./components/instructor/addCreateCenterList";
import AddEditCenterList from "./components/instructor/addEditAcademyCenter";
import AddImportVimeoSubModule_SubModule from "./components/instructor/addAdminVimeoSubmodule-Sub";
import { AdminAddTestSeriesSubModuleFolder } from "./components/student/adminAddSubmodulefolder";
import AdminAddTestSeriesMainSubModule from "./components/student/adminAddTestSeries-Main-SubModule";
import { AdminGroupTestseriesNModule } from "./components/instructor/addAdminShowTestseriesNmodules";
import AddStudentTestCourseModule from "./components/instructor/addStudentTestSeriesModule";
import TestseriesSubModule1 from "./components/instructor/addAdminTestseriesSubModule1/index.js";

// import config from 'config';
const Approuter = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent right-click context menu
    };
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  // useEffect(() => {
  //   Scrool(0, 0);
  // }, []);
  return (
    <BrowserRouter>
      <Scroll />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses/list/:id/:search" element={<StudentCourseList />} />
        <Route
          path="/student/login/courses-slug/:slug"
          element={<StudentCourseDetailsWithoutLogin/>}
        />
        
        {/* Admin login page */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/student/home" element={<StudentHome />} />
        <Route path="/home2" element={<Home2 />} />
        <Route path="/home3" element={<Home3 />} />
        <Route path="/home4" element={<Home4 />} />
        <Route
          path="/dashboard"
          element={<ProtectedStudent Component={Dashboard} />}
        />
        {/* Admni Dashboard */}
        <Route
          path="/admin/dashboard"
          element={<ProtectedAdmin Component={AdminDashboard} />}
        />
        {/* Student Login */}
        <Route path="/login" element={<StudentLogin />} />
        <Route path="/teacher/login" element={<TeacherLogin />} />
        <Route path="/teacher/dashboard" element={<TeacherDashboard />} />
        <Route path="/teacher/changepassword" element={<TeacherChangePassword />} />
        <Route path="/teacher/forgot-password" element={<TeacherForgotPassword />} />

        <Route path="/changepassword" element={<StudentChangePassword />} />
        {/* Student sign in */}
        <Route path="/register" element={<StudentSingIn />} />
        {/* student courses */}
        <Route
          path="/student/courses/:id"
          element={<ProtectedStudent Component={StudentCourse} />}
        />
          <Route
          path="/student/courses-list/:id"
          element={<StudentLogiCourses/>} />
        
        <Route
          path="/student/free-courses"
          element={<ProtectedStudent Component={StudentFreeCourse} />}
        />
        {/* stusent course details*/}
        <Route
          path="/student/courses-slug/:slug"
          element={<ProtectedStudent Component={StudentCourseDetails} />}
        />
         <Route
          path="/student/courses-details-slug/:slug"
          element={<StudentLoginCourseDetails/>}
        />
         <Route
          path="/student/mycourses/:slug"
          element={<ProtectedStudent Component={StudentMyCourseDetails} />}
        />
        {/* stdent course add */}
        <Route
          path="/student/buy-course/:slug"
          element={<ProtectedStudent Component={BuyCourse} />}
        />
        {/* student subscription */}
        <Route
          path="/student/my-subscriptions"
          element={<ProtectedStudent Component={CourseSubcription} />}
        />
           <Route
          path="/student/chat"
          element={<ProtectedStudent Component={StudentChatApp} />}
        />
        {/* student test result */}
        <Route
          path="/student/test-result/:subid"
          element={<ProtectedStudent Component={studentTestResult} />}
        />
        {/* student test summary */}
        <Route
          path="/student/test-series-summary"
          element={<ProtectedStudent Component={testSeriesSummary} />}
        />
        {/* student video */}
        <Route
          path="/student/videos"
          element={<ProtectedStudent Component={StudentsVideo} />}
        />
         <Route
          path="/student/videos/:id"
          element={<ProtectedStudent Component={StudentsVideoCourse} />}
        />
        
        {/* student video sub */}
        <Route
          path="/student/videos/:slug1/:slug2"
          element={<ProtectedStudent Component={StudentVideoSub} />}
        />
        {/* view student */}
        <Route
          path="/student/view-video/:id"
          element={<ProtectedStudent Component={StudentView} />}
        />
        {/* student live class */}
        <Route
          path="/student/std-live-class"
          element={<ProtectedStudent Component={StudentLiveClasses} />}
        />
        {/* student edit profile */}
        <Route
          path="/student/setting-edit-profile"
          element={<ProtectedStudent Component={StudentEditProfiles} />}
        />
         <Route
          path="/student/invoicefee"
          element={<ProtectedStudent Component={InvoiceDownload} />}
        />
 <Route
          path="/student/updates"
          element={<ProtectedStudent Component={Updates} />}
        />
        <Route
          path="/student/expired-courses"
          element={<ProtectedStudent Component={ExpiredCourse} />}
        />
            <Route
          path="/admin/profile"
          element={<ProtectedAdmin Component={AdminProfile} />}
        />
        {/* student password reset */}
        <Route
          path="/student/setting-student-security"
          element={<ProtectedStudent Component={StudentSecuritys} />}
        />
        {/* admin buy course */}
        <Route path="/admin/buy-course/:id" element={<AdminBuyCourse />} />
        {/* admin list course */}
        <Route path="/admin/course-list/:id" element={<AdminGridCourse />} />
        {/* admin emi list */}
        <Route
          path="/admin/course-list/emi/:cid/:id"
          element={<AdminCourseEmi />}
        />
        {/* Admin Student  */}
        <Route
          path="/admin/paid-student"
          element={<ProtectedAdmin Component={AdminInstructorStudentGrid} />}
        />
        {/* Expiry Date Student */}
        <Route
          path="/admin/expiry-student"
          element={<ProtectedAdmin Component={AddAdminExpiry} />}
        />
         <Route
          path="/admin/transaction"
          element={<ProtectedAdmin Component={AddAdminTransactionList} />}
        />
        <Route
          path="/admin/student-list"
          element={<ProtectedAdmin Component={AdminInstructorStudentList} />}
        />
         <Route
          path="/admin/teachers"
          element={<ProtectedAdmin Component={AdminInstructorTeacher} />}
        />
          <Route
          path="/admin/editteacher/:stdid"
          element={<ProtectedAdmin Component={EditTeacher} />}
        />
         <Route
          path="/admin/add-teachers"
          element={<ProtectedAdmin Component={AddTeacher} />}
        />
         <Route
          path="/admin/exam-allerts"
          element={<ProtectedAdmin Component={ExamAllerts} />}
        />
         <Route
          path="/admin/exam-allerts/pdf/:id"
          element={<ProtectedAdmin Component={ExamAlertsPdf} />}
        />
         <Route
          path="/admin/exam-allerts/edit-pdf/:id1"
          element={<ProtectedAdmin Component={AdminEditExamAllertPdf} />}
        />
         <Route
          path="/admin/exam-allerts/create-pdf/:id"
          element={<ProtectedAdmin Component={CreateExamAllertsPdf} />}
        />
           <Route
          path="/admin/edit/exam-allerts/:id"
          element={<ProtectedAdmin Component={AdminEditExamAllert} />}
        />
        
         <Route
          path="/admin/craete-exam-allerts"
          element={<ProtectedAdmin Component={CreateExamAllerts} />}
        />
        <Route
          path="/admin/course-instalments/:id/:batch_id"
          element={<ProtectedAdmin Component={Installments} />}
        />
         <Route
          path="/admin/addsubscrption/:id/:batch_id/:coursedata"
          element={<ProtectedAdmin Component={AddSubscrptions} />}
        />
           <Route
          path="/admin/images"
          element={<ProtectedAdmin Component={AdminImages} />}
        />
        <Route
          path="/admin/adduser"
          element={<ProtectedAdmin Component={AddUser} />}
        />
        {/* Edit User */}
        <Route path="*" element={<Error404 />} />
        <Route
          path="/admin/edituser/:stdid"
          element={<ProtectedAdmin Component={AddUserEdit} />}
        />
        {/* Admin Coures */}
        <Route
          path="/admin/course"
          element={<ProtectedAdmin Component={AdminCourseList} />}
        />
         <Route
          path="/admin/course-module/:id"
          element={<ProtectedAdmin Component={CourseModule} />}
        />
         <Route
          path="/admin/courses/videolist/:id"
          element={<ProtectedAdmin Component={AddCoursesVideoList} />}
        />
        
           <Route
          path="/admin/course-type/:id"
          element={<ProtectedAdmin Component={CourseType} />}
        />
          <Route
          path="/admin/edit/course-type/:id"
          element={<ProtectedAdmin Component={CourseSubCategoryEdit} />}
        />
         <Route
          path="/admin/add/course-type/:id"
          element={<ProtectedAdmin Component={CreateNewCourseType} />}
        />
         <Route
          path="/admin/course-sub-module/:id/:subid"
          element={<ProtectedAdmin Component={CourseSubModule} />}
        />
           <Route
          path="/admin/course/index"
          element={<ProtectedAdmin Component={AdminCourseIndex} />}
        />
         <Route
          path="/admin/feeds"
          element={<ProtectedAdmin Component={AdminFeedsList} />}
        />
          <Route
          path="/admin/edit/feeds/:id"
          element={<ProtectedAdmin Component={EditFedds} />}
        />
         <Route
          path="/admin/feeds/category"
          element={<ProtectedAdmin Component={AdminFeedsCategory} />}
        />
         <Route
          path="/admin/add/feeds"
          element={<ProtectedAdmin Component={AddAdminFeedlist} />}
        />
         <Route
          path="/admin/invoice"
          element={<ProtectedAdmin Component={AdminInvoiceList} />}
        />
          <Route
          path="/admin/create/invoice"
          element={<ProtectedAdmin Component={AddAdminInvouceCreate} />}
        />
        <Route
          path="/admin/centers"
          element={<ProtectedAdmin Component={CenterList} />}
        />
        <Route
          path="/admin/create/centers"
          element={<ProtectedAdmin Component={AddAdminCenterList} />}
        />
          <Route
          path="/admin/edit/centers/:id"
          element={<ProtectedAdmin Component={AddEditCenterList} />}
        />
        
          <Route
          path="/admin/institute"
          element={<ProtectedAdmin Component={AdminInstuitueList} />}
        />
         <Route
          path="/admin/create/institute"
          element={<ProtectedAdmin Component={AdminAddInstitute} />}
        />
          <Route
          path="/admin/edit/institute/:id"
          element={<ProtectedAdmin Component={AdminEditInstituteList} />}
        />
          <Route
          path="/admin/Payment-methods"
          element={<ProtectedAdmin Component={AdminPaymentList} />}
        />
         <Route
          path="/admin/create/Payment-methods"
          element={<ProtectedAdmin Component={AdminAddPayment} />}
        />
          <Route
          path="/admin/edit/Payment-methods/:id"
          element={<ProtectedAdmin Component={AdminEditPaymentMethods} />}
        />
           <Route
          path="/admin/course/category"
          element={<ProtectedAdmin Component={CourseCategory} />}
        />
        <Route
          path="/admin/app/homescreen"
          element={<ProtectedAdmin Component={AppHomeScreen} />}
        />
         <Route
          path="/admin/create/app/homescreen"
          element={<ProtectedAdmin Component={AppHomeScreenCreate} />}
        />
         <Route
          path="/admin/edit/app/homescreen/:id"
          element={<ProtectedAdmin Component={AppHomeScreenEdit} />}
        />
         <Route
          path="/admin/popularcourses"
          element={<ProtectedAdmin Component={PopularCourses} />}
        />
         <Route
          path="/admin/category-edit/:id"
          element={<ProtectedAdmin Component={AdminCategoryEdit} />}
        />

        {/* Add Course */}
        <Route
          path="/admin/addcourse/:id"
          element={<ProtectedAdmin Component={AddAdminCourse} />}
        />
        {/* Edit User */}
        <Route
          path="/admin/editcourse/:couid/:id"
          element={<ProtectedAdmin Component={EditAdminCourse} />}
        />
        {/* Course Payment tearm */}
        <Route
          path="/admin/show-course-payment/:payid"
          element={<ProtectedAdmin Component={AdminPaymentMethodList} />}
        />
        <Route
          path="/admin/batchList"
          element={<ProtectedAdmin Component={AdminBatchList} />}
        />
        <Route
          path="/admin/batchList-edit/:id"
          element={<ProtectedAdmin Component={AdminBatchListEdit} />}
        />
        <Route
          path="/admin/batchListStudents/:slug/:id"
          element={<ProtectedAdmin Component={AddBatchStudents} />}
        />
        {/* Add Payment terms */}
        <Route
          path="/admin/create-new-batch"
          element={<ProtectedAdmin Component={AdminAddNewBatch} />}
        />
        {/* Add batch link */}
        <Route
          path="/admin/batcheslink/:slug/:id"
          element={<ProtectedAdmin Component={AdminBatchLink} />}
        />
        {/* Add Payment terms */}
        {/*  */}
        <Route
          path="/admin/batcheslink/add-user/:slug/:id"
          element={<ProtectedAdmin Component={CreateBatch} />}
        />
        <Route
          path="/admin/add-payment-term/:payid/:name"
          element={<ProtectedAdmin Component={AddPaymentTerm} />}
        />
        {/* Edit Payment tearm */}
        <Route
          path="/admin/edit-payment-term/:payid/:cptid/:name"
          element={<ProtectedAdmin Component={EditPaymentTearms} />}
        />
        {/* Admin Courses Add Module and Sub Module*/}
        <Route
          path="/admin/courses/:slug/:name"
          element={<ProtectedAdmin Component={AddAdminCourseModule} />}
        />
        <Route
          path="/admin/feed"
          element={<ProtectedAdmin Component={AddAdminFeed} />}
        />
        {/* Add Feed */}
        <Route
          path="/admin/addfeed"
          element={<ProtectedAdmin Component={AddAdminFeed1} />}
        />
        {/* Add Edit */}
        <Route
          path="/admin/editfeed"
          element={<ProtectedAdmin Component={AddFeedEdit} />}
        />
        {/* List Feed */}
        <Route
          path="/admin/recorded-videos-list"
          element={<ProtectedAdmin Component={AddRecordedVideo} />}
        />
        <Route
          path="/admin/recorded-video/uploadPdf/:id"
          element={<ProtectedAdmin Component={VideoUploadPdf} />}
        />
        {/* Add Record Link */}
        <Route
          path="/admin/recorded-videos/video-link/:mid/:sid"
          element={<ProtectedAdmin Component={AddRecordedLink} />}
        />
        {/* Add Vimeo Video */}
        <Route
          path="/admin/vimeo-video"
          element={<ProtectedAdmin Component={AddImportVimeo} />}
        />
         <Route
          path="/admin/vimeo-video/users"
          element={<ProtectedAdmin Component={VimeoUserList} />}
        />
           <Route
          path="/admin/vimeo-video/create-users"
          element={<ProtectedAdmin Component={CreateVimeoUser} />}
        />
         <Route
          path="/admin/vimeo-video/edit-users/:id"
          element={<ProtectedAdmin Component={VimeoUserEdit} />}
        />
         <Route
          path="/admin/vimeo-video/submodule/:uri"
          element={<ProtectedAdmin Component={AddImportVimeoSubModule} />}
        />
         <Route
          path="/admin/vimeo-video/submodule-submodule/:uri"
          element={<ProtectedAdmin Component={AddImportVimeoSubModule_SubModule} />}
        />
        
          <Route
          path="/admin/vimeolist/:uri"
          element={<ProtectedAdmin Component={VimeoList} />}
        />
        <Route
          path="/admin/recorded-video"
          element={
            <ProtectedAdmin Component={RecordedVideoModuleAndSubModule} />
          }
        />
        {/* Add Feed */}
        <Route
          path="/admin/add-video"
          element={<ProtectedAdmin Component={AddVideo} />}
        />
        {/* Admin Recorded module */}
        <Route
          path="/admin/recorded-videos"
          element={<ProtectedAdmin Component={AddRecordedVideoModule} />}
        />
         <Route
          path="/admin/recorded-videos/videos-list/:id"
          element={<ProtectedAdmin Component={AddVideosList} />}
        />
        {/* Add module video */}
        <Route
          path="/admin/recorded-videos/add"
          element={<ProtectedAdmin Component={AddAdminVideoModule} />}
        />
        {/* Edit module video */}
        <Route
          path="/admin/recorded-videos/edit/:id"
          element={<ProtectedAdmin Component={AdminEditVideoModule} />}
        />
        {/* Add module Sub module video */}
        <Route
          path="/admin/recordedvideos/add/:id/:feature_type_id"
          element={<ProtectedAdmin Component={AddAdminVideoSubModule} />}
        />
        {/* Add  Sub module video */}
        <Route
          path="/admin/recordedvideos/subadd/:feature_type_id"
          element={<ProtectedAdmin Component={AddAdminRecordedVideoSub} />}
        />
        {/* Edit  Sub module video */}
        <Route
          path="/admin/recordedvideos/subedit/:id"
          element={<ProtectedAdmin Component={AdAdminSubEdit} />}
        />
        <Route
          path="/admin/recordedvideos/subedit/:id"
          element={<ProtectedAdmin Component={AdAdminRecoededSubEdit} />}
        />
        {/* recorded videos list */}
        <Route
          path="/admin/recordedvideos/:slug/:id/:feature_type_id"
          element={<ProtectedAdmin Component={AddAdminRecordedVideos} />}
        />
        {/* restricted video */}
        <Route
          path="/admin/add/restricted"
          element={<ProtectedAdmin Component={AdminRestrictedVideo} />}
        />
        {/* Live video */}
        <Route
          path="/admin/live-classes"
          element={<ProtectedAdmin Component={AdminLiveClasses} />}
        />
        {/* Live Class */}
        <Route
          path="/admin/live-classes-create"
          element={<ProtectedAdmin Component={AddLiveClasses} />}
        />
        {/* Live Class edit */}
        <Route
          path="/admin/edit-liveclass/:id/:meeting_number"
          element={<ProtectedAdmin Component={EditLiveClasses} />}
        />
        {/* Live Class Meeting */}
        <Route
          path="/student/std-live-class-join/:id"
          element={<ProtectedStudent Component={StartMeeting} />}
        />
        {/* Live batch */}
        <Route
          path="/admin/live-class-batches"
          element={<ProtectedAdmin Component={AdminLiveClassBatch} />}
        />
        {/* Live Batch Add */}
        <Route
          path="/admin/live-class-batches/add"
          element={<AdminClassLiveBatchAdd />}
        />
        {/* Live Edit */}
        <Route
          path="/admin/live-class-batches/edit/:id"
          element={<AdminClassLiveBatchEdit />}
        />
        {/* view video */}
        <Route
          path="/admin/view-video/:viewvid"
          element={<ProtectedAdmin Component={AdminViewVideo} />}
        />
        {/* admin link video */}
        <Route
          path="/admin/link-video/:id"
          element={<ProtectedAdmin Component={AdminViewLink} />}
        />
        {/* edit recorded */}
        <Route
          path="/admin/editvideo/:stdid"
          element={<ProtectedAdmin Component={AddRecordEdit} />}
        />
        {/*  test series list */}
        <Route
          path="/admin/test-series"
          element={<ProtectedAdmin Component={AdminTestSeries} />}
        />
        {/* Test series User List */}
        <Route
          path="/admin/test-series/:user/:id"
          element={<ProtectedAdmin Component={AdminTestSeriesList} />}
        />
         <Route
          path="/admin/test-series/submodule/:user/:id"
          element={<ProtectedAdmin Component={TestseriesSubModule} />}
        />
         <Route
          path="/admin/test-series/sub-module/:user/:id"
          element={<ProtectedAdmin Component={TestseriesSubModule1} />}
        />
         {/* group-testseries-question*/}
         
        {/* group-testseries-question*/}
        <Route
          path="/admin/test-series/show-group-testseries-question/:user/:id"
          element={<ProtectedAdmin Component={AdminGroupTestseries} />}
        />
          <Route
          path="/admin/test-series/show-group-module-testseries-question/:mainid/:user/:id"
          element={<ProtectedAdmin Component={AdminGroupTestseriesNModule} />}
        />
        {/* Add Test Series */}
        <Route
          path="/admin/add/test-series"
          element={<ProtectedAdmin Component={AdminAddTestSeries} />}
        />
        {/* Add Test Series sudmodule */}
        <Route
          path="/admin/add/test-series/:id/:user/:testtypeid"
          element={<ProtectedAdmin Component={AdminAddTestSeriesSubModule} />}
        />
         <Route
          path="/admin/test-series-module/:subid/:user/:mainid"
          element={<ProtectedAdmin Component={AdminAddTestSeriesSubModuleFolder} />}
        />
         {/* <Route
          path="/admin/test-series-module/:subid/:user/:mainid"
 render={(props) => (
    <ProtectedAdmin Component={AdminAddTestSeriesSubModuleFolder} {...props} />
  )}        /> */}
         <Route
          path="/admin/add/test-series/:id/:user/:testtypeid/:mainid"
          element={<ProtectedAdmin Component={AdminAddTestSeriesMainSubModule} />}
        />
        
        {/* Edit Test Series */}
        <Route
          path="/admin/edit/test-series/:id"
          element={<ProtectedAdmin Component={AdminEditTestSeries} />}
        />
        {/* Add Test */}
        <Route
          path="/admin/add/test"
          element={<ProtectedAdmin Component={AdminAddTest} />}
        />
        {/* Edit Test  */}
        <Route
          path="/admin/edit/test/:id"
          element={<ProtectedAdmin Component={AdminEditTest} />}
        />
        {/* Edit Test Series User */}
        <Route
          path="/admin/edit/test-series/:modid/:user/:id/:type"
          element={<ProtectedAdmin Component={AdminEditTestSeriesUser} />}
        />
        {/* Test User List  */}
        <Route
          path="/admin/test/:user"
          element={<ProtectedAdmin Component={AdminTestListUser} />}
        />
        {/* Test series Question list */}
        <Route
          path="/admin/testseries/:user/:subuser"
          element={<ProtectedAdmin Component={AdminTestListUsers} />}
        />
        {/* Test group series Question list */}
        <Route
          path="/admin/testseries/group/:user/:subuser/:uid"
          element={<ProtectedAdmin Component={AdminTestListUsersGroup} />}
        />
        {/* Test Series User Add Question */}
        <Route
          path="/admmin/testseries/add-question/:user/:subuser"
          element={<ProtectedAdmin Component={AddAdminTestQuestion} />}
        />
        {/* Test Series Group User Add Question */}
        <Route
          path="/admmin/testseries/add-question/group/:user/:subuser/:uid"
          element={<ProtectedAdmin Component={AddAdminTestQuestionGroup} />}
        />
        {/* Test User Add Question */}
        <Route
          path="/admmin/test/add-question/:user"
          element={<ProtectedAdmin Component={AddAdminQuestion} />}
        />
        {/* Test Series Edit question */}
        <Route
          path="/admin/testseries/user/edit-question/:id/:user/:subuser"
          element={<ProtectedAdmin Component={EditAdminTestQuestion} />}
        />
        {/* Test Series group Edit question */}
        <Route
          path="/admin/testseries/user/edit-question/group/:id/:user/:subuser/:uid"
          element={<ProtectedAdmin Component={EditAdminTestQuestionGroup} />}
        />
        {/* Test User Edit Question */}
        <Route
          path="/admin/test/edit-question/:id/:user"
          element={<ProtectedAdmin Component={EditAdminQuestion} />}
        />
          <Route
          path="/admin/contacts"
          element={<ProtectedAdmin Component={AdminContacts} />}
        />
         <Route
          path="/admin/craete/contacts"
          element={<ProtectedAdmin Component={AddAdminCreateContact} />}
        />
         <Route
          path="/admin/edit/contacts/:id"
          element={<ProtectedAdmin Component={EditContact} />}
        />
          <Route
          path="/admin/contact-details/:id"
          element={<ProtectedAdmin Component={AdminContactsDetails} />}
        />
         <Route
          path="/admin/edit/contact-details/:id/:detailid"
          element={<ProtectedAdmin Component={EditContactDetails} />}
        />
         <Route
          path="/admin/craete/contacts-details/:id"
          element={<ProtectedAdmin Component={AddAdminCreateContactDetails} />}
        />
        
        {/* Zoom Token */}
        <Route path="/admin/zoom" element={<AdminZoom />} />
        {/* Student Test  */}
        <Route path="/student/test-series" element={<AddStudentTest />} />
        <Route path="/student/test-series/:id" element={<AddStudentTestCourse />} />
        <Route path="/student/test-series-module/:testid" element={<AddStudentTestCourseModule />} />

        
        <Route
          path="/student/test-series-result/:id"
          element={<StudentTestResult />}
        />
         <Route
          path="/student/test-series-result-single/:id"
          element={<StudentTestResultSingle />}
        />
        <Route
          path="/student/test-series-result-single/downlaod/:userid/:id/:attempt"
          element={<StudentTestResultDownload />}
        />
        <Route
          path="/student/test-series-result-multi/downlaod/:userid/:id/:attempt"
          element={<StudentTestMultiResultDownload />}
        />
        <Route
          path="/student/test-series-result-multi/downlaod/analysis/:userid/:id/:attempt"
          element={<StudentTestMultiResultDownloadAnalysis />}
        />
          <Route
          path="/student/test-series-result-single/AnswerSheet/:userid/:id/:attempt"
          element={<StudentSingleAnswersheet />}
        />
         <Route
          path="/student/test-series-result-multi/AnswerSheet/:userid/:id/:attempt"
          element={<StudentMultiAnswerSheet />}
        />
        
          <Route
          path="/student/test-series-result-single/downlaod/result/:userid/:id/:attempt"
          element={<StudentTestResultDownloadAnalysis />}
        />
        
        {/* Student add */}
        <Route
          path="/student/test-series/question/:qid"
          element={<AddStudentTestSeires />}
        />
        {/* details */}
        <Route path="/details/:id" element={<DemoDetails />} />
        {/* student video details */}
        <Route
          path="/student/videos/video-details/:id/:name"
          element={<ProtectedStudent Component={VideoDetails} />}
        />{" "}
        {/* App Zoom Details */}
        <Route path="/admin/manage-details" element={<AdminZoomDetails />} />
        {/* Create Zoom Details */}
        <Route path="/admin/manage-details/add" element={<AdminAddZoom />} />
        {/* Edit Zoom Details */}
        <Route
          path="/admin/manage-details/edit/:id"
          element={<AdminEditZoom />}
        />
        <Route path="/instructor-list" element={<InstructorList />} />
        <Route path="/instructor-grid" element={<InstructorGrid />} />
        <Route path="/instructor-course" element={<InstructorCourse />} />
        {/* Blog */}
        <Route path="/blog-list" element={<BlogList />} />
        <Route path="/blog-grid" element={<BlogGrid />} />
        <Route path="/blog-masonry" element={<BlogMasonry />} />
        <Route path="/blog-modern" element={<BlogModern />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        {/* Pages */}
        <Route path="/page-notification" element={<Notification />} />
        <Route path="/pricing-plan" element={<PricingPlan />} />
        <Route path="/pricing-plan2" element={<PricingPlan2 />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/add-course" element={<AddCourse />} />
        <Route path="/course-list" element={<CourseList />} />
        {/* <Route path="/course-message" element={<CourseMessage />} /> */}
        <Route path="/course-grid" element={<CourseGrid />} />
        <Route path="/course-grids" element={<CourseGrids />} />
        <Route path="/course-details" element={<CourseDetails />} />
        <Route path="/course-details1" element={<CourseDetails1 />} />
        <Route path="/course-details2" element={<CourseDetails2 />} />
        <Route path="/come-soon" element={<ComingSoon />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/error-500" element={<Error500 />} />
        <Route path="/under-construction" element={<UnderConstruction />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/support" element={<Support />} />
        <Route path="/job-category" element={<JobCategory />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register1" element={<RegisterOne />} />
        <Route path="/register2" element={<RegisterTwo />} />
        <Route path="/register3" element={<RegisterThree />} />
        <Route path="/register4" element={<RegisterFour />} />
        <Route path="/register5" element={<RegisterFive />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/term-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* Instructor Routes */}
        <Route path="/instructor-dashboard" element={<Dashboard />} />
        {/* <Route path="/instructor-list" element={<InstructorList />} /> */}
        {/* <Route path="/instructor-grid" element={<InstructorGrid />} /> */}
        {/* <Route path="/instructor-course" element={<InstructorCourse />} /> */}
        <Route path="/instructor-reviews" element={<InstructorReviews />} />
        <Route path="/instructor-earnings" element={<InstructorEarnings />} />
        <Route path="/instructor-orders" element={<InstructorOrders />} />
        <Route path="/instructor-payouts" element={<InstructorPayouts />} />
        <Route path="/instructor-tickets" element={<InstructorTickets />} />
        <Route path="/instructor-profile" element={<InstructorProfile />} />
        <Route
          path="/instructor-edit-profile"
          element={<InstructorEditProfile />}
        />
        <Route path="/instructor-security" element={<InstructorSecurity />} />
        <Route
          path="/instructor-social-profile"
          element={<InstructorSocialProfile />}
        />
        <Route
          path="/instructor-notifications"
          element={<InstructorNotification />}
        />
        <Route path="/instructor-chat" element={<InstructorChat />} />
        <Route
          path="/instructor-delete-profile"
          element={<InstructorDeleteProfile />}
        />
        <Route path="/instructor-edit" element={<InstructorEdit />} />
        <Route path="/instructor-grid-2" element={<InstructorGrid2 />} />
        <Route
          path="/instructor-linked-account"
          element={<InstructorLinkedAccount />}
        />
        <Route
          path="/instructor-new-tickets"
          element={<InstructorNewTickets />}
        />
        <Route
          path="/instructor-profile-privacy"
          element={<InstructorProfilePrivacy />}
        />
        <Route
          path="/instructor-student-grid"
          element={<InstructorStudentGrid />}
        />
        <Route
          path="/instructor-student-list"
          element={<InstructorStudentList />}
        />
        <Route
          path="/deposit-instructor-dashboard"
          element={<InstructorDepositDashboard />}
        />
        <Route path="/dashboard-instructor" element={<DashboardInstructor />} />
        <Route
          path="/withdrawal-instructor"
          element={<WithdrawalInstructor />}
        />
        <Route path="/deposit-instructor" element={<DepositInstructor />} />
        <Route
          path="/transactions-instructor"
          element={<TransactionInstructor />}
        />
        {/*Student Routes  */}
        <Route path="/setting-edit-profile" element={<StudentEditProfile />} />
        <Route path="/setting-student-accounts" element={<StudentAccounts />} />
        <Route path="/setting-student-billing" element={<StudentBilling />} />
        <Route
          path="/setting-student-delete-profile"
          element={<StudentDeleteProfile />}
        />
        <Route path="/setting-student-invoice" element={<StudentInvoice />} />
        <Route path="/view-invoice" element={<ViewInvoice />} />
        <Route
          path="/setting-student-notification"
          element={<StudentNotification />}
        />
        <Route path="/setting-student-payment" element={<StudentPayment />} />
        <Route path="/setting-student-privacy" element={<StudentPrivacy />} />
        <Route path="/setting-student-referral" element={<StudentReferral />} />
        <Route path="/setting-student-security" element={<StudentSecurity />} />
        <Route
          path="/setting-student-social-profile"
          element={<StudentSocialProfile />}
        />
        <Route
          path="/setting-student-subscription"
          element={<StudentSubscription />}
        />
        <Route
          path="/setting-support-new-tickets"
          element={<StudentNewTickets />}
        />
        <Route path="/setting-support-tickets" element={<StudentTickets />} />
        <Route
          path="/setting-support-view-tickets"
          element={<StudentViewTickets />}
        />
        <Route
          path="/deposit-student-dashboard"
          element={<StudentDepositDashboard />}
        />
        <Route path="/students-profile" element={<StudentProfile />} />
        <Route path="/students-grid" element={<StudentsGrid />} />
        <Route path="/students-grid2" element={<StudentsGrid2 />} />
        <Route path="/students-list" element={<StudentsList />} />
        <Route path="/course-student" element={<CourseStudent />} />
        <Route path="/course-wishlist" element={<CourseWishlist />} />
        <Route path="/course-message" element={<CourseMessage />} />
        <Route path="/purchase-history" element={<PurchaseHistory />} />
        <Route path="/deposit-student" element={<DepositStudent />} />
        <Route path="/transactions-student" element={<TransactionStudent />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Approuter;
