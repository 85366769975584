import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../footer";
import AdminInstructorSidebar from "../adminSidebar";
// import Modal from "react-bootstrap/Modal";
import AdminHeader from "../adminHeader";
import { Button } from "react-bootstrap";
import { Input, Tooltip } from "antd";
import Switch from "react-switch";

import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import "../adminTestSeries/index.css"
import { notification, Space, Modal } from "antd";
const { confirm } = Modal;
const ZOOMVIEW = "https://zoomservice.chahalacademy.com/api/zoom/get_all";
const STATUSCHANGE="https://zoomservice.chahalacademy.com/api/zoom/edit-account-status"
const DELETEZOOM = "https://zoomservice.chahalacademy.com/api/zoom/delete-account";

export const AdminZoomDetails = () => {
  //   const {Search} =Input
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleEdit = (id) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/manage-details/edit/${ciphertexts}`);
  };
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const handleClose = () => setShow(false);
  const [data, setData] = useState(null);
  const [message, setmessage] = useState("No Data");
  const [listModule, setListModule] = useState(null);
  const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  const config = {
    headers: { api_key:"lmsv2" },
  };
  const handleShow = async (id) => {
    setShow(true);
    setId(id);
  };
  const fetchZoom = async () => {
    try {
      setLoading(true);
      const response = await axios.get(ZOOMVIEW, config);
      setData(response.data?.message);
      setListModule(response.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false)
  const openNotificationWithIcon = (type) => {
    console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>Account Deleted</strong>
              </div>
            ),
duration:1
        });

  };
  const handleChange = async (id, status) => {
    let bool = eval(status);
    console.log(bool)
    let result = Number(!bool) + "";
    const response = await axios.put(
      `${STATUSCHANGE}/${id}`,
      {  status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    fetchZoom();
    // toast.success("Status Change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
    // console.log(list);
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const DeleteZoom = async () => {
    if (id) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETEZOOM}/${id}`, config);
        // console.log("resonse", resonse);
        toast.warning("Account Deleted");
        setLoading(false);
        fetchZoom();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
      }
    }
  };
  function showConfirm(id) {
    confirm({
      title: 'Do you want to delete this account?',
      content:
        'When clicked the OK button, this dialog will be closed after 1 second',
      okButtonProps: { style: { backgroundColor: '#ff0100' } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.delete(`${DELETEZOOM}/${id}`, config);
          setLoading(false);
          openNotificationWithIcon("success")
          fetchZoom();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIconLogin("error", error?.response?.data?.message);
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
          else{
          openNotificationWithIcon2("error")
          }
        }
      },
      onCancel() { },
    });
  }
  useEffect(() => {
    fetchZoom();
  }, []);

  const Filter = (e) => {
    const searchValue = e.target.value;
    if (Array.isArray(listModule) && listModule) {
      const filteredRecords = listModule.filter((it) =>
        it.email.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (filteredRecords.length == 0) {
        setListModule(filteredRecords);
        setmessage('No Data Found')
      }
      if (filteredRecords.length > 0) {
        setListModule(filteredRecords);
      } else {
        // setList(data)
      }
      if (searchValue.length === 0) {
        setListModule(data);
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault(); 
        Filter(e)
    }
}
useEffect(() => {
  const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
  if(lastVisitedRoute){
    localStorage.removeItem("lastVisitedRoute")
  }
  else{
    localStorage.setItem("lastVisitedRoute", window.location.pathname);

  }
}, []);
const openNotificationWithIcon2 = (type) => {
  console.log("toast after click")
  notification[type]
  (
    
    {
    message: 
    (
      <div style={{color:"red"}}>
       <strong>Internal Server Error </strong> 
      </div>
    ),
  
    
    duration:1
  });
  };
  const openNotificationWithIconLogin = (type,text) => {
    console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>{text} </strong> 
        </div>
      ),
    
      
      duration:1
    });
    };
  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"Test"} />
      <div className="page-content instructor-page-content">
        <div className="container"
                style={{minWidth:"100%",marginTop:"-15px"}}

        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3 style={{ textTransform: 'capitalize' }}>Manage Accounts</h3>
                        <nav
                          aria-label="breadcrumb"
                          style={{ marginTop: "0.5em", marginBottom: '-1.6em' }}
                        >
                          <ol className="breadcrumb">
                            <li
                              className="breadcrumb-item "
                              aria-current="page"
                              role="button"
                              onClick={() => navigate("/admin/live-classes")}
                            >
                              Live Classes
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              Manage Accounts
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div className="comman-space pb-0">
                        
                        <div 
                        // className="settings-tickets-blk course-instruct-blk table-responsive"
                        // id="table-value"
                        >
                        <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              <form action="#">
                                <div className="row  gx-2 align-items-center"
                                  style={{ justifyContent: "space-between" }}>

                                  <div className="col-md-3 col-item">
                                    <div className=" search-group justify-content-start"
                                      id="search-btn"

                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "rgb(246, 106, 98)", // Change the color to yellow
                                        }}
                                      >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                          x1="21"
                                          y1="21"
                                          x2="16.65"
                                          y2="16.65"
                                        ></line>
                                      </svg>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=" Search Name"
                                        // allowClear
                                        onChange={(e)=>Filter(e)}
                                        onKeyDown={handleKeyPress}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-lg-6 col-item "
                                 style={{ justifyContent: "flex-end" }}  >
                                    <div className="button-container"
                                    style={{justifyContent:"flex-end"}}
                                    >
   
                                      <Button
                                         onClick={() =>
                                          navigate("/admin/manage-details/add")
                                        }
                                        className="custom-button"
                                        // style={{marginLeft:'19.4em'}}
                                      >
                                        <i className="fa-solid fa-plus"></i>
                                        {" "} Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          {/* Referred Users */}
                          <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          
                          <table
                            className="table table-nowrap mb-0"
                            // style={{ tableLayout: "fixed" }}
                          >
                            <thead>
                              <tr className="text-center">
                                <th>SR. NO.</th>
                                <th>EMAIL</th>
                                <th>STATUS</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {loading === true ? (
                             <tbody  className="d-flex justify-content-center align-items-center"
                             style={{
                               position: "fixed",
                               top: 0,
                               right: 0,
                               bottom: 0,
                               left: 0,
                               backgroundColor: "rgba(255, 255, 255, 0.6)",
                             }}>
                               <tr>
                                 <td>
                                   <div
                                     className="spinner-border text-warning"
                                     role="status"
                                   >
                                     <span className="sr-only">
                                       Loading...
                                     </span>
                                   </div>
                                 </td>
                               </tr>
                             </tbody>
                            ) : (
                              listModule?.map((item, i) => {
                                return (
                                  <tbody key={i}>
                                    <tr className="text-center">
                                      <td >{i + 1}</td>
                                      {/* <td> {`${item?.client_id.slice(0,10)} ${item?.client_id.length>10?"...":''}`} </td>
                                      <td> {`${item?.client_secrect.slice(0,10)} ${item.client_secrect.length>10? "...":''}`} </td>
                                      <td> {`${item?.redirect_url.slice(0,10)} ${item.redirect_url.length>10?"...":""}`} </td> */}
                                      <td>{item?.email}</td>
                                      <td className="text-center">
                                            <div
                                              className="form-check form-switch"
                                              style={{ marginRight: "3em" }}
                                            >
                                              <Switch
                                                onChange={(e) => {
                                                  handleChange(
                                                    item.id,
                                                    item.status
                                                  );
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={20}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={15}
                                                width={38}
                                                className="react-switch"
                                                id="flexSwitchCheckChecked"
                                                checked={
                                                  item.status == true
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </td>
                                      <td
                                        style={{
                                          whiteSpace: " normal !important",
                                          wordWrap: "break-word",
                                          
                                        }}
                                      >
                                        <Tooltip title="Edit">
                                          <button
                                            onClick={() => handleEdit(item?.id)}
                                            type="button"
                                            className=" btn btn-info text-white m-1"
                                          >
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <Button
                                            type="button"
                                            className="btn btn-danger m-1"
                                            onClick={() => showConfirm(item.id)}
                                          >
                                            <i className="fa-regular fa-trash-can"></i>
                                          </Button>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                              )}
                          </table>
                          </div>
                       
                              {listModule?.length<=0?  <h5 className="text-center">{message}</h5>:''}
                          {/* Referred Users */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
            <ToastContainer />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
