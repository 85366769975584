import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import CryptoJS from "crypto-js";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../instructor/adminHeader";
const ADDTESTSERIES = "/api/recorded-video/create-submodule";
import { notification, Space } from "antd";

const initalValue = {
  name: "",
};
const studentSchema = Yup.object({
  name: Yup.string().min(2).max(45).required("Please enter your name"),
});
export default function AddAdminRecordedVideoSub() {
  const { auth } = useContext(AuthContext);
  const { feature_type_id } = useParams();
  const [submit, setSubmit] = useState(false)
  // localStorage.setItem("lastVisitedRoute", window.location.pathname);

  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(feature_type_id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, text, col) => {
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: col }}>
                <strong>{text}</strong>
              </div>
            ),
          duration: 1
        });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const { errors, values, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value) => {
      const token = localStorage.getItem("token");
      setSubmit(true)
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const response = await axios.post(
          ADDTESTSERIES,
          {
            recordedvideos_submodule_name: value.name,
            feature_type_id: decryptedData,
            "recordedvideos-id": 0,
          },
          config
        );
        // console.log(response);
        if (response) {
          openNotificationWithIcon("success", 'SubModule Created', "#52c41a")
          setTimeout(() => {
            navigate("/admin/recorded-videos");
          }, 800);
          // toast.success("SubModule Created");
        }
      } catch (error) {
        console.log(error);
        setSubmit(false)
        
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon('error', error?.response?.data?.message?.replace("recordedvideos submodule name", "name"), 'red')
        } else {
          openNotificationWithIcon("error", 'Internal Server Error', 'red')
        }

      }

    },
    validationSchema: studentSchema,
  });
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute")
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute")
    }
    else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add SubModule</h3>
                    {/* <p>Add Sub Modules information.</p> */}
                    <nav aria-label="breadcrumb" style={{ marginTop: "0.5em" }}>
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li
                          className="breadcrumb-item "
                          aria-current="page"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate("/admin/recorded-videos-list")
                          }
                        >
                          {" "}
                          Recorded Videos
                        </li>
                        <li
                          className="breadcrumb-item "
                          aria-current="page"
                          style={{ cursor: "pointer", fontWeight: 'normal' }}
                          onClick={() => navigate("/admin/recorded-videos")}
                        >
                          {" "}
                          Recorded Module & SubModule
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          style={{
                            cursor: "pointer",
                            marginLeft: "-1em",
                          }}
                        >
                          {" "}
                          Add SubModule
                          {/* {user.substring(0, user.length - 4)} */}
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="update-profile"
                          id="submit-button"

                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button type="submit" className="btn btn-primary"
                              disabled={submit}

                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
        <ToastContainer />
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
