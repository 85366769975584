import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Cart, Course14, Course15, Course4, logo, Messages, Notification, User1, User11, User2, User3, Wish ,ChahalLogo} from "../../../imagepath";
import HeadModule from "../module";
import axios from "../../../../api/axios";
const COURSECATEGORY = "/api/admin/course/menu";

const DemoHeader = ({activeMenu}) => {
    const tokenStudent = localStorage.getItem("tokenStudent");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  const [mobileSubMenu5, setMobileSubMenu5] = useState(false);
  const [mobileSubMenu6, setMobileSubMenu6] = useState(false);
  
  const [showMorecourses, setShowMoreCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const openMobileMenu = (event) => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = (event) => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu22 = (e) => {
    e.preventDefault();
    setMobileSubMenu22(!mobileSubMenu22);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  const openMobileSubMenu32 = (e) => {
    e.preventDefault();
    setMobileSubMenu32(!mobileSubMenu32);
  };
  const openMobileSubMenu4 = (e) => {
    e.preventDefault();
    setMobileSubMenu4(!mobileSubMenu4);
  };
  const openMobileSubMenu42 = (e) => {
    e.preventDefault();
    setMobileSubMenu42(!mobileSubMenu42);
  };
  const openMobileSubMenu43 = (e) => {
    e.preventDefault();
    setMobileSubMenu43(!mobileSubMenu43);
  };
  const openMobileSubMenu5 = (e) => {
    e.preventDefault();
    setMobileSubMenu5(!mobileSubMenu5);
  };
  const courseslist = async () => {
    try {
      const response = await axios.get(COURSECATEGORY);
      setCourses(response?.data?.message);
    } catch (error) {}
  };
  useEffect(() => {
    courseslist();
  }, []);
  
  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);


  return (
    <>
      <header className="header header-page">
        <div className="header-fixed">
          <nav 
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
          >
            <div className="container" style={{border:'none',minWidth:"100%"}}>
              <div className="navbar-header">
              <Link
                  id="mobile_btn"
                  to="#"
                  onClick={openMobileMenu}
                >
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  <img
                    src={ChahalLogo}
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="main-menu-wrapper">
              <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="#"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>
                <ul className="main-nav">
                <li className="has-submenu">
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li className="has-submenu">
                    <Link to="/course-grids">courses</Link>
                  </li> */}
                  {courses?.map((item,index)=>{
                  return(
                    index < 4 &&  
                   
                    <li className="has-submenu">
                  <Link
                    to={`/student/courses-list/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}
                  </Link>
                
                </li>

                
                
                  
                  )
                })}
           
                <li className="  d-block d-sm-block d-md-block d-lg-none" onClick={(()=>setShowMoreCourses(!showMorecourses))} ><a >More Courses <i className="fas fa-chevron-down"></i></a>
                <ul className={`submenu ${showMorecourses ? 'd-block' : 'd-none'}`} >
                {courses?.map((item,index)=>{
                  return(
                    index >= 4 &&  
                   
                    <li className="has-submenu">
                  <Link
                    to={`/student/courses-list/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}
                  </Link>
                
                </li>

                
                
                  
                  )
                })}
                  
                </ul></li>
              
                
                <li className="has-submenu d-lg-block d-md-none d-sm-none d-none "  ><a >More Courses <i className="fas fa-chevron-down"></i></a>
                <ul className={`submenu `} >
                {courses?.map((item,index)=>{
                  return(
                    index >= 4 &&  
                   
                    <li className="has-submenu">
                  <Link
                    to={`/student/courses-list/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}
                  </Link>
                
                </li>

                
                
                  
                  )
                })}
                  
                </ul></li>
              
                </ul>
              </div>

              <ul className="nav header-navbar-rht">
              {tokenStudent ? null : (
                <li className="nav-item">
                  <Link className="nav-link header-sign" to="/login">
                    Login
                  </Link>
                </li>
              )}</ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default DemoHeader;
